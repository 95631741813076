<template>
    <div id="contentcontainter" :class="user_settings.advancedMode ? 'advanced-mode' : 'standard-mode'">
        <!-- different locations in clipboard Info -->
        <!-- <v-snackbar top color="#fcba03" v-model="showLocError">
         <v-row> <v-col cols='1'><v-icon class="pl-3">mdi-alert-circle-outline</v-icon> </v-col> <v-col> <span class="font-weight-bold">The keywords in your clipboard have search volume of another country. Please delete these keywords first. </span> </v-col>   </v-row> 
    </v-snackbar> -->
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>

        <SearchSettings :catsOfList="catsOfList" :seasonsOfList="seasonsOfList" :headers="headers.modifiedHeaders"></SearchSettings>

        <!-- Delete Dialog -->
        <v-dialog v-model="deleteQuestion" max-width="300">
            <v-card>
                <v-card-title
                    style="
                         {
                            text-align: center;
                        }
                    ">
                    Are you sure?</v-card-title
                >
                <v-card-text class="text-center">
                    <v-btn class="mr-3" text @click="deleteQuestion = false"> Nevermind </v-btn>
                    <v-btn color="primary" text @click="deleteKeywords(selected)"> Yes </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-slide-x-transition hide-on-leave>
            <div v-if="user_settings.advancedMode">
                <div v-if="!loaded">
                    <v-row>
                        <v-col md="12">
                            <v-card id="searchbar" class="rounded_card mb-3 mt-9 quaroshadow">
                                <div class="backsheet"></div>
                                <h2 class="px-0 pt-3 pb-0">Google Search Volume</h2>
                                <v-row id="searchRow">
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="5" class="pb-0">
                                        <v-textarea
                                            filled
                                            rounded
                                            label="e.g. Garden, Backpack, Smart Home"
                                            hint="You can paste up to 10.000 keywords. Seperate keywords with comma or new line."
                                            rows="3"
                                            prepend-inner-icon="mdi-magnify"
                                            v-model="keywords_data"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="ma-auto">
                                        <!-- <v-select
                                            :disabled="btndisabled"
                                            class="centerVertical"
                                            v-model="selectedLocation"
                                            :items="stateItems"
                                            item-text="loc"
                                            item-value="id"
                                            label="Location"
                                            prepend-inner-icon="mdi-map-marker"
                                            return-object
                                            top
                                            rounded
                                            filled
                                            attach
                                            required>
                                        </v-select> -->
                                        <LocationSelection @setLangLoc="selectedLocation = $event"></LocationSelection>
                                    </v-col>
                                    <v-col v-if="!btndisabled" cols="12" xs="12" sm="12" md="12" lg="1">
                                        <v-btn elevation="0" rounded class="centerVertical white-text" :disabled="btndisabled" id="sendBtn" color="primary" @click="getKeywordData(keywords_data)"
                                            ><v-icon>mdi-send</v-icon></v-btn
                                        >
                                    </v-col>
                                    <v-col v-else cols="12" xs="12" sm="12" md="12" lg="1">
                                        <v-btn
                                            elevation="0"
                                            rounded
                                            class="centerVertical white-text"
                                            id="sendBtn"
                                            color="primary"
                                            @click="
                                                stopRequest = true
                                                loading = false
                                                btndisabled = false
                                                keywords = []
                                                progressinterminate = true
                                                progressvalue = 0
                                            "
                                            ><v-icon>mdi-close</v-icon></v-btn
                                        >
                                    </v-col>
                                </v-row>
                                <!-- Spinner -->
                                <div class="loaderwrap" v-if="loading">
                                    <v-progress-linear class="linearloader" rounded :indeterminate="progressinterminate" :value="progressvalue" color="primary"> </v-progress-linear>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <v-row
                    ><v-col cols="12">
                        <v-card class="rounded_card mt-0 quaroshadow" id="searchbar_adv" v-if="loaded">
                            <v-row id="searchRow" class="px-2 pr-5">
                                <v-col cols="12" xl="6" lg="4" md="4" class="pb-0">
                                    <v-textarea
                                        filled
                                        rounded
                                        :hide-details="input_focus ? false : true"
                                        label="e.g. Garden, Backpack, Smart Home"
                                        hint="You can paste up to 10.000 keywords. Seperate keywords with comma or new line."
                                        :rows="input_focus ? 3 : 1"
                                        prepend-inner-icon="mdi-magnify"
                                        @focus="input_focus = true"
                                        @blur="input_focus = false"
                                        v-model="keywords_data"></v-textarea>
                                </v-col>
                                <v-col cols="12" xl="5" lg="7" md="7">
                                    <!-- <v-select
                                        :disabled="btndisabled"
                                        v-model="selectedLocation"
                                        hide-details
                                        :items="stateItems"
                                        item-text="loc"
                                        item-value="id"
                                        label="Location"
                                        prepend-inner-icon="mdi-map-marker"
                                        return-object
                                        top
                                        rounded
                                        filled
                                        attach
                                        required>
                                    </v-select> -->
                                    <LocationSelection @setLangLoc="selectedLocation = $event"></LocationSelection>
                                </v-col>
                                <v-col v-if="!btndisabled" xl="1" lg="2" md="2">
                                    <v-btn elevation="0" rounded class="white-text" :disabled="btndisabled" id="sendBtn" color="primary" @click="getKeywordData(keywords_data)"
                                        ><v-icon>mdi-send</v-icon></v-btn
                                    >
                                </v-col>
                                <v-col v-else xl="1" lg="2" md="2">
                                    <v-btn
                                        elevation="0"
                                        rounded
                                        class="white-text"
                                        id="sendBtn"
                                        color="primary"
                                        @click="
                                            stopRequest = true
                                            loading = false
                                            btndisabled = false
                                            keywords = []
                                            progressinterminate = true
                                            progressvalue = 0
                                        "
                                        ><v-icon>mdi-close</v-icon></v-btn
                                    >
                                </v-col>
                            </v-row>
                            <!-- loader -->
                            <div class="loaderwrap" v-if="loading">
                                <v-progress-linear class="linearloader" rounded :indeterminate="progressinterminate" :value="progressvalue" color="primary"> </v-progress-linear>
                            </div>
                        </v-card> </v-col
                ></v-row>
                <v-row>
                    <v-col cols="12" lg="6" class="pb-0 pt-0 order-xs-last order-sm-last order-md-last">
                        <!-- Keyword Table -->
                        <v-row
                            ><v-col class="pb-0">
                                <v-slide-x-transition>
                                    <KeywordTable
                                        ref="kw_table"
                                        v-if="loaded"
                                        :columnsDisabled="columnsDisabled"
                                        :keywords="keywords"
                                        :headers="headers"
                                        :catsLoading="catsLoading"
                                        :catsOfList="catsOfList"
                                        :cardData="carddata"
                                        :serpsOfList="serpsOfList"
                                        :addToSerpDisabled="addToSerpDisabled"
                                        :siteKeywordsLocation="siteKeywordsLocation"
                                        @filterColumns="filterColumns"
                                        @fetchSERP="fetchSERP"
                                        :new_data="new_data"
                                        mountedOn="Searchvolume"
                                        @removeCat="removeCat"
                                        @switchCat="switchCat"
                                        @addToChart="addToChart"
                                        @deleteKeywords="prepareDeleteKw"
                                        @filterForCat="filterForCat"
                                        @addCategories="addCategories"
                                        @createNotification="createNotification"
                                        @addCustomEntries="addCustomEntries"
                                        @filterForCustomDropdown="filterForCustomDropdown"
                                        @modifiedDropDownOptions="modifiedDropDownOptions"
                                        @changedHeaders="changedHeaders"
                                        @startFetchWithSendToKeywords="startFetchWithSendToKeywords"
                                        @changeRankingURL="addRankings"></KeywordTable>
                                </v-slide-x-transition> </v-col
                        ></v-row>
                    </v-col>

                    <v-col cols="12" lg="6" class="order-xs-first order-sm-first order-md-first order-lg-last">
                        <v-row>
                            <v-col cols="12" class="py-0" id="linechart_col">
                                <!-- line chart  -->
                                <v-slide-x-transition>
                                    <KeywordLineChart
                                        id="line_chart"
                                        :dataChart="dataChart"
                                        :countGraph="countGraph"
                                        :countGraph2="countGraph2"
                                        v-if="loaded"
                                        :graphloaded="graphloaded"
                                        :loading="false"
                                        @clearChart="clearChart"
                                        @rerender="rerenderChart">
                                    </KeywordLineChart>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <!-- SERP Preview -->
                                <v-slide-x-transition>
                                    <serp-preview
                                        v-if="loaded"
                                        :searched="loaded"
                                        :keyword="serpKeyword"
                                        :selectedLocation="siteKeywordsLocation"
                                        :reload="serpReload"
                                        :search_volume="preview_sv_data"
                                        @serpLoading="addToSerpDisabled = false"></serp-preview>

                                    <!-- <serp-preview :searched="loaded" :keyword="serpKeyword" :selectedLocation="selectedLocation" :reload="serpReload" :search_volume="preview_sv_data"></serp-preview> -->
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-slide-x-transition>
        <v-slide-x-transition hide-on-leave>
            <div v-if="!user_settings.advancedMode">
                <!-- new search box -->
                <v-row>
                    <v-col md="12">
                        <v-card id="searchbar" class="rounded_card mb-3 quaroshadow">
                            <div class="backsheet"></div>
                            <h2 class="px-0 pt-3 pb-0">Google Search Volume</h2>
                            <v-row id="searchRow">
                                <v-col cols="12" xs="12" sm="12" md="12" lg="5" class="pb-0">
                                    <v-textarea
                                        filled
                                        rounded
                                        label="e.g. Garden, Backpack, Smart Home"
                                        hint="You can paste up to 10.000 keywords. Seperate keywords with comma or new line."
                                        rows="3"
                                        prepend-inner-icon="mdi-magnify"
                                        v-model="keywords_data"></v-textarea>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                                    <!-- <v-select
                                        :disabled="btndisabled"
                                        class="centerVertical"
                                        v-model="selectedLocation"
                                        :items="stateItems"
                                        item-text="loc"
                                        item-value="id"
                                        label="Location"
                                        prepend-inner-icon="mdi-map-marker"
                                        return-object
                                        top
                                        rounded
                                        filled
                                        attach
                                        required>
                                    </v-select> -->
                                    <LocationSelection @setLangLoc="selectedLocation = $event"></LocationSelection>
                                </v-col>
                                <v-col v-if="!btndisabled" cols="12" xs="12" sm="12" md="12" lg="1">
                                    <v-btn elevation="0" rounded class="centerVertical white-text" :disabled="btndisabled" id="sendBtn" color="primary" @click="getKeywordData(keywords_data)"
                                        ><v-icon>mdi-send</v-icon></v-btn
                                    >
                                </v-col>
                                <v-col v-else cols="12" xs="12" sm="12" md="12" lg="1">
                                    <v-btn
                                        elevation="0"
                                        rounded
                                        class="centerVertical white-text"
                                        id="sendBtn"
                                        color="primary"
                                        @click="
                                            stopRequest = true
                                            loading = false
                                            btndisabled = false
                                            keywords = []
                                            progressinterminate = true
                                            progressvalue = 0
                                        "
                                        ><v-icon>mdi-close</v-icon></v-btn
                                    >
                                </v-col>
                            </v-row>
                            <!-- Spinner -->
                            <div class="loaderwrap" v-if="loading">
                                <v-progress-linear class="linearloader" rounded :indeterminate="progressinterminate" :value="progressvalue" color="primary"> </v-progress-linear>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <!-- Line Chart -->
                <v-row class="mb-5">
                    <v-col>
                        <v-slide-x-transition>
                            <KeywordLineChart
                                :dataChart="dataChart"
                                :countGraph="countGraph"
                                v-if="loaded"
                                :countGraph2="countGraph2"
                                :graphloaded="graphloaded"
                                :loading="false"
                                @clearChart="clearChart"
                                @rerender="rerenderChart">
                            </KeywordLineChart>
                        </v-slide-x-transition>
                    </v-col>
                </v-row>

                <!-- Data table  -->
                <v-slide-x-transition>
                    <KeywordTable
                        ref="kw_table"
                        class="mb-5"
                        v-if="loaded"
                        :columnsDisabled="columnsDisabled"
                        :keywords="keywords"
                        :headers="headers"
                        :catsLoading="catsLoading"
                        :catsOfList="catsOfList"
                        :cardData="carddata"
                        mountedOn="Searchvolume"
                        :serpsOfList="serpsOfList"
                        :addToSerpDisabled="addToSerpDisabled"
                        :siteKeywordsLocation="siteKeywordsLocation"
                        :new_data="new_data"
                        @removeCat="removeCat"
                        @switchCat="switchCat"
                        @addToChart="addToChart"
                        @deleteKeywords="prepareDeleteKw"
                        @filterForCat="filterForCat"
                        @addCategories="addCategories"
                        @createNotification="createNotification"
                        @addCustomEntries="addCustomEntries"
                        @filterForCustomDropdown="filterForCustomDropdown"
                        @modifiedDropDownOptions="modifiedDropDownOptions"
                        @filterColumns="filterColumns"
                        @changedHeaders="changedHeaders"
                        @startFetchWithSendToKeywords="startFetchWithSendToKeywords"></KeywordTable>
                </v-slide-x-transition>
            </div>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import KeywordLineChart from "../components/charts/KeywordLineChart"
    import KeywordTable from "../components/keyword-table/KeywordTable"
    // import locs from "../components/helper/locs"
    import { standardHeaders } from "../components/helper/global.js"
    import dataprocessing from "../components/helper/dataprocessing.js"
    import SerpPreview from "../components/SerpPreview"
    import SearchSettings from "../components/SearchSettings"
    import LocationSelection from "../components/LocationSelection"

    export default {
        name: "KeywordPlanner",
        data() {
            return {
                get plan() {
                    if (localStorage.getItem("plan")) {
                        if (localStorage.getItem("plan") != "[object Object],[object Object]") {
                            // //console.log(localStorage.getItem('user'))
                            return localStorage.getItem("plan")
                        } else {
                            return
                        }
                    } else {
                        return
                    }
                },
                notification: { show: false },
                columnsDisabled: {},
                startColumnIndex: 5,
                dialog: false,
                catData: [],
                catsOfList: [],
                seasonsOfList: [],
                serpsOfList: [],
                catsLoading: true,
                btndisabled: false,
                deleteQuestion: false,
                tableBtnDisabled: true,
                selected: [],
                dataChart: {
                    selected: [],
                    labels: [],
                    datasets: [
                        {
                            label: "Sum of all keywords",
                            backgroundColor: "#EB5E28",
                            borderColor: "grey",
                            pointBackgroundColor: "white",
                            borderWidth: 2,
                            pointBorderColor: "grey",
                            data: [],
                            hidden: false
                        }
                    ]
                },
                showgraph: true,
                countGraph: 0,
                countGraph2: 0,
                graphloaded: false,
                keywords: [],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                pagination: { sortBy: "avgsv", descending: true },
                search: "",
                headers: {
                    options: {
                        sort: true,
                        active: true
                    },
                    standardHeaders: [],
                    modifiedHeaders: []
                },
                keywords_data: [],
                keywords_ideas: [],
                keepdata: false,
                selectedLanguage: { lang: "German", value: "1001" },
                selectedLocation: {},
                siteKeywordsLocation: {},
                langItems: [
                    { lang: "German", value: "1001" },
                    { lang: "English", value: "1000" },
                    { lang: "Italian", value: "1004" },
                    { lang: "Polish", value: "1030" },
                    { lang: "French", value: "1002" },
                    { lang: "Spanish", value: "1003" },
                    { lang: "Dutch", value: "1010" }
                ],
                // stateItems: locs.stateItems,
                loading: false,
                loaded: false,
                carddata: {
                    title: "",
                    subTitle: "",
                    searchPhrases: null
                },
                tablerows: [],
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                monthArray: ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                progressinterminate: true,
                progressvalue: 0,
                showInvalidInputError: false,
                stopRequest: false,
                unfilteredKeywords: [],
                serpKeyword: null,
                serpReload: 0,
                input_focus: false,
                preview_sv_data: {},
                addToSerpDisabled: false,
                new_data: 0
            }
        },
        components: {
            KeywordLineChart,
            KeywordTable,
            SerpPreview,
            SearchSettings,
            LocationSelection
        },
        watch: {
            selectedLocation: function (val) {
                localStorage.setItem("selectedLocation", JSON.stringify(val))
            },
            "$store.state.user_settings.advancedMode"(val) {
                this.keywords.map((x) => {
                    x.selected = false
                })
            }
        },
        computed: {
            decryptedPlan() {
                if (this.plan.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            user_settings: {
                get: function () {
                    return this.$store.state.user_settings
                },
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_USER_SETTINGS", newValue)
                }
            }
        },
        methods: {
            addRankings(url, device) {
                this.$helpers.addRankings(this.keywords, this.headers, this.selectedLocation.value, url, device)
            },
            startFetchWithSendToKeywords() {
                if (this.$store.state.send_to_keywords.length) {
                    this.keywords_data = this.$store.state.send_to_keywords.reduce((a, b) => a + "\n" + b)
                    // this.getKeywordData(this.keywords_data)
                    this.$store.dispatch("SET_SEND_TO_KEYWORDS", [])
                }
            },
            changedHeaders() {
                console.log("header in parent set")
                this.headers = this.$store.state.header_items
            },
            modifiedDropDownOptions(option, headerValue) {
                this.keywords.map((keywordRow) => {
                    if (keywordRow.customs[headerValue].id == option.id) {
                        keywordRow.customs[headerValue] = option
                    }
                })
            },
            createNotification(notification) {
                this.notification = this.$helpers.createNotification(notification.message, notification.icon, notification.color)
            },
            addCustomEntries(rows, selectedEntry, headerValue, isBackend, isArray) {
                if (!rows) return
                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    if (rowindex > -1) {
                        if (this.keywords[rowindex].customs === undefined) {
                            this.keywords[rowindex].customs = {}
                        }
                        this.keywords[rowindex].customs[headerValue] = isArray ? [...selectedEntry] : selectedEntry
                    }
                })
                // // this.keywords.push({})
                // // this.keywords.pop()
            },
            filterColumns() {
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
            },
            filterForCustomDropdown(initialObject, isArray, cat_for_filter) {
                var resultObject = Object.fromEntries(Object.entries(initialObject).filter(([_, v]) => v.length != 0))

                if (Object.keys(resultObject).length === 0 && cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.map((keyword) => {
                    if (Object.keys(resultObject).length > 0) {
                        Object.keys(resultObject).map((entry) => {
                            if (keyword.customs !== undefined && Object.keys(keyword.customs).includes(entry)) {
                                if (
                                    resultObject[entry]
                                        .map((obj) => {
                                            if (isArray) {
                                                return keyword.customs[entry].some((keyEntry) => keyEntry.text === obj.text)
                                            } else {
                                                return keyword.customs[entry].text === obj.text
                                            }
                                        })
                                        .includes(true)
                                ) {
                                    if (!this.keywords.some((k) => k === keyword)) {
                                        this.keywords.push(keyword)
                                    }
                                }
                            }
                        })
                    }
                    if (cat_for_filter.length > 0) {
                        if (keyword.cats) {
                            let catsofKeyword = keyword.cats[keyword.active_cat_index]
                            if (catsofKeyword) {
                                cat_for_filter.forEach((catforfilter) => {
                                    if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                        this.keywords.push(keyword)
                                    }
                                })
                            }
                        } else if (!keyword.cats) {
                            let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                            if (findemptycat) this.keywords.push(keyword)
                        }
                    }
                })
                console.log("filtered")
                // this.cardData.subtitle = this.keywords.length + " keywords"
            },
            filterForCat(cat_for_filter) {
                if (cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.carddata.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.forEach((keyword) => {
                    if (keyword.cats) {
                        let catsofKeyword = keyword.cats[keyword.active_cat_index]
                        cat_for_filter.forEach((catforfilter) => {
                            if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                this.keywords.push(keyword)
                            }
                        })
                    } else if (!keyword.cats) {
                        let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                        if (findemptycat) this.keywords.push(keyword)
                    }
                })
                this.carddata.subtitle = this.keywords.length + " keywords"
            },
            fetchAllCats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            /**
             * fetch and add cats to keywords
             * gets called by child component KeywordTable.vue
             */
            async fetchCategories(keywords) {
                try {
                    this.catsLoading = true
                    console.log(this.catData)
                    let userinfo = {
                        id: "additional-data",
                        company_id: 0
                    }
                    if (this.siteKeywordsLocation.ads_lang_code !== null) await dataprocessing.fetchCategories(keywords, this.siteKeywordsLocation, userinfo, this.catData, this.catsLoading, false)
                    this.catsLoading = false
                    if (this.$store.state.active_filter.length) this.filterColumns()

                    this.getListCats()
                    this.getListSerps()
                    this.getListSeasons()
                } catch (error) {
                    console.log(error)
                    this.catsLoading = false
                }
            },
            // fetchCategories(keywords) {
            //     this.catsLoading = true
            //     this.fetchAllCats()
            //     this.$http
            //         .post(process.env.VUE_APP_APIURL + "/sources/dataforseo/categories", { keywords: keywords }, { headers: { Authorization: this.auth } })
            //         .then((response) => {
            //             let catData = response.body
            //             let onlykeywords = keywords.map((x) => x.keyword)
            //             for (let index = 0; index < catData.length; index++) {
            //                 let pos = onlykeywords.indexOf(catData[index].keyword)
            //                 this.keywords[pos].cats = this.sortCats(catData[index].categories)

            //                 if (this.keywords[pos].cats) {
            //                     this.keywords[pos].cats.forEach((cat) => {
            //                         cat.active = false
            //                     })
            //                     this.keywords[pos].cats[0].active = true
            //                 }
            //             }
            //             this.getListCats()
            //             this.catsLoading = false
            //         })
            //         .catch((err) => {
            //             console.log(err)
            //         })
            // },
            getTree(category) {
                let tree = []
                tree[0] = category
                var findParent = (category) => {
                    let parent = this.catData.filter((x) => x.category_code == category.category_code_parent)
                    if (parent[0]) {
                        tree.unshift(parent[0])
                        if (parent[0].category_code_parent) {
                            findParent(parent[0])
                        }
                    }
                }
                if (category.category_code_parent) {
                    findParent(category)
                }
                return tree
            },
            sortCats(allCats) {
                if (!allCats) return null
                let sortedCats = []
                let catRoots = []
                let childCats = allCats.filter((x) => x.category_code_parent)
                for (let index = 0; index < allCats.length; index++) {
                    if (!allCats[index].category_code_parent) {
                        allCats[index].catlevel = 0
                        let rootArr = []
                        rootArr.push(allCats[index])
                        catRoots.push(rootArr)
                    }
                }
                var findChildren = (parent, catlevel) => {
                    // let parentCopy = parent
                    let childsOfRoot = childCats.filter((x) => x.category_code_parent == parent[catlevel].category_code)
                    catlevel++
                    if (childsOfRoot[0]) {
                        childsOfRoot[0].catlevel = catlevel
                        parent.push(childsOfRoot[0])
                        findChildren(parent, catlevel)
                        // if (childsOfRoot.length > 1) {
                        //     for (let index = 1; index < childsOfRoot.length; index++) {
                        //         let rootDouble = parentCopy
                        //         rootDouble.push(childsOfRoot[index])
                        //         catRoots.push(rootDouble)
                        //         findChildren(rootDouble, catlevel)
                        //     }
                        // }
                    } // else{
                    //     parent[catlevel].lastlvl = true
                    // }
                }
                for (let index = 0; index < catRoots.length; index++) {
                    findChildren(catRoots[index], 0)
                }
                catRoots.sort((a, b) => b.length - a.length)
                return catRoots
            },
            getIndexOfCat(cat, catArray) {
                for (let index = 0; index < catArray.length; index++) {
                    if (catArray[index].category_code == cat.category_code) {
                        return index
                    } else {
                        return -1
                    }
                }
                return -1
            },
            removeCat(row, cat) {
                // remove cat
                let rowindex = this.keywords.indexOf(row)
                let catindex = this.keywords[rowindex].cats.indexOf(cat)
                if (this.keywords[rowindex].cats.length < 2) {
                    this.keywords[rowindex].cats = null
                    this.rerenderKeywordTable()
                } else {
                    this.keywords[rowindex].cats.splice(catindex, 1)
                    this.rerenderKeywordTable()
                }
            },
            getListSerps() {
                let serpsOfList = []
                this.keywords.forEach((keyword) => {
                    // console.log(keyword.serp_items)
                    let serps = keyword.serp_items
                    if (serps) {
                        serps.map((x) => {
                            serpsOfList.push(x)
                        })
                    }
                })
                serpsOfList = new Set(serpsOfList)
                serpsOfList = Array.from(serpsOfList)

                serpsOfList.sort((a, b) => a.value - b.value)
                serpsOfList.unshift({ value: "-", tooltip: "-:" })
                console.log(serpsOfList)
                this.serpsOfList = serpsOfList
            },
            getListCats() {
                let catsOfList = []
                this.keywords.forEach((keyword) => {
                    let cats = keyword.cats
                    if (cats) {
                        if (cats[0]) {
                            if (cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1]) {
                                catsOfList.push(cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1])
                            }
                        }
                    }
                })

                catsOfList.sort((a, b) => a.category_name.localeCompare(b.category_name))
                catsOfList.unshift({ category_name: "-" })

                this.catsOfList = catsOfList
            },
            getListSeasons() {
                let seasonsOfList = []
                this.keywords.forEach((keyword) => {
                    if (keyword.season) {
                        seasonsOfList.push(keyword.season.season)
                    }
                })
                seasonsOfList = new Set(seasonsOfList)
                seasonsOfList = Array.from(seasonsOfList)
                console.log(seasonsOfList)

                seasonsOfList.sort()
                seasonsOfList.unshift("-")
                this.seasonsOfList = seasonsOfList
            },
            addCategories(rows, selectedCats) {
                //adds categories to a keyword
                if (!selectedCats[0]) return

                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    for (let index = 0; index < selectedCats.length; index++) {
                        let tree = this.getTree(selectedCats[index])

                        if (this.keywords[rowindex].cats) {
                            this.keywords[rowindex].cats.unshift(tree)
                        } else {
                            this.keywords[rowindex].cats = [tree]
                        }
                        console.log(row)
                        console.log(tree)
                    }
                })
                for (let index = 0; index < selectedCats.length; index++) {
                    let tree = this.getTree(selectedCats[index])
                    this.switchCat(rows, tree)
                }
                this.getListCats()
                this.rerenderKeywordTable()
            },
            rerenderKeywordTable() {
                this.$refs.kw_table.rerenderKeywordTable()
            },
            /**
             *  * sets/changes primary category for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} cat category to set
             */
            switchCat(rows, cat) {
                // switch visible categories
                this.$helpers.switchCat(rows, cat, this.keywords)
                this.getListCats()
                this.rerenderKeywordTable()
            },
            prepareDeleteKw(selected) {
                this.selected = selected
                console.log(selected)
                this.deleteQuestion = true
            },
            deleteKeywords(keywords) {
                if (this.selected.length > 0) {
                    try {
                        for (var i = 0; i < this.selected.length; i++) {
                            let index = this.keywords.indexOf(this.selected[i])
                            this.keywords.splice(index, 1)
                        }
                        if (this.unfilteredKeywords.length > 0) {
                            for (var i = 0; i < this.selected.length; i++) {
                                let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                this.unfilteredKeywords.splice(index, 1)
                            }
                        }
                        this.$matomo.trackEvent("Ideas", "deleteKeywords", "KeywordsCount: " + this.selected.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                    this.$refs.kw_table.keywordsDeleted(kwCount)

                    let selectedNoDoubles = this.$helpers.checkGraphDoubles(this.selected, this.dataChart.datasets)
                    for (var i = 0; i < this.selected.length; i++) {
                        let index = selectedNoDoubles.findIndex((set) => {
                            if (set.keyword == this.selected[i].keyword) return set
                        })
                        if (index > -1) selectedNoDoubles.splice(index, 1)
                    }
                    let kwForGraph = this.keywords
                    if (this.unfilteredKeywords.length > this.keywords.length) {
                        kwForGraph = this.unfilteredKeywords
                    }
                    if (kwForGraph.length > 0) {
                        var graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, kwForGraph, selectedNoDoubles)
                        this.dataChart.datasets = graphObjects.graphdata
                        this.dataChart.labels = graphObjects.graphlabel
                        if (this.dataChart.datasets.length > 1) {
                            this.dataChart.datasets[0].hidden = true
                        } else {
                            this.dataChart.datasets[0].hidden = false
                        }
                        this.countGraph2++
                        this.getListCats()
                        this.getListSerps()
                        this.getListSeasons()
                    }

                    this.selected = []
                    this.keywords.map((x) => {
                        x.selected = false
                    })
                    this.deleteQuestion = false
                    // this.selected = [];
                    this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")

                    this.carddata.subtitle = this.keywords.length + " keywords"
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one Keyword!", "mdi-alert-circle-outline", "orange")
                }
            },
            rerenderChart() {
                this.countGraph2++
            },
            clearChart() {
                try {
                    this.$matomo.trackEvent("Searchvolume", "clearChart", "", "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                let graphObjects = this.$helpers.buildGraphObjects([], this.keywords, this.dataChart.datasets, this.dataChart.labels)
                // console.log(graphObjects)
                this.dataChart.datasets = graphObjects.graphdata
                this.dataChart.labels = graphObjects.graphlabel
                this.countGraph2++
            },
            addToChart(selected) {
                let graphdata = this.dataChart.datasets
                let selectedNoDoubles = this.$helpers.checkGraphDoubles(selected, graphdata)
                if (selected.length > 0) {
                    try {
                        this.$matomo.trackEvent("Ideas", "addToChart", "Keywords: " + selectedNoDoubles.length, "")
                    } catch (error) {
                        console.log(error)
                        console.log("matomo blocked")
                    }
                    //console.log(selectedNoDoubles)
                    if (selectedNoDoubles.length > 0) {
                        if (this.$helpers.checkSelectedSizeForGraph(selectedNoDoubles, 11, graphdata)) {
                            let graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, this.keywords, selectedNoDoubles)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            if (this.dataChart.datasets[0].label == "Sum of all keywords") {
                                this.dataChart.datasets[0].hidden = true
                            }
                            // Hilfsvariable wird hochgezählt und Aktion in linechart.js ausgelöst
                            this.countGraph2++
                        } else {
                            this.notification = this.$helpers.createNotification("Only 10 keywords can be displayed in graph!", "mdi-alert-circle-outline", "#FCBA03")
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("Selected keywords are displayed in the graph already!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            numbercolumn(header) {
                if (header.substr(0, 5) === "Search") return true
                return false
            },
            getAvgSV(svArray) {
                let sum = 0
                for (let index = 0; index < 12; index++) {
                    sum += parseInt(svArray[index].count)
                }
                let avg = sum / 12
                return parseInt(avg.toFixed(0))
            },

            getSparklineData(ranking) {
                let svArray = []
                let svArrayCounter = 0
                for (let searchvolumesCounter = ranking.search_volumes.length - 1; searchvolumesCounter > -1; searchvolumesCounter--) {
                    svArray[svArrayCounter] = ranking.search_volumes[searchvolumesCounter].count
                    svArrayCounter++
                }
                return svArray
            },
            endloading() {
                console.log(this.keywords.length)
                if (this.keywords.length > 0) {
                    this.createHeader(this.keywords)
                    let keywords = this.$helpers.removeDuplicatesByAttribute(this.keywords, "keyword")
                    keywords.sort(function (a, b) {
                        return b.avgsv - a.avgsv
                    })
                    this.keywords = keywords
                    this.fetchCategories(keywords)
                    console.log(keywords)
                    if (this.$store.state.monitoring_suite) this.addRankings("www.xxxlutz.de", 0)
                    let graphObjects = this.$helpers.buildGraphObjects([], keywords, this.dataChart.datasets, this.dataChart.labels)
                    // console.log(graphObjects)
                    this.dataChart.datasets = graphObjects.graphdata
                    this.dataChart.labels = graphObjects.graphlabel
                    this.countGraph2++
                    this.new_data++
                    this.graphloaded = true

                    this.carddata.subtitle = keywords.length + " keywords"
                    this.loading = false
                    this.btndisabled = false
                    this.loaded = true
                    this.progressinterminate = true
                    this.progressvalue = 0
                    console.log(this.keywords)
                } else {
                    this.keywords = []
                    this.loading = false
                    this.loaded = false
                    this.graphloaded = false
                    this.btndisabled = false
                    this.notification = this.$helpers.createNotification("Theres no data for the searched keywords!", "mdi-alert-circle-outline", "#FCBA03")
                    this.progressinterminate = true
                    this.progressvalue = 0
                }
            },
            async getKeywordData(keywords) {
                if (keywords.length === 0) return
                try {
                    this.$matomo.trackEvent("Searchvolume", "Search", "keywords: " + keywords.length, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }

                this.stopRequest = false
                this.responseCounter = 0
                this.loading = true
                this.carddata.title = "Keyword data for"
                this.btndisabled = true
                // Remove new lines and komma
                let inputlines = keywords.split(String.fromCharCode(10))
                //console.log(inputlines)
                let input = []
                for (let inputlinesCounter = 0; inputlinesCounter < inputlines.length; inputlinesCounter++) {
                    let noKomma = inputlines[inputlinesCounter].split(",")
                    for (let noKommaCounter = 0; noKommaCounter < noKomma.length; noKommaCounter++) {
                        input.push(noKomma[noKommaCounter])
                    }
                }

                //clean Input
                let cleanedInput = this.cleanArray(input)

                if (cleanedInput.length > 10000) {
                    this.loading = false
                    this.btndisabled = false
                    this.notification = this.$helpers.createNotification("Please do not enter more than 10.000 keywords!", "mdi-alert-circle-outline", "#FCBA03")
                    return
                }
                //check keywords for length < 81
                let keywordsInvalid = this.$helpers.checkKeywordsLength(cleanedInput)
                console.log(keywordsInvalid)
                if (keywordsInvalid) {
                    this.btndisabled = false
                    this.loading = false
                    this.notification = this.$helpers.createNotification("One keyword cannot be longer than 80 characters!", "mdi-alert-circle-outline", "#FCBA03")
                    return
                }
                this.carddata.searchPhrases = input.splice(0, 10)
                // to lower case
                var cleanedInputLowerCase = cleanedInput.map((v) => v.toLowerCase())

                // let keywordArray = this.splitArray(cleanedInputLowerCase, 1000)
                console.log(cleanedInputLowerCase)
                let inputErrors = this.$helpers.validateUserInput(cleanedInputLowerCase)
                if (inputErrors.length) {
                    console.log(inputErrors)
                    let lines = inputErrors.reduce((lineString, entry) => lineString + (parseInt(entry.index) + 1) + ",", "")
                    console.log(lines)
                    this.notification = this.$helpers.createNotification("Please validate your input. Watch Keywords: " + lines, "mdi-alert-circle-outline", "#FCBA03")
                    this.loading = false
                    this.btndisabled = false
                    return
                }
                this.siteKeywordsLocation = this.selectedLocation
                cleanedInputLowerCase = this.$helpers.replaceSpecialCharacters(cleanedInputLowerCase)
                // start serp preview
                this.serpKeyword = cleanedInputLowerCase[0]
                this.serpReload++

                let keywordArray = this.splitArray(cleanedInputLowerCase, 1000)

                if (this.keepdata === false) {
                    this.keywords = []
                    this.tablerows = []
                    this.selected = []
                }

                if (keywordArray.length > 1) {
                    this.progressinterminate = false
                }

                for (let split = 0; split < keywordArray.length; split++) {
                    if (!this.stopRequest) {
                        console.log("getting data")
                        let answer = await this.fetchKeywordData(keywordArray[split])
                        console.log(answer)
                        if (answer.credits_left == false) {
                            this.keywords = []
                            this.loading = false
                            this.loaded = false
                            this.graphloaded = false
                            this.btndisabled = false
                            this.progressinterminate = true
                            this.progressvalue = 0
                            return
                        }
                        if (!this.progressinterminate) {
                            this.progressvalue = (100 / keywordArray.length) * (split + 1)
                        }
                        console.log(this.progressvalue)
                        console.log("answer")
                    } else {
                        this.loading = false
                        this.btndisabled = false
                        this.keywords = []
                        this.progressinterminate = true
                        this.progressvalue = 0
                        return
                    }
                }
                if (!this.stopRequest) {
                    this.endloading()
                } else {
                    this.loading = false
                    this.btndisabled = false
                    this.keywords = []
                    this.progressinterminate = true
                    this.progressvalue = 0
                    return
                }
            },

            fetchKeywordData(keywordArray) {
                return new Promise(async (resolve, reject) => {
                    console.log(this.selectedLanguage.value)
                    // let date = new Date()
                    // let year = date.getFullYear()
                    // year -= 4
                    // date.setFullYear(year)
                    // date = date.toISOString().split("T")[0]
                    let storedate = this.$store.state.actual_date
                    let month = storedate.split("/")[0]
                    let year = storedate.split("/")[storedate.split("/").length - 1]
                    if (month.length == 1) {
                        month = "0" + month
                    }
                    let date = year - 4 + "-" + month + "-01"
                    this.unfilteredKeywords = []
                    this.$http
                        .post(
                            process.env.VUE_APP_APIURL + process.env.VUE_APP_DATASOURCE,
                            {
                                keywords: keywordArray,
                                language: this.siteKeywordsLocation.ads_lang_code,
                                location: this.siteKeywordsLocation.value,
                                company: localStorage.company_id,
                                user: localStorage.id,
                                date_from: date
                            },
                            { headers: { Authorization: this.auth } }
                        )
                        .then(
                            (response) => {
                                console.log(response)

                                if (response.body.error == "no_credits_left") {
                                    this.notification = this.$helpers.createNotification(
                                        "You dont have any credits left. Please contact the support via chat or email!",
                                        "mdi-alert-circle-outline",
                                        "#FCBA03"
                                    )
                                    resolve({ credits_left: false })
                                }

                                let keywords = []
                                for (let index = 0; index < response.body.totalNumEntries; index++) {
                                    let entry = response.body.entries[index].data[1].value
                                    let annual = 0
                                    for (let sumindex = 0; sumindex < 12; sumindex++) {
                                        annual += parseInt(entry.value[sumindex].count) || 0
                                    }
                                    let row = {
                                        keyword: response.body.entries[index].data[0].value.value,
                                        annualsv: annual,
                                        avgsv: parseInt(annual / 12),
                                        search_volumes: [],
                                        dialog: false,
                                        // categories: response.body.entries[index].data[1].value.categories,
                                        categories: [],
                                        adwords_cpc_high: response.body.entries[index].data[2].value.value.high,
                                        // adwords_cpc: response.body.entries[index].data[2].value.value.high,
                                        adwords_cpc_low: response.body.entries[index].data[2].value.value.low,
                                        adwords_competition:
                                            response.body.entries[index].data[3].value && response.body.entries[index].data[3].value.value !== null
                                                ? response.body.entries[index].data[3].value.value.toFixed(0)
                                                : null,
                                        active_cat_index: 0
                                    }

                                    // starts estimated visits calculation in serp preview
                                    if (row.keyword == this.serpKeyword) {
                                        this.preview_sv_data = { keyword: this.serpKeyword, value: row.avgsv }
                                    }

                                    for (let monthCounter = 0; monthCounter < entry.value.length; monthCounter++) {
                                        let volume = {
                                            count: parseInt(entry.value[monthCounter].count) || 0,
                                            year: entry.value[monthCounter].year,
                                            month: entry.value[monthCounter].month
                                        }
                                        row.search_volumes.push(volume)

                                        let svname = ""
                                        if (entry.value[monthCounter].month < 10) {
                                            svname = entry.value[monthCounter].year.toString() + "0" + entry.value[monthCounter].month.toString()
                                        } else {
                                            svname = entry.value[monthCounter].year.toString() + entry.value[monthCounter].month.toString()
                                        }
                                        row[svname] = parseInt(entry.value[monthCounter].count) || 0
                                    }
                                    this.keywords.push(row)
                                }
                                console.log(this.keywords)
                                // this.fetchCategories(this.keywords)
                                resolve(true)
                            },
                            (response) => {
                                resolve(true)
                                //console.log(response)
                            }
                        )
                })
            },
            createHeader(keywords) {
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                const researchHeaderIndex = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                let modifiedHeaderIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")

                // reset SV Headers
                this.headers.standardHeaders[researchHeaderIndex].columns = []
                if (this.headers.modifiedHeaders.length > 0) {
                    this.headers.modifiedHeaders[modifiedHeaderIndex].columns = []
                }

                let headers = []
                keywords.map((keyword) => {
                    keyword.search_volumes.map((vol) => {
                        let header = {
                            group: 1,
                            custom: false,
                            active: true,
                            canBeModified: false,
                            modifiedText: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            text: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            sortable: true,
                            width: "160"
                        }
                        if (vol.month < 10) {
                            header.value = vol.year.toString() + "0" + vol.month.toString()
                        } else {
                            header.value = vol.year.toString() + vol.month.toString()
                        }
                        header.dataChartLabel = monthArray[vol.month] + " " + vol.year
                        // console.log(headers.findIndex(p => p.text === header.text) === -1 )
                        if (headers.findIndex((p) => p.text === header.text) === -1) headers.push(header)
                    })
                })
                headers.sort(function (a, b) {
                    return a.value - b.value
                })
                this.headers.standardHeaders[researchHeaderIndex].columns = headers
                if (this.headers.modifiedHeaders.length == 0) {
                    this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                } else {
                    this.headers.modifiedHeaders[modifiedHeaderIndex].columns = headers
                }
                console.log("Erstelle Header", this.headers)
            },
            fetchSERP(selected) {
                console.log("fetchSERP:", selected.length)
                if (!selected || selected.length == 0) {
                    this.notification = this.$helpers.createNotification("You have to select one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                    return
                }
                this.addToSerpDisabled = true

                this.serpKeyword = selected[0].keyword
                this.preview_sv_data = { keyword: selected[0].keyword, value: selected[0].avgsv }
                this.serpReload++
            },
            cleanArray(arr) {
                return arr.filter((e) => {
                    return e != false && e !== null && e !== " " && e !== ""
                })
            },
            splitArray(myArray, chunk_size) {
                let myChunk = []
                let tempArray = []
                myArray = this.cleanArray(myArray) // hier weiter
                for (let index = 0; index < myArray.length; index += chunk_size) {
                    myChunk = myArray.slice(index, index + chunk_size)
                    tempArray.push(myChunk)
                }
                return tempArray
            },
            checkIfSendTo() {
                if (this.$store.state.send_to_keywords.length) {
                    this.keywords_data = this.$store.state.send_to_keywords.reduce((a, b) => a + "\n" + b)
                    // this.getKeywordData(this.keywords_data)
                    this.$store.dispatch("SET_SEND_TO_KEYWORDS", [])
                }
            }
        },
        beforeRouteLeave(to, from, next) {
            // if (this.unfilteredKeywords.length) this.keywords = [...this.unfilteredKeywords]
            next()
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.fetchAllCats()
                vm.headers.standardHeaders = JSON.parse(JSON.stringify(standardHeaders))
                // if (vm.keywords.length) {
                //     vm.unfilteredKeywords = [...vm.keywords]
                //     vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
                // }
                vm.checkIfSendTo()

                //test end check
                if (vm.$store.state.company_status === "test_ended") {
                    vm.$router.push("/endoftest")
                }
            })
        },
        mounted() {}
    }
</script>

<style scoped>
    .tablecard {
        margin-bottom: 1%;
    }
    .v-card {
        margin-top: 20px;
    }
    .chartBtn {
        float: right !important;
    }
    .chartCardHeading {
        padding-top: 23px;
        padding-left: 23px;
    }
    #cardtitle {
        padding-bottom: 15px;
        padding-top: 15px;
        background-color: var(--v-primary-base);
        color: white;
    }
    .Innenabstand {
        padding: 20px;
    }
    #kwpformflex {
        padding-top: 0%;
    }
    #kwpform {
        padding-left: 0.5%;
    }
    #searchbutton {
        text-align: center;
    }
    .seitenabstand {
        padding-left: 25px;
        padding-right: 25px;
    }
    .spark {
        padding: 0%;
    }
    .v-card .v-divider {
        border-color: white;
    }
    .fabicon {
        margin-left: 10px;
        margin-top: 10px;
    }
    #buttonFlex {
        padding-left: 2px;
    }
    #sendBtn {
        height: 56px !important;
    }
    #searchRow {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        /* width: 99%; */
        padding-top: 0px !important;
    }
    h2 {
        font-family: Nunito;
        text-transform: uppercase;
        font-weight: 500 !important;
        color: var(--v-primary-base);
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    #searchbar {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }
    .centerVertical {
        margin-top: 25px !important;
    }
</style>
