<template>
    <div>
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <AddTopicsDialog
            :active="{ process: 'setup' }"
            :show="addTopicsDialogOptions.show"
            :project="project"
            @started="
                topic_fetch_loading = true
                addTopicsDialogOptions.show = false
            "
            @added="addFetchedTopics"
            @close="addTopicsDialogOptions.show = false"
            @failed="
                notification = $helpers.createNotification('Something went wrong fetching your keywords! Please try again or contact support.', 'mdi-alert', 'orange darken-2')
                topic_fetch_loading = false
            "></AddTopicsDialog>
        <div v-if="loading" class="loaderwrapper">
            <v-row class="loaderimg">
                <img id="loader" src="../../../assets/quaro-q.svg" />
            </v-row>
            <v-row class="loaderheading mt-10">
                <span style="font-weight: 300; font-size: 16px" class="mx-auto">{{ statusNotification.message }}...</span>
            </v-row>
            <v-row class="ma-auto">
                <v-col>
                    <div id="loaderdiv">
                        <v-progress-linear
                            :indeterminate="statusNotification.message ? false : true"
                            rounded
                            height="6px"
                            class="ma-auto"
                            color="primary"
                            :value="statusNotification.progress"></v-progress-linear>
                    </div>
                </v-col>
            </v-row>
            <v-col class="d-flex mt-10" v-if="statusNotification.type == 'error'">
                <v-btn color="primary" class="mx-auto" @click="$emit('retryProcess', 1)"> Retry <v-icon>mdi-refresh</v-icon> </v-btn>
            </v-col>
            <v-row class="mt-10">
                <p class="loadersubtext">
                    Note: Based on the main keyword you provided, we will now identify relevant keywords that you can serve with the same landing page. This usually only takes a few seconds.
                </p>
            </v-row>
        </div>
        <div v-else>
            <h2 style="font-weight: 300">Select Focus Keywords</h2>
            <p style="max-width: 1000px">
                Here you can see topics for which a subset of your competitors appear in Google search results. Choose the keyword that you want to cover with your website and for which you want to be
                found in the search results.
            </p>
            <p class="note" v-if="disabled"><v-icon class="pb-1" small color="primary">mdi-alert-circle</v-icon> Once the setup is finished, the selected focus keywords cannot be change!</p>
            <v-fade-transition>
                <div v-if="topic_chips.length" class="mb-5 topicsarea">
                    <h4 class="mb-1">Selected Topics:</h4>
                    <v-chip class="white--text mr-2" close v-for="(topic_chip, index) in topic_chips" :key="index" :color="topic_chip.color" @click:close="removeTopic(index)">{{
                        topic_chip.topic
                    }}</v-chip>
                </div>
            </v-fade-transition>
            <v-row no-gutters v-if="!disabled">
                <v-col cols="12" class="ml-auto">
                    <v-row class="ml-auto" no-gutters>
                        <!-- <v-slide-x-transition>
                            <v-text-field
                                v-if="addManually"
                                class="ml-auto mr-3"
                                dense
                                single-line
                                rounded
                                filled
                                v-model="topic_manual"
                                label="enter manually"
                                hide-details
                                @keyup.enter="add"></v-text-field>
                        </v-slide-x-transition> -->
                        <v-btn rounded class="ml-auto mb-2" color="primary" @click="addTopicsDialogOptions.show = true" :loading="topic_fetch_loading">enter manually</v-btn>
                        <span class="mt-2 mx-3">or</span>
                        <v-btn rounded color="primary" @click="addFromClipboard">add from clipboard</v-btn>
                    </v-row>
                </v-col>
            </v-row>

            <div class="comp-table-area">
                <v-row class="pa-4">
                    <span>
                        <v-chip class="white--text" color="darkgreen" small> {{ selected.length }} selected</v-chip>
                    </span>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-text-field
                        clearable
                        clear-icon="mdi-close"
                        dense
                        rounded
                        filled
                        v-model="search"
                        max-width="300px"
                        prepend-inner-icon="mdi-magnify"
                        label="search keywords..."
                        single-line
                        hide-details></v-text-field>
                </v-row>
                <v-data-table
                    readonly
                    id="cs_comp_table"
                    :show-select="!disabled"
                    :search="search"
                    v-model="selected"
                    fixed-header
                    height="600px"
                    :loading="!project.topics.length"
                    :headers="headers"
                    :sort-by="'keyword_info.search_volume'"
                    :sort-desc="true"
                    :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
                    :items-per-page="25"
                    :items="project.topics"
                    class="elevation-0 comp-table"
                    @toggle-select-all="selectPage">
                    <!-- header slot -->

                    <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="quarotblheader">
                                            {{ header.text }}
                                            <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>

                    <!-- Table Body -->
                    <template v-slot:body="{ items }">
                        <tbody v-if="items.length">
                            <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                                <td class="selecttd" v-if="!disabled">
                                    <v-icon :disabled="disabled" v-if="!item.selected" @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-blank-outline</v-icon>
                                    <v-icon :disabled="disabled" v-else @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-marked</v-icon>
                                </td>
                                <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                                    <!-- ------- -->
                                    <!-- keyword -->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'keyword'" class="td_keyword">
                                        {{ item[header.value] }}
                                        <v-chip small color="grey" class="white--text ml-3" v-if="item[header.value] == project.main_keyword">main keyword</v-chip>
                                    </div>
                                    <!-- -------------- -->
                                    <!---- relevance ----->
                                    <!-- -------------- -->
                                    <div v-if="item.serp_info && header.value === 'serp_info.keyword_difficulty'">
                                        <v-progress-linear
                                            style="border-radius: 12px; font-size: 12px"
                                            class="white--text"
                                            rounded
                                            :value="item.serp_info.keyword_difficulty"
                                            color="primary"
                                            height="24">
                                            {{ item.serp_info.keyword_difficulty || 0 }}%
                                        </v-progress-linear>
                                    </div>
                                    <!-- -------------- -->
                                    <!---- avgsv ----->
                                    <!-- -------------- -->
                                    <div v-if="header.value === 'keyword_info.search_volume'">
                                        <v-chip small color="primary"> {{ $helpers.formatNumber(item.keyword_info.search_volume) }}</v-chip>
                                    </div>
                                    <!-- ------------ -->
                                    <!--  serp_items  -->
                                    <!-- ------------ -->
                                    <!-- <div v-if="header.value === 'serp_items'" id="serp_items_cell">
                                        <SERPTypesCell
                                            :serp_items="getSERPItemsObject(item.serp_info.serp_item_types)"
                                            :showAll="showAllSERPItems"
                                            @toggle="showAllSERPItems = !showAllSERPItems"></SERPTypesCell>
                                    </div> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5">
                                    <center>Sorry! No topics found...</center>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
    // import { colorCodes } from "../../helper/global.js"
    import AddTopicsDialog from "../../content-suite/AddTopicsDialog.vue"
    import SERPTypesCell from "../../keyword-table/SERPTypesCell.vue"
    import { all_serp_items } from "../../helper/serp_items.js"
    export default {
        components: {
            AddTopicsDialog,
            SERPTypesCell
        },
        props: {
            project: Object,
            loading: Boolean,
            statusNotification: Object,
            selectedTopics: Array
        },
        data: () => ({
            addTopicsDialogOptions: {
                show: false
            },
            showAllSERPItems: false,
            topic_fetch_loading: false,
            addTopicDialog: false,
            addManually: false,
            colors: ["cyan darken-3", "purple darken-3", "lime darken-3", "light-green darken-3", "blue darken-3", "orange darken-3", "red darken-3"],
            topic_manual: "",
            topic_chips: [],
            search: "",
            headers: [
                {
                    text: "KEYWORD",
                    value: "keyword",
                    sortable: true
                },
                {
                    text: "Ø SEARCH VOLUME",
                    value: "keyword_info.search_volume",
                    sortable: true
                },
                {
                    text: "COMPETITION",
                    value: "serp_info.keyword_difficulty",
                    sortable: true,
                    width: "200px"
                }
                // {
                //     sortable: false,
                //     text: "SERP Items",
                //     value: "serp_items",
                //     tooltip: "SERP features represent the elements that constitute the search engine result page.",
                //     width: "150px",
                //     align: "left"
                // }
            ],
            notification: { show: false }
        }),
        methods: {
            getSERPItemsObject(serp_items) {
                console.log(serp_items)
                return serp_items.map((item) => all_serp_items.find((e) => e.value == item))
            },
            addFromClipboard() {
                if (!this.$store.state.clipboard_keywords.length) {
                    this.notification = this.$helpers.createNotification("Your clipboard is empty! Please push keywords to your clipboard first.", "mdi-alert", "orange darken-2")
                    return
                }

                // check if clipboard keywords and topics > 5, if yes unselect all topics
                let selected = this.project.topics.filter((topics) => topics.selected)
                if (selected.length + this.$store.state.clipboard_keywords.length > 5) {
                    this.project.topics = this.project.topics.map((x) => {
                        x.selected = false
                        return x
                    })
                }
                console.log(this.$store.state.clipboard_keywords)
                console.log(this.project.topics)
                let formatted_clipboard_keywords = this.$store.state.clipboard_keywords.map((x, i) => {
                    let keyword_object = {}
                    if (this.project.topics.findIndex((topic) => topic.keyword == x.keyword) == -1) {
                        keyword_object = {
                            keyword: x.keyword,
                            keyword_info: {
                                monthly_searches: x.search_volumes.map((y) => {
                                    y.year = parseInt(y.year)
                                    return y
                                }),
                                search_volume: x.avgsv
                            },
                            serp_info: {
                                keyword_difficulty: x.keyword_difficulty ? x.keyword_difficulty.difficulty : 0
                            },
                            selected: i < 5 ? true : false
                        }

                        keyword_object.keyword_info.monthly_searches.length = 12
                        this.project.topics.push(keyword_object)
                    }
                    return keyword_object
                })
                console.log(formatted_clipboard_keywords)
                this.notification = this.$helpers.createNotification("Keywords added from clipboard!", "mdi-check-circle-outline", "primary")
            },
            addFetchedTopics(results) {
                this.topic_fetch_loading = false
                if (!results.length) {
                    this.notification = this.$helpers.createNotification("We could not find any keywords for our query!", "mdi-alert", "orange darken-2")
                    return
                }
                // check if clipboard keywords and topics > 5, if yes unselect all topics
                let selected = this.project.topics.filter((topics) => topics.selected)
                if (selected.length + results.length > 5) {
                    this.project.topics = this.project.topics.map((x) => {
                        x.selected = false
                        return x
                    })
                }

                let formatted_fetched_keywords = results.map((x, i) => {
                    let keyword_object = x
                    if (this.project.topics.findIndex((topic) => topic.keyword == x.keyword) == -1) {
                        keyword_object.keyword_info.monthly_searches.length = 12
                        if (!keyword_object.serp_info) keyword_object.serp_info = {}
                        keyword_object.selected = i < 5 ? true : false
                        keyword_object.serp_info.keyword_difficulty = !x.keyword_properties ? 0 : x.keyword_properties.keyword_difficulty ? x.keyword_properties.keyword_difficulty : 0
                        this.project.topics.push(keyword_object)
                    }
                    console.log(keyword_object)
                    return keyword_object
                })

                this.notification = this.$helpers.createNotification("Keywords added!", "mdi-check-circle-outline", "primary")

                // this.topic_chips.push({ topic: this.topic_manual, color: this.colors[this.topic_chips.length - 1] })
                // this.project.topics.push(this.topic_manual)
                // this.topic_manual = ""
                // this.$emit("updateProject")
            },
            removeTopic(index) {
                let topicindex = this.project.topics.indexOf(this.topic_chips[index].topic)
                this.project.topics.splice(topicindex, 1)
            },
            selectItem(index, item) {
                let visibleIndex = this.project.topics.indexOf(item)
                // if (!this.project.topics[visibleIndex].selected) {
                //     this.project.topics[visibleIndex].selected = false
                // }
                if (this.selected.length == 5 && this.project.topics[visibleIndex].selected == false) {
                    this.notification = this.$helpers.createNotification("You cannot selet more than 5 focus topics!", "mdi-alert", "orange darken-2")
                    return
                }
                this.project.topics[visibleIndex].selected = !this.project.topics[visibleIndex].selected
                // this.$emit("updateKeywords", [item])
            },
            selectPage(event) {
                console.log(event)
                if (event.value == true) {
                    event.items.map((x, i) => {
                        let index = this.project.topics.findIndex((y) => y == x)
                        if (index > -1) {
                            if (i < 5) this.project.topics[index].selected = true
                        }
                        // this.project.topics.map((topic, index) => {
                        //     if (index < 5) {
                        //         topic.selected = true
                        //     }
                    })
                } else {
                    this.project.topics.map((topic) => {
                        topic.selected = false
                    })
                }
                // console.log(event.items)
                // let kwToUpdate = []
                // event.items.map((item) => {
                //     let kw = this.project.topics.find((keyword) => keyword.keyword === item.keyword)
                //     if (kw) {
                //         kw.selected = !kw.selected
                //         kwToUpdate.push(kw)
                //     }
                // })
                // this.$emit("updateKeywords", kwToUpdate)
                // let selectedPageItems = event.items.filter((x) => x.selected)
                // if (event.value && selectedPageItems.length == 0) {
                //     //select
                //     event.items.map((x) => {
                //         x.selected = true
                //         this.selected.push(x)
                //     })
                // } else {
                //     //unselect
                //     event.items.map((x) => {
                //         const index = this.selected.findIndex((selectedRow) => selectedRow.keyword == x.keyword)
                //         if (index > -1) {
                //             this.selected.splice(index, 1)
                //         }
                //         x.selected = false
                //     })
                // }
            }
        },
        computed: {
            selected: {
                get() {
                    return this.project.topics.filter((topics) => topics.selected)
                },
                set() {}
            },
            disabled() {
                return this.project ? this.project.setup.finished : true
            }
        },
        watch: {
            project: {
                handler(newValue, oldValue) {
                    if (this.project.topics.length) {
                        console.log(this.project.topics)
                    }
                    console.log("WATCHHHHHHHHHHHHHH")
                    // this.topic_chips = this.selectedTopics.map((topic, index) => {
                    //     let chip = { topic: topic.keyword, color: this.colors[index] }
                    //     return chip
                    // })
                },
                deep: true
            }
        }
    }
</script>

<style scoped lang="scss">
    #cs_comp_table > div.v-data-table__wrapper > table > thead > tr > th {
        text-transform: uppercase !important;
    }
    h4 {
        font-weight: 300;
        margin-bottom: 10px;
    }
    .topicsarea {
        padding: 7px 12px;
        border-radius: 5px;
        color: black;
        background-color: #f2f2f2;
        &:hover {
            background-color: #e2e2e2;
        }
    }

    .loaderwrapper {
        padding: 50px;
    }

    .loaderimg {
        margin: auto;
    }
    #loader {
        margin: auto;
    }
    .highlighted {
        background-color: rgb(229, 229, 229);
    }
    .theme--dark .highlighted {
        background-color: rgb(122, 122, 122);
    }
    .note {
        font-size: 12px;
    }
</style>
<style lang="scss">
    #loaderdiv {
        margin: auto;
        max-width: 300px;
    }
    .loadersubtext {
        text-align: center;
        max-width: 700px;
        margin: auto;
        color: grey;
        font-size: 12px;
        &.highlighted {
            color: var(--v-primary-base);
            font-weight: bold;
            font-size: 14px;
        }
    }
</style>
