<template>
    <div>
        <v-card flat>
            <!-- <div class="backsheet"></div> -->

            <v-card-title class="briefing_card_label">
                <!-- INSPIRATION
                <v-tooltip max-width="250" color="" top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                    </template>
                    <span
                        >The "Inspiration" field specifies some of the competitors' websites that can be used as inspiration for the content of the project. It is important to note that these websites
                        should only be used as a reference point and not as a source of content to be copied.</span
                    >
                </v-tooltip> -->
            </v-card-title>
            <v-data-table id="bitable" fixed-header height="550px" :headers="headers" :items="competitors" class="elevation-0 comp-table" item-key="id">
                <!-- header slot -->
                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                    <span :key="index">
                        <span v-bind:style="{ width: header.width }">
                            <!-- Adding tooltips to header -->
                            <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ header.text }}
                                        <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                    </span>
                                </template>
                                <span>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </span>
                            </v-tooltip>
                        </span>
                    </span>
                </template>

                <!-- Table Body -->
                <template v-slot:body="{ items }">
                    <tbody v-if="items.length">
                        <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                            <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="table_td">
                                <!-- ------- -->
                                <!-- position -->
                                <!-- ------- -->
                                <div v-if="header.value === 'domain'" class="td_position" style="padding: 7px">
                                    <v-row no-gutters>
                                        <v-col cols="2" style="display: flex">
                                            <div class="fav_wrapper"><img class="fav" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item[header.value] + '&sz=24'" alt="" /></div>
                                        </v-col>
                                        <v-col cols="10">
                                            <div class="domain_td">{{ item[header.value] }}</div>
                                            <div class="path_td">{{ item.path }}</div></v-col
                                        >
                                    </v-row>
                                </div>
                                <!-- -------------- -->
                                <!---- TITLE ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'title'">
                                    {{ item[header.value] }}
                                </div>
                                <div v-if="header.value == 'actions'" class="mr-5">
                                    <v-btn color="primary" icon :href="item.url" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    export default {
        props: {
            competitors: Array
        },
        data() {
            return {
                headers: [
                    {
                        text: "Competitor",
                        value: "domain", //domain and path
                        width: "20",
                        sortable: true,
                        width: 350
                    },
                    {
                        text: "Title",
                        align: "left",
                        sortable: true,
                        value: "title"
                    },
                    {
                        text: "",
                        value: "actions",
                        align: "left",
                        sortable: true
                    }
                ],
                tblOptions: {
                    "footer-props": {
                        "items-per-page-options": [5, 10, 15]
                    }
                },
                perpage: 10
            }
        },
        methods: {}
    }
</script>

<style scoped>
    /* .briefing_td_keyword {
        font-weight: bold !important;
    } */
    .domain_td {
        /* font-weight: bold; */
    }
</style>
