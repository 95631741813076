var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"650px"},on:{"click:outside":function($event){return _vm.cancel(true)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"outline_card_label"},[_vm._v("AI Headline Generator "),_c('v-btn',{attrs:{"right":"","absolute":"","icon":""},on:{"click":function($event){return _vm.cancel(true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',[_vm._v("Generate headlines for your content article using AI")]),(_vm.showResponse)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showResponse = false}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" try again")],1):_vm._e(),(_vm.loading)?_c('v-card-text',[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"mx-auto my-10"},[_c('img',{attrs:{"id":"loader","src":require("../../../assets/quaro-q.svg")}})])]),_c('v-col',{staticClass:"d-flex mt-5"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancel(false)}}},[_vm._v("Cancel")])],1)],1):(!_vm.showResponse && !_vm.errorMessage)?_c('v-card-text',[_c('div',{staticClass:"qgreycard"},[_c('div',{staticClass:"quarolbl pb-1"},[_vm._v(" Output Language "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The desired languagethe generated outline should be written in!")])])],1),_c('v-select',{attrs:{"hide-details":"","rounded":"","filled":"","items":_vm.languages},model:{value:(_vm.outputLanguage),callback:function ($$v) {_vm.outputLanguage=$$v},expression:"outputLanguage"}})],1),_c('div',{staticClass:"qgreycard"},[_c('div',{staticClass:"quarolbl pb-1"},[_vm._v(" number of variations "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The desired language the generated outline should be written in!")])])],1),_c('v-chip-group',{attrs:{"mandatory":"","color":"cyan darken-2"},model:{value:(_vm.optionsCount),callback:function ($$v) {_vm.optionsCount=$$v},expression:"optionsCount"}},_vm._l((_vm.variants),function(variant,index){return _c('v-chip',{key:variant,attrs:{"filter":"","close-icon":"mdi-close-circle-outline","filter-icon":"mdi-check-circle"}},[_vm._v(" "+_vm._s(variant + 1)+" "+_vm._s(index > 0 ? "Variants" : "Variant")+" ")])}),1)],1)]):_c('v-card-text',[(_vm.errorMessage)?_c('div',[_c('v-row',[_c('v-icon',{staticClass:"mx-auto",attrs:{"size":"100","color":"warning"}},[_vm._v("mdi-alert")])],1),_c('v-row',[_c('v-btn',{staticClass:"ml-auto mr-1",attrs:{"outlined":"","color":"grey"},on:{"click":function($event){_vm.showResponse = false
                                _vm.errorMessage = null}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-auto ml-1",attrs:{"outlined":"","color":"grey"},on:{"click":_vm.generate}},[_vm._v("Retry")])],1)],1):_vm._e(),_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.responses),function(items,index){return _c('v-tab',{key:index},[_vm._v(" "+_vm._s("Variant " + (index + 1))+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.responses),function(items,index){return _c('v-tab-item',{key:index},[_c('v-treeview',{staticClass:"pb-5",attrs:{"id":"outlinetree","items":[items],"item-key":"id","open-all":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
                                var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-divider',{attrs:{"vertical":"","color":"red"}}),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(item.text))])],1)]}},{key:"prepend",fn:function(ref){
                                var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.placementColors[item.tag]},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.tag))])]}}],null,true)})],1)}),1)],1),(!_vm.showResponse && !_vm.errorMessage && !_vm.loading)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.generate}},[_vm._v("generate")])],1):_vm._e(),(_vm.showResponse && !_vm.errorMessage)?_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('insertOutline', _vm.responses[_vm.tab])}}},[_vm._v("save outline")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }