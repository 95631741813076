<template>
    <div class="mb-10 setup">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <v-row>
            <v-col xl="6" lg="8" md="8" sm="12">
                <v-slide-x-transition>
                    <v-card class="mb-5 rounded_card details_card quaroshadow" v-if="project._id">
                        <v-row no-gutters>
                            <div class="title_area">
                                <div class="titlelbl white--text">PROJECT</div>
                                <div class="cs_p_title">{{ project.title }}</div>

                                <div class="mkwlbl">MAIN KEYWORD</div>
                                <div class="cs_p_kw">{{ project.main_keyword }}</div>
                            </div>
                            <div class="details_area">
                                <div class="setup_progress_bar">
                                    <v-progress-linear height="7px" rounded :value="(e1 / 6) * 100"></v-progress-linear>
                                    <span class="setup_progress_bar_text">Step {{ e1 }}/ 6</span>
                                </div>
                                <div class="my-auto">
                                    <div class="titlelbl">URL</div>
                                    <div class="cs_url mb-3" v-if="project.url && !url_loading">
                                        {{ project.url }}
                                        <v-icon
                                            small
                                            v-if="edit"
                                            @click="
                                                addURLDialog.id = project._id
                                                addURLDialog.show = true
                                                addURLDialog.url = project.url
                                            "
                                            >mdi-pencil</v-icon
                                        >
                                    </div>
                                    <div v-else-if="url_loading">
                                        <v-progress-circular indeterminate color="primary" size="16" width="2"></v-progress-circular>
                                    </div>
                                    <div v-else class="mb-3">
                                        <v-btn
                                            @click="
                                                addURLDialog.id = project._id
                                                addURLDialog.show = true
                                            "
                                            class="px-1"
                                            x-small
                                            outlined
                                            color="primary">
                                            <v-icon class="mr-1" x-small>mdi-plus-circle-outline</v-icon>add url</v-btn
                                        >
                                    </div>
                                    <div class="titlelbl mb-1">TOPICS</div>
                                    <div class="cs_topics mb-2" v-if="selectedTopics.length">
                                        <v-chip class="white--text mr-2 mb-1" v-for="(topic, index) in selectedTopics" :key="index" :color="colors[index]">{{ topic.keyword }}</v-chip>
                                    </div>
                                    <div class="cs_topics mb-3" v-else>{{ selectedTopics.length }} selected</div>
                                    <div class="titlelbl">COMPETITORS</div>
                                    <div class="keywordcount mb-3">{{ selectedCompetitors.length }} selected</div>
                                    <div class="titlelbl">KEYWORDS</div>
                                    <div class="keywordcount mb-3">{{ selectedKeywords.length }} selected</div>
                                </div>
                            </div>
                        </v-row>
                    </v-card>
                </v-slide-x-transition>
            </v-col>
        </v-row>

        <v-stepper rounded v-model="e1" alt-labels non-linear class="rounded_card quaroshadow">
            <v-stepper-header elevation-0>
                <v-stepper-step :complete="e1 > 1" step="1"> Setup </v-stepper-step>

                <v-divider v-if="!edit"></v-divider>
                <v-stepper-step v-if="!edit" :complete="e1 > 2" step="2"> Select Keywords </v-stepper-step>

                <v-divider v-if="!edit && !project.setup.fastmode"></v-divider>
                <v-stepper-step v-if="!edit && !project.setup.fastmode" :complete="e1 > 3" step="3"><span style="white-space: nowrap"> Select Competitors</span></v-stepper-step>

                <v-divider v-if="!project.setup.fastmode"></v-divider>
                <v-stepper-step v-if="!project.setup.fastmode" :complete="e1 > 4" step="4"> Select Terms </v-stepper-step>

                <v-divider v-if="!project.setup.fastmode"></v-divider>
                <v-stepper-step v-if="!project.setup.fastmode" :complete="e1 > 5" step="5"> Select Questions </v-stepper-step>

                <v-divider v-if="!project.setup.fastmode"></v-divider>
                <v-stepper-step v-if="!project.setup.fastmode" :complete="e1 > 6" step="6"> Settings </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <!---   CreateProject    --->
                <!-- ------ Step 1 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="1">
                    <CreateProject :project="project" :edit="edit"></CreateProject>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                $router.push('/content-projects')
                                trackEvent('CS - Setup', 'Cancel - Step ' + project.setup.step, '', '')
                            ">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" @click="createProject" :disabled="create_button_disabled"> Continue </v-btn>
                    </v-row>
                </v-stepper-content>

                <!-- ---- AddTopics ----- -->
                <!-- ------ Step 2 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="2" v-if="!edit">
                    <SelectTopics :project="project" :loading="addTopicsLoading" :statusNotification="statusNotification" @retryProcess="retryProcess"></SelectTopics>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                $router.push('/content-projects')
                                trackEvent('CS - Setup', 'Cancel - Step ' + project.setup.step, '', '')
                            ">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            class="mr-2"
                            :disabled="project.setup.loading"
                            @click="
                                e1--
                                trackEvent('CS - Setup', 'Back - Step ' + project.setup.step, '', '')
                            ">
                            Back
                        </v-btn>
                        <v-btn
                            :disabled="project.setup.loading"
                            color="primary"
                            @click="
                                updateProject(3)
                                trackEvent('CS - Setup', 'Continue - Step ' + project.setup.step, '', '')
                            ">
                            Continue
                        </v-btn>
                    </v-row>
                </v-stepper-content>

                <!--  select-competitors  -->
                <!-- ------ Step 3 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="3" v-if="!edit && !project.setup.fastmode">
                    <select-competitors
                        :loading="addCompetitorsLoading"
                        :url_loading="url_loading"
                        :project="project"
                        :statusNotification="statusNotification"
                        @retryProcess="retryProcess"></select-competitors>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                $router.push('/content-projects')
                                trackEvent('CS - Setup', 'Cancel - Step ' + project.setup.step, '', '')
                            ">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            :disabled="project.setup.loading"
                            class="mr-2"
                            @click="
                                e1--
                                trackEvent('CS - Setup', 'Back - Step ' + project.setup.step, '', '')
                            ">
                            Back
                        </v-btn>
                        <v-btn
                            :disabled="project.setup.loading"
                            color="primary"
                            @click="
                                updateProject(4)
                                trackEvent('CS - Setup', 'Continue - Step ' + project.setup.step, '', '')
                            ">
                            Continue
                        </v-btn>
                    </v-row>
                </v-stepper-content>
                <!--    fastMode   -->
                <!-- ------ End------ -->
                <!-- -------------------- -->

                <v-stepper-content step="3" v-if="!edit && project.setup.fastmode">
                    <div class="loaderwrapper">
                        <v-row class="loaderimg mb-10">
                            <img id="loader" src="../../assets/quaro-q.svg" />
                        </v-row>
                        <v-row class="loaderheading">
                            <span style="font-weight: 300; font-size: 16px" class="mx-auto">{{ statusNotification.message }}</span>
                        </v-row>
                        <v-row class="ma-auto">
                            <v-col>
                                <div id="loaderdiv">
                                    <v-progress-linear
                                        :indeterminate="statusNotification.message ? false : true"
                                        rounded
                                        height="6px"
                                        class="ma-auto"
                                        color="primary"
                                        :value="statusNotification.progress"></v-progress-linear>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="mt-10">
                            <p class="loadersubtext">Note: Based on your selected focus topics, we now identify the most relevant and strongest competitors. This usually only takes a few seconds!</p>
                        </v-row>
                    </div>
                </v-stepper-content>
                <!--    select-keywords   -->
                <!-- ------ Step 4 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="4">
                    <select-terms
                        @updateKeywords="updateKeywords"
                        @retryProcess="retryProcess"
                        setup
                        :statusNotification="statusNotification"
                        :loading="addKeywordsLoading"
                        :project="project"
                        :topKeywords="topKeywords"></select-terms>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                $router.push('/content-projects')
                                trackEvent('CS - Setup', 'Cancel - Step ' + project.setup.step, '', '')
                            ">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            :disabled="project.setup.loading"
                            class="mr-2"
                            @click="
                                !edit ? e1-- : (e1 = 1)
                                trackEvent('CS - Setup', 'Back - Step ' + project.setup.step, '', '')
                            ">
                            Back
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="project.setup.loading"
                            @click="
                                updateProject(5)
                                trackEvent('CS - Setup', 'Continue - Step ' + project.setup.step, '', '')
                            ">
                            Continue
                        </v-btn>
                    </v-row>
                </v-stepper-content>

                <!-- --SelectQuestions--- -->
                <!-- ------ Step 5 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="5">
                    <SelectQuestions :questions="project.questions" @updateQuestions="updateQuestions" setup></SelectQuestions>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                $router.push('/content-projects')
                                trackEvent('CS - Setup', 'Cancel - Step ' + project.setup.step, '', '')
                            ">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                e1--
                                trackEvent('CS - Setup', 'Back - Step ' + project.setup.step, '', '')
                            ">
                            Back
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="
                                updateProject(6)
                                trackEvent('CS - Setup', 'Continue - Step ' + project.setup.step, '', '')
                            ">
                            Continue
                        </v-btn>
                    </v-row>
                </v-stepper-content>

                <!-- -------------------- -->
                <!-- ------ Step 6 ------ -->
                <!-- -------------------- -->
                <v-stepper-content step="6">
                    <SetupSettings :project="project"></SetupSettings>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            class="mr-2"
                            @click="
                                $router.push('/content-projects')
                                trackEvent('CS - Setup', 'Cancel - Step ' + project.setup.step, '', '')
                            ">
                            Cancel
                        </v-btn>
                        <v-btn
                            :disabled="project.setup.finished"
                            text
                            class="mr-2"
                            @click="
                                e1--
                                trackEvent('CS - Setup', 'Back - Step ' + project.setup.step, '', '')
                            ">
                            Back
                        </v-btn>
                        <v-btn
                            :disabled="project.setup.finished"
                            color="primary"
                            @click="
                                updateProject(7)
                                trackEvent('CS - Setup', 'Finish - Step ' + project.setup.step, '', '')
                            ">
                            finish
                        </v-btn>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <AddUrlDialogVue
            :show="addURLDialog.show"
            :id="addURLDialog.id"
            :url="addURLDialog.url"
            @started="addedURLNotification()"
            @added="
                fetchProject(project._id)
                this.url_loading = false
            "
            @close="addURLDialog.show = false"
            :setMainURL="true"
            :addLighthouseData="true"></AddUrlDialogVue>
    </div>
</template>

<script>
    import CreateProject from "../../components/content-suite/setup/CreateProject.vue"
    import SelectTopics from "../../components/content-suite/setup/SelectTopics.vue"
    import SelectCompetitors from "../../components/content-suite/setup/SelectCompetitors.vue"
    import SelectTerms from "../../components/content-suite/setup/SelectTerms.vue"
    import SelectQuestions from "../../components/content-suite/setup/SelectQuestions.vue"
    import SetupSettings from "../../components/content-suite/setup/SetupSettings.vue"
    import AddUrlDialogVue from "../../components/content-suite/AddUrlDialog.vue"
    export default {
        components: {
            CreateProject,
            SelectTopics,
            SelectCompetitors,
            SelectTerms,
            SelectQuestions,
            SetupSettings,
            AddUrlDialogVue
        },
        data() {
            return {
                finishedFastMode: false,
                addURLDialog: {
                    id: null,
                    show: false,
                    url: ""
                },
                edit: false,
                colors: ["cyan darken-3", "purple darken-3", "lime darken-3", "light-green darken-3", "blue darken-3", "orange darken-3", "red darken-3"],
                statusNotification: { message: "" },
                addKeywordsLoading: false,
                addTopicsLoading: false,
                addCompetitorsLoading: false,
                notification: { show: false },
                url_loading: false,
                project: {
                    shared: true,
                    briefing_shared: true,
                    briefing_shared_extern: false,
                    editor_shared: true,
                    editor_shared_extern: false,
                    editable: true,
                    title: "",
                    url: "",
                    main_keyword: "",
                    topics: [],
                    langloc: { language_code: "de", location_code: "2276" },
                    competitors: [],
                    keywords: 0,
                    device: "mobile",
                    questions: [],
                    setup: {
                        finished: false,
                        step: 1,
                        loading: true,
                        fastmode: false,
                        depth: { depth: 10, label: "Top 10" }
                    },
                    settings: {
                        word_count: 0,
                        term_usage: 75,
                        readability: { id: 4, min: 50, max: 60, text: "normal", color: "amber darken-2" },
                        placement: 10
                    },
                    briefing_description: "",
                    authors: []
                },
                topKeywords: [],
                e1: 1,
                create_button_disabled: false,
                folder: null
            }
        },

        methods: {
            async updateQuestions(questions) {
                questions.map((question) => {
                    let index = this.project.questions.findIndex((x) => question.question == x.question)
                    if (index > -1) {
                        this.project.questions[index].selected = question.selected
                    } else {
                        this.project.questions.push(question)
                    }
                })
                const url = process.env.VUE_APP_APIURL + "/cs/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project
                }
                await this.$helpers.axiosPost(url, post_data)
                console.log("questions updated!!!!")
                // this.$emit("fetchQuestions")
            },
            retryProcess(step) {
                if (step == 1) {
                    this.addTopics()
                } else if (step == 2) {
                    this.addCompetitors()
                } else if (step == 3) {
                    this.addKeywords()
                }
            },
            trackEvent(component, action, param1, param2) {
                try {
                    this.$matomo.trackEvent(component, action, param1, param2)
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
            },
            /**
             * creates new project in database
             */
            async createProject() {
                try {
                    if (!this.project._id) {
                        if (!this.checkStep(1)) return
                        this.create_button_disabled = true
                        const url = process.env.VUE_APP_APIURL + "/cs/create"
                        this.project.owner = localStorage.username
                        this.project.setup.step = 2
                        let post_data = {
                            company_id: localStorage.company_id,
                            project: this.project,
                            folder: this.folder
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        if (response_data.data.error == "no_credits_left") {
                            this.notification = this.$helpers.createNotification("You dont have any credits left. Please contact the support via chat or email!", "mdi-alert-circle-outline", "#FCBA03")
                            this.create_button_disabled = false
                            this.project.setup.step = 1
                            return
                        }
                        if (!response_data.data.created) {
                            this.create_button_disabled = false
                            this.project.setup.step = 1
                            this.notification = this.$helpers.createNotification("A project with this name already exists, please provide another name!", "mdi-alert", "orange darken-2")
                            return
                        }

                        console.log(response_data)
                        this.project = response_data.data.resp

                        this.e1 = 2
                        this.trackEvent("CS - Setup", "Continue - Step " + this.project.setup.step, this.project.title, this.project.main_keyword)
                        this.changeLoading(this.e1)
                        console.log("NO TOPICS - ADD")
                        if (this.project.url) {
                            this.saveURL()
                        }
                        this.addTopics()
                        this.create_button_disabled = false
                        this.$router.replace({ query: { id: response_data.data.resp._id, step: this.e1 } })
                        return
                    }

                    if (this.edit) {
                        this.updateProject(4)
                    } else {
                        this.updateProject(2)
                    }
                } catch (error) {
                    console.log(error)
                    this.notification = this.$helpers.createNotification("There was an error. Please try again or contact the support!", "mdi-alert-circle-outline", "#FCBA03")
                    this.create_button_disabled = false
                    this.project.setup.step = 1
                }
            },
            /**
             * Updates Project Data
             * @param {*} nextstep
             */
            async updateProject(nextstep) {
                if (!this.checkStep(nextstep - 1)) return
                // Update Project

                this.project.setup.step = nextstep

                if (nextstep == 7) {
                    if (!this.project.setup.finished) {
                        this.project.setup.finished = true
                        this.project.competitors_status = "loading"
                        this.addLHData()
                    }
                }
                const url = process.env.VUE_APP_APIURL + "/cs/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project
                }
                await this.$helpers.axiosPost(url, post_data)

                // Check action for nextstep
                if (nextstep == 2 && !this.project.topics.length && !this.project.setup.loading) {
                    // this.changeLoading(nextstep)
                    // console.log("NO TOPICS - ADD")
                    // if (this.project.url) {
                    //     this.saveURL()
                    // }
                    // this.addTopics()
                } else if (nextstep == 3 && !this.project.setup.loading) {
                    this.changeLoading(nextstep)
                    console.log("NO COMPS - ADD")
                    this.addCompetitors()
                } else if (nextstep == 4 && !this.project.setup.loading && !this.edit) {
                    console.log(this.project.competitors)
                    let selected = this.project.competitors.filter((x) => x.selected)
                    if (!selected.length) {
                        this.notification = this.$helpers.createNotification("Please select at least one competitor!", "mdi-alert", "orange darken-2")
                        return
                    }
                    this.changeLoading(nextstep)
                    console.log("NO TopKeywords - ADD")
                    this.addKeywords()
                } else if (nextstep == 7) {
                    this.$router.push("/content-projects")
                }
                this.e1 = nextstep
            },
            async saveURL() {
                const url = process.env.VUE_APP_APIURL + "/cs/add/url"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.project._id,
                    url: this.project.url,
                    mainurl: true,
                    addLighthouseData: true,
                    user_id: localStorage.id
                }
                console.log(post_data)
                this.$helpers.axiosPost(url, post_data)
            },
            async changeLoading(nextstep) {
                if (nextstep == 2 || nextstep == 3 || nextstep == 4) {
                    this.project.setup.loading = true

                    const url = process.env.VUE_APP_APIURL + "/cs/update"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project: this.project
                    }
                    await this.$helpers.axiosPost(url, post_data)
                }
            },
            /**
             * Fetches Project Data by id
             * @param {*} id
             */
            async fetchProject(id, setInitialSelection = false) {
                console.log("fetch project")
                const url = process.env.VUE_APP_APIURL + "/cs/get/project"
                let post_data = {
                    company_id: localStorage.company_id,
                    id: id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.project = response_data.data.project
                console.log(this.project.setup)
                this.edit = this.project.setup.finished ? true : false
                if (this.project.setup.loading) {
                    if (this.project.setup.step == 2) {
                        this.addTopicsLoading = true
                    } else if (this.project.setup.step == 3) {
                        this.addCompetitorsLoading = true
                    } else if (this.project.setup.step == 4) {
                        this.addKeywordsLoading = true
                    }
                }
                if (setInitialSelection) {
                    this.project.competitors.map((c) => {
                        let avgpos = this.getavgpos(c.positions)
                        c.avgpos = avgpos
                        // c.selected = avgpos <= 20 ? true : false
                    })

                    this.project.competitors.sort(function (a, b) {
                        return a.avgpos - b.avgpos
                    })

                    this.project.competitors.map((c, i) => {
                        c.selected = i < this.project.setup.depth.depth ? true : false
                    })
                    console.log(this.project.competitors)
                    const url = process.env.VUE_APP_APIURL + "/cs/update"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project: this.project
                    }
                    await this.$helpers.axiosPost(url, post_data)
                }
                if (this.e1 == 2 && !this.project.topics.length) {
                    console.log("NO TOPICS - ADD")
                    // this.addTopics()

                    // if (this.project.url) {
                    //     this.saveURL()
                    // }
                }
            },
            /**
             * Fetches and adds topics to Project
             * based on main_keyword
             */
            getavgpos(positions) {
                let sum = 0
                if (positions.length) {
                    this.selectedTopics.map((t) => {
                        let e = positions.find((e) => e.keyword == t.keyword)
                        sum += e ? e.position : 100
                    })
                    let avgpos = sum / this.selectedTopics.length
                    return avgpos.toFixed(1)
                }
                return 100
            },
            async addTopics() {
                this.addTopicsLoading = true
                const url = process.env.VUE_APP_APIURL + "/cs/add/topics"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    project_id: this.project._id,
                    socket_id: this.$socket.id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
            },
            /**
             * Fetches and adds Comps to Project
             * based on selected topics
             */
            async addCompetitors() {
                this.addCompetitorsLoading = true
                const url = process.env.VUE_APP_APIURL + "/cs/add/competitors"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    project: this.project,
                    socket_id: this.$socket.id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)

                // this.project.competitors = response_data.data.competitors
                // this.fetchProject(this.project._id)
                // setTimeout(() => {
                //     this.addCompetitorsLoading = false
                // }, 1000)
                // console.log(response_data.data)
            },
            /**
             * extracts Comps Website Conten and identifies Keywords
             * saves Keywords to project
             */
            async addKeywords() {
                this.addKeywordsLoading = true
                const url = process.env.VUE_APP_APIURL + "/cs/add/keywords"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    project: this.project,
                    socket_id: this.$socket.id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
            },
            async addLHData() {
                const url = process.env.VUE_APP_APIURL + "/cs/add/lh-report"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project,
                    socket_id: this.$socket.id
                }
                await this.$helpers.axiosPost(url, post_data)
            },
            async updateKeywords(keywords) {
                const url = process.env.VUE_APP_APIURL + "/cs/update/keywords"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    keywords: keywords,
                    project_id: this.project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
            },
            /**
             * id exists if reload happens while in setup
             * @param {*} id
             */
            async fetchKeywords(id) {
                const url = process.env.VUE_APP_APIURL + "/cs/get/keywords"
                if (id) {
                    let post_data = {
                        company_id: localStorage.company_id,
                        user_id: localStorage.id,
                        project_id: id ? id : this.project._id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                    this.topKeywords = response_data.data.keywords
                } else {
                    console.log("no id")
                }
            },

            checkStep(step) {
                console.log("checkstep", step)
                if (step == 1) {
                    if (this.project.title.length < 3) {
                        this.notification = this.$helpers.createNotification("Please provide a project title!", "mdi-alert", "orange darken-2")
                        return false
                    }
                    if (!this.project.main_keyword || this.project.main_keyword.length < 2) {
                        this.notification = this.$helpers.createNotification("Please provide a main keyword!", "mdi-alert", "orange darken-2")
                        return false
                    }
                    if (this.project.url) {
                        try {
                            let url = new URL(this.project.url)
                        } catch (error) {
                            this.notification = this.$helpers.createNotification("Please enter a complete and accurate website URL! (including https://)", "mdi-alert", "orange darken-2")
                            return false
                        }
                    }
                    return true
                } else if (step == 2) {
                    if (this.selectedTopics.length < 1) {
                        this.notification = this.$helpers.createNotification("Please provide a at least one topic!", "mdi-alert", "orange darken-2")
                        return false
                    }
                    return true
                } else if (step == 4) {
                    if (this.selectedKeywords.length < 1) {
                        this.notification = this.$helpers.createNotification("Please select at least 1 Keyword!", "mdi-alert", "orange darken-2")
                        return false
                    }
                    return true
                } else if (step == 5) {
                    // if (this.selectedQuestions.length < 4) {
                    //     this.notification = this.$helpers.createNotification("Please select at least 4 questions!", "mdi-alert", "orange darken-2")
                    //     return false
                    // }
                    return true
                } else {
                    return true
                }
            },
            addedURLNotification() {
                this.addURLDialog.show = false
                this.url_loading = true
                this.notification = this.$helpers.createNotification("The URL has been added! All data will be fetched within the next seconds.", "mdi-check-circle-outline", "primary")
            }
        },
        computed: {
            selectedCompetitors() {
                return this.project.competitors.filter((comp) => comp.selected)
            },
            selectedKeywords() {
                return this.topKeywords.filter((keyword) => keyword.selected)
            },
            selectedTopics() {
                return this.project.topics.filter((topic) => topic.selected)
            },
            selectedQuestions() {
                return this.project.questions.filter((question) => question.selected)
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (!vm.$route.query.id) {
                    console.log("hiiii")
                    vm.$forceUpdate()
                    vm.project = {
                        shared: true,
                        briefing_shared: true,
                        briefing_shared_extern: false,
                        editor_shared: true,
                        editor_shared_extern: false,
                        editable: true,
                        title: "",
                        url: "",
                        main_keyword: "",
                        topics: [],
                        langloc: { language_code: "de", location_code: "2276" },
                        competitors: [],
                        keywords: 0,
                        device: "mobile",
                        questions: [],
                        setup: {
                            finished: false,
                            step: 1,
                            loading: true,
                            fastmode: false,
                            depth: { depth: 10, label: "Top 10" }
                        },
                        settings: {
                            word_count: 0,
                            term_usage: 75,
                            readability: { id: 4, min: 50, max: 60, text: "normal", color: "amber darken-2" },
                            placement: 10
                        },
                        briefing_description: ""
                    }
                    vm.e1 = 1
                }
            })
        },
        updated() {
            console.log("STEP:   ", this.e1)
        },
        mounted() {
            console.log(this.$socket.id)
            this.$router.onReady(() => {
                if (this.$route.query.id) {
                    console.log(this.$route.query.id)
                    this.fetchProject(this.$route.query.id)
                    this.fetchKeywords(this.$route.query.id)
                }
                if (this.$route.query.step) {
                    this.e1 = this.$route.query.step
                }
                if (this.$route.query.folder) {
                    // folder
                    this.folder = this.$route.query.folder
                    console.log("FOLDER FOUND")
                }
                console.log(this.e1)
            })
            this.$socket.on("getCSNotification", (data) => {
                this.statusNotification = data
                if (data.type == "cs_topics_creation_finished") {
                    console.log(this.project)
                    this.fetchProject(this.project ? this.project._id : this.$route.query.id)
                    this.trackEvent("CS - Setup", data.type, "", "")
                    setTimeout(() => {
                        this.addTopicsLoading = false
                    }, 1000)
                } else if (data.type == "cs_competitors_added") {
                    console.log("cs_competitors_added")
                    const setInitialSelection = true
                    this.fetchProject(this.project ? this.project._id : this.$route.query.id, setInitialSelection)
                    this.trackEvent("CS - Setup", data.type, "", "")
                    setTimeout(() => {
                        this.addCompetitorsLoading = false
                    }, 1000)
                } else if (data.type == "cs_creation_finished") {
                    if (data.data && data.data.finishedFastMode) {
                        this.finishedFastMode = true
                        this.$router.push("/content-editor/" + this.project._id)
                    }
                    this.fetchKeywords(this.project ? this.project._id : this.$route.query.id)
                    this.fetchProject(this.project ? this.project._id : this.$route.query.id)
                    this.trackEvent("CS - Setup", data.type, "", "")
                    this.addKeywordsLoading = false
                }
                //  else if (data.type == "error") {
                //     this.retry_button = true
                // }

                // sendNotification(socket_id, 100, "cs_creation_finished", "Finished")
            })
            this.$socket.on("csAddUrl", (data) => {
                console.log("ADD-")
                if (data.url_updated) {
                    this.fetchProject(this.project ? this.project._id : this.$route.query.id)
                    this.url_loading = false
                } else {
                    this.notification = this.$helpers.createNotification("Something went wrong adding your URL! Please try again or contact support.", "mdi-alert", "orange darken-2")
                    this.url_loading = false
                }
                this.trackEvent("CS - Setup", "url updated", data.url_updated, "")
            })
        }
    }
</script>

<style scoped>
    .title_area {
        display: block;
        padding: 20px;
        background-color: var(--v-primary-base);
        border-radius: 10px 0 0 10px;
        color: white;
        width: 25%;
    }
    .details_area {
        display: flex;
        padding: 20px;
        width: 75%;
    }
    .setup_progress_bar {
        width: 20%;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .setup_progress_bar_text {
        font-size: 9px;
        position: absolute;
        right: 0px;
        top: 10px;
        color: rgb(157, 157, 157);
        align-items: center;
        margin-left: auto;
    }
    .titlelbl {
        font-size: 10px;
    }
    .mkwlbl {
        font-size: 10px;
        margin-top: 15px;
    }
    .cs_p_title {
        font-size: 20px;
    }
    .cs_p_kw {
        font-size: 20px;
    }
    .setup {
        padding-top: 35px;
        padding-left: 100px;
        padding-right: 100px;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }
    .v-stepper__header {
        box-shadow: none !important;
    }
    .loaderwrapper {
        padding: 50px;
    }
    .loadersubtext {
        text-align: center;
    }
    .loaderimg {
        margin: auto;
    }
    #loader {
        margin: auto;
    }
</style>
