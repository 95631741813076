<template>
    <v-dialog v-model="dialog" max-width="500px" transition="slide-x-transition" @click:outside="$emit('close')">
        <v-card>
            <div class="backsheet"></div>
            <v-btn class="close-btn" absolute top right icon color="primary" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-text class="pt-5 pb-0">
                <div class="lbl pb-1">ENTER ADDITIONAL KEYWORDS</div>
                <v-text-field
                    @keyup.enter="active.process == 'setup' ? fetchTopics() : addTermplacement()"
                    class="my-2"
                    rounded
                    filled
                    v-model="topics"
                    hint="seperate new keywords with comma"
                    persistent-hint></v-text-field>
                <v-select v-if="active.process == 'briefing' && active.area == 'term_placement'" v-model="tag" :items="tags_formatted" chips></v-select>
            </v-card-text>
            <v-card-actions>
                <v-btn class="mx-auto" color="primary" @click="active.process == 'setup' ? fetchTopics() : addTermplacement()" rounded>ok</v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-if="notification" top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import datasources from "../helper/datasources.js"
    import locs from "../helper/locs"

    export default {
        props: {
            show: Boolean,
            active: Object,
            tags: undefined,
            project: Object
        },
        data() {
            return {
                tag: {},
                // selectedLocation: locs.stateItems[locs.stateItems.findIndex((x) => x.loc == "Germany")],
                // selectedLocation: localStorage.selectedLocation ? JSON.parse(localStorage.selectedLocation) : null,
                topics: "",
                notification: null
            }
        },
        computed: {
            dialog() {
                return this.show
            },
            tags_formatted() {
                let keys = Object.keys(this.tags)
                return keys
            }
        },
        methods: {
            trackEvent(component, action, param1, param2) {
                try {
                    this.$matomo.trackEvent(component, action, param1, param2)
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
            },
            async addTermplacement() {
                console.log("term added")

                try {
                    // const url = process.env.VUE_APP_APIURL + "/cs/briefing/term_placement/change"
                    // console.log(this.company_id)
                    // let post_data = {
                    //     company_id: this.company_id,
                    //     user_id: this.user_id,
                    //     project_id: this.project._id,
                    //     description: this.content
                    // }
                    // let response_data = await this.$helpers.axiosPost(url, post_data)
                    // this.notification = this.$helpers.createNotification("The description has been saved successfully!", "mdi-check-circle-outline", "primary")
                } catch (error) {
                    this.notification = this.$helpers.createNotification("Sorry, something went wrong! Please try again later!", "mdi-alert-circle-outline", "#fcba03")
                }
            },
            async fetchTopics() {
                try {
                    console.log("FETHCCHCHHCHC")
                    if (this.topics == "") return

                    let input = this.topics.split(",")
                    if (input.length > 20) {
                        this.btndisabled = false
                        this.loading = false
                        this.notification = this.$helpers.createNotification("Please do not enter more than 20 keywords!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                    //check keywords for length < 81
                    let keywordsInvalid = this.$helpers.checkKeywordsLength(input)
                    if (keywordsInvalid) {
                        this.btndisabled = false
                        this.loading = false
                        this.notification = this.$helpers.createNotification("One keyword cannot be longer than 80 characters!", "mdi-alert-circle-outline", "#FCBA03")
                        return
                    }
                    let inputErrors = this.$helpers.validateUserInput(input)
                    if (inputErrors.length) {
                        let lines = inputErrors.reduce((lineString, entry) => lineString + (parseInt(entry.keyword) + 1) + ",", "")
                        this.notification = this.$helpers.createNotification("Please validate your input. Watch Keywords: " + lines, "mdi-alert-circle-outline", "#FCBA03")

                        this.loading = false
                        this.btndisabled = false
                        return
                    }
                    input = this.$helpers.replaceSpecialCharacters(input)
                    input = input.filter((x) => x !== " " && x !== "")
                    this.$emit("started")

                    // let date = new Date()
                    // let year = date.getFullYear()
                    // year -= 1
                    // date.setFullYear(year)
                    // date = date.toISOString().split("T")[0]
                    let options = {
                        keywords: input,
                        location_code: this.project.langloc.location_code,
                        language_code: this.project.langloc.language_code
                        // date_from: date
                    }
                    console.log("FETCH")
                    let kw_response = await datasources.fetchDFS(options, localStorage.company_id, localStorage.id, "historic_searchvolume")
                    console.log(kw_response.result.items)

                    console.log("EMIT")
                    if (!kw_response.result) kw_response.result = {}
                    if (!kw_response.result.items) kw_response.result.items = []

                    input.map((x) => {
                        if (kw_response.result.items.findIndex((item) => item.keyword == x) == -1) {
                            let keyword_object = {
                                keyword_info: {
                                    monthly_searches: [],
                                    avgsv: 0
                                },
                                keyword: x,
                                selected: true
                            }
                            kw_response.result.items.push(keyword_object)
                        }
                    })
                    if (kw_response.result.items !== null && kw_response.result.items.length) {
                        this.$emit("added", kw_response.result.items)
                        this.trackEvent("CS - Add Topics Manually - success", this.topics, "", "")
                    } else {
                        this.$emit("added", [])
                        this.trackEvent("CS - Add Topics Manually - empty", this.topics, "", "")
                    }

                    this.topics = ""
                } catch (error) {
                    console.log(error)
                    this.$emit("failed")
                    this.topics = ""
                }
            }
        }
    }
</script>

<style scoped>
    .close-btn {
        top: 5px;
        right: 5px;
    }
</style>
