<template>
    <div>
        <!-- Info -->
        <v-snackbar top :color="info.color" v-model="info.show" :timeout="info_timeout">
            <v-icon>{{ info.icon }}</v-icon>
            <span class="font-weight-bold pl-2">{{ info.message }}</span>
        </v-snackbar>

        <v-dialog v-model="select_terms_dialog">
            <v-card>
                <v-card-title class="titlelbl uppercase">
                    Select Terms
                    <v-btn icon right absolute @click="select_terms_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle> All terms are generated through an analysis of your top competitors. </v-card-subtitle>
                <v-card-text>
                    <select-terms @updateKeywords="updateKeywords" :statusNotification="{ message: '' }" :loading="false" :project="project" :topKeywords="topKeywords"></select-terms>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="select_questions_dialog">
            <v-card>
                <v-card-title class="titlelbl uppercase">
                    Select Questions
                    <v-btn icon right absolute @click="select_questions_dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle> All questions are generated from competitors content and google search results! </v-card-subtitle>
                <v-card-text>
                    <SelectQuestions v-if="project" :questions="project.questions" @updateQuestions="updateQuestions"></SelectQuestions>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- {{ overlay }}
        <v-btn
            @click="
                overlay.active = true
                info = $helpers.createNotification('This Content Editor is opened by another user. The Content Editors can be used by one user at a time!', 'mdi-alert-circle-outline', '#FCBA03')
            "
            >overlay</v-btn
        > -->
        <v-overlay v-if="overlay.active">
            <div>
                <div style="display: flex; z-index: 9999">
                    <!-- Info -->
                    <v-snackbar top :color="info.color" v-model="info.show" :timeout="info_timeout">
                        <v-icon>{{ info.icon }}</v-icon>
                        <span class="font-weight-bold pl-2">{{ info.message }}</span>
                    </v-snackbar>

                    <!-- <span class="mx-auto">
                        <img id="loader" src="../../assets/quaro-q.svg" />
                    </span> -->
                </div>
            </div>
        </v-overlay>

        <div v-if="!project">
            <div style="display: flex">
                <span style="margin-top: 35vh" class="circularprogress mx-auto">
                    <img id="loader" src="../../assets/quaro-q.svg" />
                </span>
            </div>
        </div>
        <!-- --------- -->
        <!-- main area -->
        <!-- --------- -->
        <div class="main_area mr-5">
            <v-slide-x-transition>
                <div v-if="project" class="mb-7 mt-10">
                    <component-header
                        :active="{ url: 'content-projects', name: 'Content Project', destination: 'content-editor' }"
                        :title="project.title"
                        :id="project._id"
                        :device="project.device"
                        :language_code="project.langloc.language_code"
                        :share_data="share_data"
                        :shared_extern="shared_extern"
                        :project="project"
                        :shared_company_id="sharedCompanyId"></component-header>
                    <!-- <span class="mt-10" style="font-size: 12px; display: block">
                        <div class="pb-1">
                            <v-chip x-small color="" class="mr-2" style="text-transform: capitalize">
                                <v-icon x-small class="mr-1">{{ project.device == "mobile" ? "mdi-cellphone" : "mdi-monitor" }}</v-icon>
                                {{ project.device }}
                            </v-chip>
                            <v-chip x-small color="" class="mr-2" style="text-transform: uppercase">
                                <v-icon x-small class="mr-1">mdi-map-marker</v-icon>
                                {{ project.langloc.language_code }}
                            </v-chip>
                        </div></span
                    >
                    <v-row no-gutters style="font-size: 24px" class="mb-1"
                        >{{ project.title }}
                        <div class="flex" style="height: 100%; width: 100px">
                            <div class="ml-2">
                                <v-btn style="padding-right: 2px" color="primary" elevation="0" x-small class="mb-1" fab><v-icon small>mdi-share-variant</v-icon></v-btn>
                            </div>
                        </div>
                    </v-row> -->
                    <!-- <v-row>
                        <v-col cols="12" sm="12" md="6"> </v-col>
                    </v-row> -->
                    <!-- <navi v-if="!shared_extern" :id="project._id"></navi>
                    <div v-if="!shared_extern" class="mt-1 mb-5"> -->
                    <!-- <v-btn
                            @click="
                                shareMenu = true
                                shareItem = {
                                    _id: $route.params.project_id,
                                    listname: project.title
                                }
                            "
                            class=""
                            small
                            color="primary">
                            <v-icon small class="mr-1">mdi-share-variant</v-icon>
                            share
                        </v-btn> -->
                    <!-- <v-btn class="ml-3" small color="blue" outlined> <v-icon small class="mr-1">mdi-export</v-icon> export </v-btn> -->
                    <!-- <v-btn class="ml-3 white--text" small :color="'primary'" @click="saveEditor(false)"> <v-icon small class="mr-1">mdi-content-save</v-icon> save </v-btn> -->
                    <!-- <v-btn class="ml-3" small color="primary" @click="$router.push('/briefing/' + project._id)"> <v-icon small class="mr-1">mdi-open-in-new</v-icon> briefing</v-btn> -->
                    <!-- </div> -->
                </div>
            </v-slide-x-transition>
            <v-row>
                <v-col>
                    <v-slide-x-transition>
                        <v-card class="quaroshadow rounded_card" v-if="project">
                            <v-slide-y-reverse-transition>
                                <div class="backsheet" v-if="loadbacksheet"></div>
                            </v-slide-y-reverse-transition>
                            <v-row class="px-5">
                                <!-- <v-col>
                                    <div class="selectionCol">
                                        <div class="titlelbl">TITLE</div>
                                        <div class="cs_p_title">{{ project.title }}</div>
                                    </div>
                                </v-col>
                                <v-divider class="d-none d-xl-block" inset vertical> </v-divider>
                                <v-col>
                                    <div class="selectionCol">
                                        <div class="titlelbl">URL</div>
                                        <div class="cs_url" v-if="project.url">{{ project.url }}</div>
                                        <div class="cs_url" v-else>
                                            <v-btn small class="ml-2 mt-2" color="primary" outlined @click="addURLDialog.show = true">
                                                <span v-if="!addURLLoading">add url</span>
                                                <v-progress-circular class="mx-3" v-else indeterminate size="20" width="2" color="primary"></v-progress-circular>
                                            </v-btn>

                                            <AddUrlDialog
                                                :show="addURLDialog.show"
                                                :id="$route.params.project_id"
                                                @started="
                                                    addURLLoading = true
                                                    info = $helpers.createNotification(
                                                        'The URL has been added successfully! After a few minutes all data will be fetched!',
                                                        'mdi-check-circle-outline',
                                                        'primary'
                                                    )
                                                    addURLDialog.show = false
                                                "
                                                @added="
                                                    fetchProject($route.params.project_id)
                                                    addURLLoading = false
                                                "
                                                @close="addURLDialog.show = false"
                                                :setMainURL="true"
                                                :addLighthouseData="true"></AddUrlDialog>

                                        </div>
                                    </div>
                                </v-col>
                                <v-divider class="d-none d-xl-block" inset vertical> </v-divider>

                                <v-col>
                                    <div class="selectionCol">
                                        <div class="my-auto">
                                            <div class="titlelbl">COMPETITORS</div>
                                            <div class="keywordcount">{{ selectedCompetitors.length }} selected</div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-divider class="d-none d-xl-block" inset vertical> </v-divider> -->
                                <v-col>
                                    <div class="selectionCol">
                                        <div class="titlelbl">TOPICS</div>
                                        <div class="cs_topics" v-if="selectedTopics.length">
                                            <v-chip-group show-arrows>
                                                <v-chip class="white--text mr-2 mb-1" v-for="(topic, index) in selectedTopics" :key="index" color="primary">{{ topic.keyword }}</v-chip>
                                                <!-- <v-chip class="white--text mr-2 mb-1" v-for="(topic, index) in selectedTopics" :key="index" :color="colors[index]">{{ topic.keyword }}</v-chip> -->
                                            </v-chip-group>
                                        </div>
                                        <div class="cs_topics" v-else>{{ selectedTopics.length }} selected</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>
            </v-row>
            <v-row>
                <!-- --------- -->
                <!-- meta data -->
                <!-- --------- -->
                <v-col cols="12" md="6">
                    <v-slide-x-transition>
                        <v-expansion-panels v-model="metapanel" flat accordion elevation-1 class="px-0" v-if="project">
                            <v-expansion-panel class="quaroshadow rounded_card">
                                <v-slide-y-reverse-transition>
                                    <div class="backsheet" v-if="loadbacksheet"></div>
                                </v-slide-y-reverse-transition>

                                <v-expansion-panel-header class="py-1">
                                    <div class="titlelbl mb-1">
                                        META DATA
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span
                                                >Enter the title and description for your website. The maximum character length for each field is displayed. This information helps optimize the
                                                appearance and relevance of the website in search engine results pages (SERPs).</span
                                            >
                                        </v-tooltip>
                                    </div>

                                    <template v-slot:actions>
                                        <v-icon color="primary"> mdi-chevron-down </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <!-- ---------- -->
                                    <!-- meta title -->
                                    <!-- ---------- -->
                                    <v-row no-gutters class="pb-0 pt-0">
                                        <v-col class="pb-0 pt-0">
                                            <div class="scorelbl">TITLE</div>
                                            <v-textarea
                                                :readonly="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2 && !shared_extern"
                                                style="font-size: 14px"
                                                @input="setSERPtitle(true)"
                                                dense
                                                class=""
                                                rounded
                                                :row-height="12"
                                                auto-grow
                                                persistent-hint
                                                filled
                                                v-model="editorData.title"
                                                counter="70"></v-textarea>
                                            <!-- :hint="getPixelLength('documentTitle') + 'px / 580px'" -->
                                        </v-col>
                                    </v-row>
                                    <!-- ---------------- -->
                                    <!-- meta description -->
                                    <!-- ---------------- -->
                                    <v-row>
                                        <v-col class="pb-0 pt-0">
                                            <div class="scorelbl">DESCRIPTION</div>
                                            <v-textarea
                                                :readonly="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2 && !shared_extern"
                                                style="font-size: 14px"
                                                @input="setSERPdescription(true)"
                                                dense
                                                :row-height="12"
                                                auto-grow
                                                persistent-hint
                                                rounded
                                                filled
                                                v-model="editorData.description"
                                                counter="158"></v-textarea>
                                            <!-- :hint="getPixelLength('documentDescription') + 'px / 990px'" -->
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <v-btn
                                            small
                                            text
                                            color="purple darken-2"
                                            @click="
                                                showMetaDataGenerator = true
                                                metaDataGeneratorTopic = project.main_keyword + ''
                                            ">
                                            <v-icon size="16px" class="mr-1">mdi-creation-outline</v-icon>
                                            Generate with AI</v-btn
                                        >
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-slide-x-transition>
                </v-col>
                <!-- --------------- -->
                <!-- snippet preview -->
                <!-- --------------- -->
                <v-col cols="12" md="6">
                    <v-slide-x-transition>
                        <v-expansion-panels v-model="metapanel" flat accordion elevation-1 class="px-0" v-if="project" style="height: 100%">
                            <v-expansion-panel class="quaroshadow rounded_card">
                                <v-slide-y-reverse-transition>
                                    <div class="backsheet" v-if="loadbacksheet"></div>
                                </v-slide-y-reverse-transition>

                                <v-expansion-panel-header class="py-1">
                                    <div class="titlelbl mb-1">
                                        SNIPPET PREVIEW
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>The Snippet Preview shows you how your page could appear in search engine results based on the title and description you have entered.</span>
                                        </v-tooltip>
                                    </div>

                                    <template v-slot:actions>
                                        <v-icon color="primary"> mdi-chevron-down </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager>
                                    <div class="serp-preview">
                                        <div id="serp-title">
                                            <span id="documentTitle">{{ serp_preview.title }}</span>
                                        </div>
                                        <div id="serp-url">
                                            {{ serp_preview.url }}
                                            <v-icon size="20" class="pl-0 ml-0">mdi-triangle-small-down</v-icon>
                                        </div>
                                        <div id="serp-description">
                                            <span id="documentDescription">{{ serp_preview.description }}</span>
                                        </div>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-slide-x-transition>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-slide-x-transition>
                        <editor
                            v-if="project && editorData"
                            :new_content="new_content"
                            :content="editorData.content"
                            :comments="editorData.comments"
                            :editorVersion="editorData.version"
                            :pasteContent="pasteContent"
                            @setContent="setContent"
                            :project="project"
                            :keywords="selectedKeywords"
                            :keywordFrequency="keywordFrequency"
                            @processContent="processContent"
                            @updateComment="updateComment"
                            @deleteComment="deleteComment"
                            :editor_disabled="editor_disabled"
                            :shared_extern="shared_extern"></editor>
                        <!-- :scrollBottom="scrollBottom" -->
                    </v-slide-x-transition>
                </v-col>
            </v-row>
        </div>

        <!-- --------- -->
        <!-- right nav -->
        <!-- --------- -->
        <div class="right_area" v-if="project">
            <div class="area_content">
                <v-slide-x-transition>
                    <v-expansion-panels accordion flat class="px-0" v-model="score_expanded" v-if="project">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="px-7 py-1 white--text" color="primary">
                                <!-- --------------- -->
                                <!-- CONTENT QUALITY -->
                                <!-- --------------- -->
                                <div class="pr-2">CONTENT QUALITY</div>
                                <div class="pr-5 mt-2">
                                    <vue-ellipse-progress
                                        color="white"
                                        empty-color="transparent"
                                        :empty-color-fill="emptyColorFill"
                                        :thickness="5"
                                        :empty-thickness="3"
                                        lineMode="out 5"
                                        :legend="true"
                                        animation="rs 700 1000"
                                        fontSize="1.2rem"
                                        :size="80"
                                        :dot="{
                                            size: 10,
                                            width: '4px',
                                            backgroundColor: 'white',
                                            transitionDuration: '1000ms'
                                        }"
                                        :progress="getContentScore">
                                        <span slot="legend-caption" style="font-size: 11px"> SCORE </span>
                                    </vue-ellipse-progress>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="white"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="pb-4 pt-5">
                                    <div class="scorelbl pb-2 pb-2" style="display: flex" v-bind="(color = getColor(project.settings.word_count, editorWords.length))">
                                        WORD COUNT
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Shows you the current count of words in the editor and the number of words you need to write to fulfill the project's requirements.</span>
                                        </v-tooltip>
                                        <!-- <v-chip><v-icon color="red">mdi-close-cirlce</v-icon> 0/3125</v-chip> -->
                                        <span class="range mt-auto ml-auto pr-2">
                                            {{ editorWords.length }} / {{ Math.ceil(project.settings.word_count) }}
                                            <v-icon style="padding-bottom: 3px" small class="pl-1" :color="color.color">
                                                {{ editorWords.length >= project.settings.word_count ? "mdi-check-circle" : "mdi-arrow-up-circle" }}
                                            </v-icon>
                                        </span>
                                    </div>
                                    <v-progress-linear :value="(editorWords.length / project.settings.word_count) * 100" :color="color.color" rounded height="5px"></v-progress-linear>
                                </div>

                                <div class="pb-4 pt-5" v-bind="(score = getScore(project.settings.term_usage, keywordCoverage, true))">
                                    <div class="scorelbl pb-2 pb-2" style="display: flex" v-bind="(covcolor = getColor(project.settings.term_usage, keywordCoverage))">
                                        KEYWORD USAGE
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Keyword usage shows you what percentage of the keywords in your text have been used enough times.</span>
                                        </v-tooltip>
                                        <!-- <v-chip><v-icon color="green">mdi-check-cirlce</v-icon> 75%</v-chip> -->
                                        <span class="range mt-auto ml-auto pr-2">
                                            {{ keywordCoverage }}% / {{ project.settings.term_usage }}%
                                            <v-icon style="padding-bottom: 3px" small class="pl-1" :color="covcolor.color">
                                                {{ score.score == 1 ? "mdi-check-circle" : "mdi-arrow-up-circle" }}
                                            </v-icon>
                                        </span>
                                    </div>
                                    <v-progress-linear :value="score.score * 100" :color="covcolor.color" rounded height="5px"></v-progress-linear>
                                </div>
                                <div class="pb-4 pt-5">
                                    <div class="scorelbl pb-2 pb-2" style="display: flex" v-bind="(imagecolor = getColor(project.settings.img_count, editorImageCount))">
                                        IMAGES
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Shows you how many images you use in your text and how many you should use at least.</span>
                                        </v-tooltip>
                                        <!-- <v-chip><v-icon color="green">mdi-check-cirlce</v-icon> 75%</v-chip> -->
                                        <span class="range mt-auto ml-auto pr-2">
                                            {{ editorImageCount }}/{{ project.settings.img_count }}
                                            <v-icon style="padding-bottom: 3px" small class="pl-1" :color="imagecolor.color">
                                                {{ editorImageCount >= project.settings.img_count ? "mdi-check-circle" : "mdi-arrow-up-circle" }}
                                            </v-icon>
                                        </span>
                                    </div>
                                    <v-progress-linear
                                        :value="project.settings.img_count == 0 ? 100 : (editorImageCount / project.settings.img_count) * 100"
                                        :color="imagecolor.color"
                                        rounded
                                        height="5px"></v-progress-linear>
                                </div>
                                <div class="pb-4 pt-5">
                                    <div class="scorelbl pb-2 pb-2" style="display: flex">
                                        READABILITY
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="pl-1" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                            </template>
                                            <span>Shows you the readability of your content based on the Flash Reading Index.</span>
                                        </v-tooltip>
                                        <!-- <v-chip><v-icon color="red">mdi-close-cirlce</v-icon> 990/3125</v-chip> -->
                                        <span class="range mt-auto ml-auto pr-2">
                                            {{ editorReadability.text }}
                                            <v-icon style="padding-bottom: 3px" small class="pl-1" :color="editorReadability.color ? editorReadability.c.color : 'deep-orange darken-2'">
                                                {{ editorReadability.color ? (editorReadability.score < 1 ? "mdi-arrow-up-circle" : "mdi-check-circle") : "mdi-arrow-up-circle" }}
                                            </v-icon>
                                        </span>
                                    </div>

                                    <v-progress-linear
                                        v-if="editorReadability.color"
                                        :value="editorReadability.ri"
                                        :color="editorReadability.c.color"
                                        rounded
                                        height="5px"
                                        style="overflow: unset !important">
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <div id="myBar" v-on="on"></div>
                                                <div id="myBar2" v-on="on"></div>
                                            </template>
                                            <span>Target: {{ project.settings.readability.text }}</span>
                                        </v-tooltip></v-progress-linear
                                    >

                                    <v-progress-linear v-if="!editorReadability.color" color="red" disabled rounded value="0" height="5px" style="overflow: unset !important">
                                        <v-tooltip max-width="250" color="" top>
                                            <template v-slot:activator="{ on }">
                                                <div id="myBar" v-on="on"></div>
                                                <div id="myBar2" v-on="on"></div>
                                            </template>
                                            <span>Target: {{ project.settings.readability.text }}</span>
                                        </v-tooltip></v-progress-linear
                                    >
                                    <div class="lineal">
                                        <div id="readability_hard">difficult</div>
                                        <div id="readability_easy">easy</div>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-slide-x-transition>
                <v-divider></v-divider>
                <v-slide-y-transition>
                    <v-expansion-panels id="detailfields" accordion flat class="px-0" v-if="project && topKeywords">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-key-star </v-icon></div>
                                KEYWORDS
                                <div style="display: flex">
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Shows you which keywords you should use at least how often. Based on a live WDF*IDF analysis.</span>
                                    </v-tooltip>
                                    <!-- <v-btn @click.native.stop icon @click="select_terms_dialog = true">add</v-btn> -->
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="flex mt-5" no-gutters>
                                    <div class="my-auto" style="color: #797979; padding-right: 12.4444444444px">{{ selectedKeywords.length }} keywords</div>
                                    <v-divider vertical class="mx-0"></v-divider>
                                    <v-btn :disabled="$store.state.content_suite_group.id !== 1 || shared_extern" small color="primary" text @click.native.stop @click="select_terms_dialog = true">
                                        <v-icon small class="mr-1">mdi-pencil</v-icon>
                                        edit
                                    </v-btn>
                                    <v-divider vertical class="mx-0"></v-divider>
                                </v-row>
                                <v-simple-table dense id="editorkwtable" class="mt-2">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="keyword in selectedKeywords" :key="keyword.keyword" v-bind="(data = getCovAttr(keyword))">
                                                <td class="pl-0 py-2" style="font-size: 0.925rem">
                                                    <v-icon size="20" :color="data.editor_count >= data.targetCount ? 'green' : ''" style="margin-bottom: 0px" class="pr-1">mdi-check-circle</v-icon>
                                                    {{ keyword.keyword }}
                                                </td>
                                                <td class="px-0">
                                                    <div class="countareay">
                                                        <span class="keyword-count">{{ data.editor_count }}/{{ data.targetCount }}</span>
                                                    </div>
                                                    <v-progress-linear :width="100" :value="(data.editor_count / data.targetCount) * 100" color="green" rounded></v-progress-linear>
                                                    <!-- <div class="countareay">
                                                    <v-row>
                                                        <span class="keyword-count">0/{{ Math.ceil(keyword.avgKeywordFrequency) }}</span>
                                                    </v-row>
                                                    <v-row>
                                                        <v-progress-linear :value="75" color="green" rounded></v-progress-linear>
                                                    </v-row>
                                                </div> -->
                                                </td>
                                                <!-- <td class="px-0">
                                                    <v-btn icon @click="removeKeyword(keyword)"><v-icon class="removekwbtn">mdi-close</v-icon></v-btn>
                                                </td> -->
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-help-circle </v-icon></div>
                                QUESTIONS
                                <div style="display: flex">
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Shows you the questions found and selected for this project. Make sure to awnser some of these.</span>
                                    </v-tooltip>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="">
                                <v-row class="flex mt-5" no-gutters>
                                    <div class="my-auto" style="color: #797979; padding-right: 12.4444444444px">{{ selectedQuestions.length }} questions</div>
                                    <v-divider vertical class="mx-0"></v-divider>
                                    <v-btn :disabled="$store.state.content_suite_group.id !== 1 || shared_extern" small color="primary" text @click.native.stop @click="select_questions_dialog = true">
                                        <v-icon small class="mr-1">mdi-pencil</v-icon>
                                        edit
                                    </v-btn>
                                    <v-divider vertical class="mx-0"></v-divider>
                                </v-row>
                                <v-simple-table id="editorkwtable" class="mt-2">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="question in selectedQuestions" :key="question.question" v-bind="(data = $helpers.getQuestionWord(question))">
                                                <td class="px-0">
                                                    <div class="countareay">
                                                        <v-chip class="white--text" :color="data.color" small>{{ data.text }}</v-chip>
                                                    </div>
                                                </td>
                                                <td class="pl-3 py-2">
                                                    {{ question.question }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-divider></v-divider>

                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-select-place </v-icon></div>
                                HEADLINES
                                <div style="display: flex">
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Create new or check the given headings. </span>
                                    </v-tooltip>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="outlineexpansion">
                                <BriefingOutline
                                    :project="project"
                                    :shared_extern="shared_extern"
                                    editorview
                                    @pasteOutline="pasteOutline"
                                    @pushBriefing="$router.push('/briefing/' + project._id)"></BriefingOutline>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-divider></v-divider>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-text-box-outline </v-icon></div>
                                READABILITY
                                <div style="display: flex">
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Optimize the readability of your content. </span>
                                    </v-tooltip>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="mt-2 flex">
                                    <span class="uppercase mb-1 my-auto" style="font-size: 12px"> Content readability: </span>
                                    <v-chip color="cyan darken-2" small class="ml-auto my-auto white--text">{{ editorReadability.text }}</v-chip>
                                </div>
                                <div class="mt-2 flex">
                                    <span class="uppercase mb-1 my-auto" style="font-size: 12px"> Targeted readability: </span>
                                    <v-chip color="cyan darken-2" small class="ml-auto my-auto white--text">{{ project.settings.readability.text }}</v-chip>
                                </div>
                                <v-divider class="mt-4"></v-divider>

                                <div class="mt-2">
                                    <div class="uppercase">Tipps:</div>
                                    <div class="tipp" v-if="editorReadability.diff > 0">
                                        <v-icon class="mx-2" color="yellow">mdi-lightbulb-outline</v-icon>
                                        <span class="my-auto"> use shorter sentences</span>
                                    </div>
                                    <div class="tipp" v-if="editorReadability.diff > 0">
                                        <v-icon class="mx-2" color="yellow">mdi-lightbulb-outline</v-icon>
                                        <span class="my-auto">use words with less syllables</span>
                                    </div>
                                    <div class="tipp" v-if="editorReadability.diff < 0">
                                        <v-icon class="mx-2" color="success">mdi-check-circle-outline</v-icon>
                                        <span class="my-auto">no tipps.. all good</span>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-lightbulb-on </v-icon></div>
                                INSPIRATION
                                <div style="display: flex">
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Use your competitors content as an inspiration.</span>
                                    </v-tooltip>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table id="editorkwtable">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="comp in selectedCompetitors" :key="comp.url">
                                                <td class="pl-0">
                                                    {{ comp.title }}
                                                </td>
                                                <td class="px-0">
                                                    <v-btn color="primary" x-small icon :href="comp.url" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-text-box-outline </v-icon></div>
                                DESCRIPTION
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content id="briefing_description">
                                <div v-if="project.briefing_description" class="desciption_area" v-html="project.briefing_description"></div>

                                <div v-else class="flex">
                                    <v-btn
                                        :disabled="$store.state.content_suite_group.id !== 1 || shared_extern"
                                        class="ma-auto mt-3"
                                        small
                                        color="primary"
                                        @click="$router.push('/briefing/' + project._id)">
                                        <v-icon small class="mr-1">mdi-open-in-new</v-icon> add description</v-btn
                                    >
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- <v-expansion-panel disabled>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-comment-multiple </v-icon></div>
                                COMMENTS

                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </v-expansion-panel-content>
                        </v-expansion-panel> -->
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-select-place </v-icon></div>
                                TERM PLACEMENT
                                <div style="display: flex">
                                    <v-tooltip max-width="250" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-1 mr-auto" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>Shows you where your competitors are placing the keyword on their website. </span>
                                    </v-tooltip>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody>
                                            <tr v-for="(placement, index) in placementHints" :key="index">
                                                <td class="pl-0 py-2">
                                                    <v-icon size="20" class="pr-1" :color="checkPlacement(placement) ? 'green darken-1' : ''">mdi-check-circle</v-icon>
                                                    {{ placement.keyword }}
                                                </td>
                                                <td class="px-0">
                                                    <v-chip class="white--text" small :color="placementColors[placement.tag]">{{ placement.tag }}</v-chip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-divider></v-divider>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="">
                                <div class="headericon"><v-icon class="ma-auto">mdi-history </v-icon></div>
                                CHANGES

                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content> <EditorLog :project_id="shared_extern ? sharedId : $route.params.project_id" :new_data="new_data"></EditorLog> </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-slide-y-transition>
                <v-divider></v-divider>
            </div>
        </div>

        <MetaDataGenerator
            v-if="project"
            :show="showMetaDataGenerator"
            :topic="metaDataGeneratorTopic"
            :projectLanguage="project.langloc.language_code"
            @pasteContent="pasteMetaData"
            @close="
                showMetaDataGenerator = false
                metaDataGeneratorTopic = null
            "></MetaDataGenerator>
    </div>
</template>

<script>
    import Editor from "../../components/content-suite/editor/ContentEditor.vue"
    import ShareDialog from "../../components/dialogs/shareDialog/Sharedialog"
    // import AddUrlDialog from "@/components/content-suite/AddUrlDialog.vue"
    import EditorLog from "@/components/content-suite/EditorLog.vue"
    import BriefingOutline from "@/components/content-suite/briefing/BriefingOutline.vue"
    import ComponentHeader from "../../components/content-suite/ComponentHeader.vue"
    import SelectTerms from "../../components/content-suite/setup/SelectTerms.vue"
    import SelectQuestions from "../../components/content-suite/setup/SelectQuestions.vue"
    import MetaDataGenerator from "../../components/content-suite/editor/MetaDataGenerator.vue"
    // import _debounce from "lodash/debounce"
    import debounce from "lodash/debounce" // Beachten Sie, dass es 'debounce' und nicht '_debounce' ist
    export default {
        name: "cs-editor",
        components: {
            Editor,
            ShareDialog,
            // AddUrlDialog,
            EditorLog,
            ComponentHeader,
            BriefingOutline,
            SelectTerms,
            SelectQuestions,
            MetaDataGenerator
        },
        data() {
            return {
                showMetaDataGenerator: false,
                metaDataGeneratorTopic: null,
                icon_hover: false,
                select_terms_dialog: false,
                select_questions_dialog: false,
                addURLDialog: { show: false },
                addURLLoading: false,
                metapanel: 1,
                placementColors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                serp_preview: {
                    title: "No title to preview",
                    url: "https://no.url.to.preview/",
                    description: "No description to preview"
                },
                colors: ["cyan darken-3", "purple darken-3", "lime darken-3", "light-green darken-3", "blue darken-3", "orange darken-3", "red darken-3"],
                autosave: true,
                project: null,

                score_expanded: 0,
                emptyColorFill: {
                    radial: false,
                    colors: [
                        {
                            color: "#4fadc2",
                            offset: "0",
                            opacity: "0.3"
                        },
                        {
                            color: "#2bd6a5",
                            offset: "100",
                            opacity: "0.3"
                        }
                    ]
                },
                topKeywords: [],
                editorData: {
                    title: "No title to preview",
                    description: "No description to preview",
                    content: ""
                },
                editorImageCount: 0,
                editorSentences: [],
                editorWords: [],
                editorSyllCount: 0,
                loadbacksheet: false,
                textNodes: "",
                editorReadability: {
                    text: "",
                    color: "",
                    icon: "",
                    ri: 80
                },
                placementHints: [],
                fetched: 0,
                new_content: 0,

                // share
                sharedCompanyId: null,
                sharedId: null,
                sharedUserId: null,
                shared_extern: false,
                share_data: { briefing: false, editor: false, wdfidf: false, competitors: false },

                info: {
                    show: false,
                    message: null,
                    icon: null,
                    color: null
                },
                showInfo: false,
                info_timeout: 6000,
                sharedCompanyId: null,
                sharedId: null,
                shared_extern: false,
                user_left: false,
                overlay: { active: false, text: "" },
                data_fetched: false,
                editor_disabled: true,
                myInterval: 0,
                new_data: 0,
                // scrollBottom: 0,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                pasteContent: "",
                comments: ""
            }
        },
        methods: {
            async updateQuestions(questions) {
                questions.map((question) => {
                    let index = this.project.questions.findIndex((x) => question.question == x.question)
                    if (index > -1) {
                        this.project.questions[index].selected = question.selected
                    } else {
                        this.project.questions.push(question)
                    }
                })
                const url = process.env.VUE_APP_APIURL + "/cs/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project
                }
                await this.$helpers.axiosPost(url, post_data)
                console.log("questions updated!!!!")
            },
            async updateKeywords(keywords) {
                const url = process.env.VUE_APP_APIURL + "/cs/update/keywords"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    keywords: keywords,
                    project_id: this.project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
                keywords.map((keyword) => {
                    let index = this.topKeywords.findIndex((x) => keyword.keyword == x.keyword)
                    if (index > -1) {
                        keyword.covrate = this.getKWCov(keyword.keyword)
                        this.topKeywords[index] = keyword
                    }
                })
            },
            deleteComment(id) {
                let index = this.editorData.comments.findIndex((c) => c.id == id)
                if (index > -1) {
                    this.editorData.comments.splice(index, 1)
                    this.saveEditor(false)
                }
            },
            updateComment(commentThread) {
                console.log("commentThread - TopLevel", commentThread)
                if (!this.editorData.comments) this.editorData.comments = []
                const commentIndex = this.editorData.comments.findIndex((c) => c.id == commentThread.id)
                if (commentIndex == -1) {
                    this.editorData.comments.push(commentThread)
                    return
                }
                this.editorData.comments[commentIndex].comments = JSON.parse(JSON.stringify(commentThread.comments))
                this.saveEditor(false)
            },
            // pasteOutline(outline) {
            //     console.log("paste outline: ", outline)
            //     console.log("this.editorData.content: ", this.editorData.content)
            //     let htmlOutline = this.convertToHTML(outline)
            //     const contentToPaste = this.convertToStreamedHTML(htmlOutline)
            //     // this.streamContent(contentToPaste)
            //     this.editorData.content = htmlOutline + this.editorData.content
            // },
            // convertToStreamedHTML(html) {
            //     let streamedHTML = ""
            //     for (let i = 0; i < html.length; i++) {
            //         const char = html[i]
            //         streamedHTML += char
            //         if (char === "<" && html[i + 1] !== "/") {
            //             let endIndex = html.indexOf(">", i)
            //             endIndex = endIndex === -1 ? html.length - 1 : endIndex
            //             const tag = html.substring(i + 1, endIndex)
            //             const isSelfClosing = html[endIndex - 1] === "/"
            //             const delay = isSelfClosing ? 500 : 1000 // Zeitverzögerung anpassen
            //             streamedHTML += `<span id="stream_${i}"></span>`
            //             setTimeout(() => {
            //                 const streamElement = document.getElementById(`stream_${i}`)
            //                 if (streamElement) {
            //                     streamElement.outerHTML = `<${tag}>`
            //                 }
            //             }, delay)
            //         }
            //     }
            //     return streamedHTML
            // },
            // streamContent(content) {
            //     this.editorData.content += content
            // },
            // convertToHTML(data) {
            //     let html = ""

            //     data.forEach((item) => {
            //         const { tag, text, children } = item

            //         if (tag && text) {
            //             html += `<${tag}>${text}</${tag}>`
            //         }

            //         if (children && children.length > 0) {
            //             html += this.convertToHTML(children)
            //         }
            //     })

            //     return html
            // },
            async pasteOutline(outline) {
                // console.log("paste outline: ", outline)
                // console.log("this.editorData.content: ", this.editorData.content)
                // this.scrollBottom++
                const htmlOutline = this.convertToHTML(outline)
                this.pasteContent = htmlOutline
                // setTimeout(async () => {
                //     await this.streamContent(htmlOutline)
                //     this.scrollBottom++
                // }, 1200)
            },

            // async streamContent(content) {
            //     const element = document.createElement("div")
            //     element.innerHTML = content
            //     const tags = element.children

            //     for (let i = 0; i < tags.length; i++) {
            //         const tag = tags[i]
            //         this.editorData.content += tag.outerHTML + " </br> "
            //         this.scrollBottom++
            //         await this.delay(50) // Zeitverzögerung anpassen
            //     }
            // },
            // delay(ms) {
            //     return new Promise((resolve) => setTimeout(resolve, ms))
            // },
            convertToHTML(data) {
                let html = ""
                data.forEach((item) => {
                    const { tag, text, children } = item
                    if (tag && text) {
                        html += `<${tag}>${text}</${tag}>`
                    }
                    if (children && children.length > 0) {
                        html += this.convertToHTML(children)
                    }
                })
                return html
            },
            // async removeKeyword(keyword) {
            //     keyword.selected = false
            //     const url = process.env.VUE_APP_APIURL + "/cs/update/keywords"
            //     let post_data = {
            //         company_id: localStorage.company_id,
            //         user_id: localStorage.id,
            //         keywords: [keyword],
            //         project_id: this.project._id
            //     }
            //     let response_data = await this.$helpers.axiosPost(url, post_data)
            // //     console.log(response_data.data)
            // },
            // async removeQuestion(questions) {
            //     questions.map((question) => {
            //         let index = this.project.questions.findIndex((x) => question.question == x.question)
            //         if (index > -1) {
            //             this.project.questions[index].selected = false
            //         }
            //         // else {
            //         //     this.project.questions.push(question)
            //         // }
            //     })
            //     const url = process.env.VUE_APP_APIURL + "/cs/update"
            //     let post_data = {
            //         company_id: localStorage.company_id,
            //         project: this.project
            //     }
            //     await this.$helpers.axiosPost(url, post_data)
            // //     console.log("questions updated!!!!")
            //     // this.$emit("fetchQuestions")
            // },
            fetchCompanyStatus() {
                return new Promise(async (resolve, reject) => {
                    this.$http.post(process.env.VUE_APP_APIURL + "/company/status", { company_id: this.sharedCompanyId }, { headers: { Authorization: this.auth } }).then(function (response) {
                        // // console.log(response.body)

                        if (response.body[0].status !== "test_ended" || response.body[0].status !== "inactive") {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    })
                })
            },
            createInfo(message, color, icon) {
                // console.log("createinfo")
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.info.show = true
            },
            /**
             * Fetches Project Data by id
             */
            fetchProject() {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/project"
                    let post_data = {
                        company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                        id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    // console.log(response_data)
                    this.project = response_data.data.project

                    if (this.project.url) {
                        this.serp_preview.url = this.project.url
                    }
                    // console.log("description", this.project.briefing_description)
                    this.fetched++
                    setTimeout(() => {
                        this.loadbacksheet = true
                    }, 600)
                    this.share_data = {
                        briefing: response_data.data.project.briefing_shared_extern,
                        editor: response_data.data.project.editor_shared_extern,
                        wdfidf: response_data.data.project.wdfidf_shared_extern,
                        competitors: response_data.data.project.competitors_shared_extern
                    }

                    resolve(response_data.data.project.editor_shared_extern)
                })
            },
            /**
             * id exists if reload happens while in setup
             * @param {*} id
             */
            async fetchKeywords() {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/keywords"
                    if (this.$route.params.project_id) {
                        let post_data = {
                            company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                            user_id: localStorage.id,
                            project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        this.topKeywords = response_data.data.keywords
                        this.getPlacementHints()
                        this.fetched++
                        resolve(true)
                    } else {
                        // console.log("no id")
                        resolve(true)
                    }
                })
            },
            /**
             * fetches editor data of project
             */
            async fetchEditor() {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/editor"
                    if (this.$route.params.project_id) {
                        this.data_fetched = true

                        let post_data = {
                            company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                            user_id: localStorage.id,
                            project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        // if (response_data.data.editorData && response_data.data.editorData._id) {
                        this.editor_id = response_data.data.editorData.project_id
                        // console.log("setting data")
                        this.editorData = response_data.data.editorData
                        if (this.editorData.content.length == 0) {
                            this.editorData.content += " " //TIPTAP needs content to initilize
                        }
                        console.log("this.editorData.content", this.editorData.content.length)
                        this.wdfIdfAnalysis(response_data.data.editorData.content, true)
                        this.setSERPdescription()
                        this.setSERPtitle()
                        // }

                        // // console.log(response_data.data.editorData)
                        this.fetched++
                        this.new_content++
                        this.editor_disabled = false
                        resolve(true)
                    } else {
                        // console.log("no id")
                    }
                })
            },
            /**
             * saves editor data of project
             */
            async saveEditor(closing) {
                let url = process.env.VUE_APP_APIURL + "/cs/update/editor"
                let post_data = {
                    company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                    project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id,
                    projectEditorData: { word_count: this.editorWords.length, content_score: this.getContentScore },
                    editorObject: {
                        content: this.editorData.content,
                        comments: this.editorData.comments,
                        // description: this.editorData.description,
                        // title: this.editorData.title,
                        user_id: closing ? "" : this.shared_extern ? this.sharedUserId : localStorage.id,
                        user_name: closing ? "" : this.shared_extern ? "Extern user" : localStorage.username,
                        last_updated: closing ? new Date(Date.now() - 15000) : new Date()
                    }
                }

                if (this.editorData.content !== undefined) {
                    try {
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        console.log("Editor content saved!")
                        this.new_data++
                    } catch (error) {
                        console.log(error)
                        this.info_timeout = 6000
                        this.info = this.$helpers.createNotification("There was an error saving your data!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                }
            },
            /**
             * saves serp snippet of project
             */
            saveSerpSnippet: debounce(async function () {
                let url = process.env.VUE_APP_APIURL + "/cs/update/serpsnippet"
                let post_data = {
                    company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                    project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id,
                    editorObject: {
                        description: this.editorData.description,
                        title: this.editorData.title
                    }
                }
                try {
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                } catch (error) {
                    console.log(error)
                    this.info_timeout = 6000
                    this.info = this.$helpers.createNotification("There was an error saving your data!", "mdi-alert-circle-outline", "#FCBA03")
                }
            }, 500),
            /**
             * open editor data of project
             */
            async openEditor(closing = false) {
                let url = process.env.VUE_APP_APIURL + "/cs/open/editor"

                let post_data = {
                    company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                    project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id,
                    user_id: closing ? "" : this.shared_extern ? this.sharedUserId : localStorage.id,
                    // user_name: closing ? "" : this.shared_extern ? "Extern user" : localStorage.username,
                    last_updated: closing ? new Date(Date.now() - 15000) : new Date()
                }
                try {
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                } catch (error) {
                    console.log(error)
                    this.info_timeout = 6000
                    this.info = this.$helpers.createNotification("There was an error saving your data!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            getPlacementHints() {
                let treshold = this.project.settings.placement
                let sorted = this.selectedKeywords
                    .filter((kw) => kw.termplacementValue > 0)
                    .sort(function (a, b) {
                        return b.termplacementValue - a.termplacementValue
                    })
                sorted.map((kwEntry) => {
                    kwEntry.termplacement.map((placement) => {
                        let data = { keyword: kwEntry.keyword, ...placement }
                        if ((placement.count / this.selectedCompetitors.length) * 100 > treshold) {
                            let index = this.placementHints.findIndex((entry) => entry.keyword == data.keyword)
                            if (index == -1) {
                                this.placementHints.push(data)
                            }
                        }
                    })
                })
                this.placementHints = this.placementHints.sort(function (a, b) {
                    return a.tag.substring(1) - b.tag.substring(1)
                })
            },
            getPixelLength(id) {
                if (id == "documentTitle" && this.serp_preview.title == "No title to preview") {
                    return 0
                } else if (id == "documentDescription" && this.serp_preview.description == "No description to preview") {
                    return 0
                }
                return document.getElementById(id) ? document.getElementById(id).offsetWidth : 0
            },
            checkPlacement(placement) {
                if (placement.tag == "title") {
                    return this.checkText(this.editorData.title, placement.keyword)
                } else if (placement.tag == "description") {
                    return this.checkText(this.editorData.description, placement.keyword)
                }

                let foundPlacements = []
                var el = document.createElement("html")
                el.innerHTML = this.editorData.content
                let elements = el.getElementsByTagName(placement.tag)
                if (elements) {
                    for (let index = 0; index < elements.length; index++) {
                        if (this.checkText(elements[index].innerText, placement.keyword)) {
                            foundPlacements.push(elements[index])
                        }
                    }
                }
                return foundPlacements.length ? true : false
            },
            checkText(text, checkString) {
                text = text.toLowerCase().replace("-", " ")
                checkString = checkString.toLowerCase().replace("-", " ")
                return text.includes(checkString) ? true : false
            },
            setSERPtitle(save = false) {
                if (this.editorData.title.length > 70) {
                    this.serp_preview.title = this.editorData.title.substring(0, 70) + " ..."
                } else if (this.editorData.title.length > 0) {
                    this.serp_preview.title = this.editorData.title + ""
                }
                if (save) this.saveSerpSnippet()
            },
            setSERPdescription(save = false) {
                if (this.editorData.description.length > 158) {
                    this.serp_preview.description = this.editorData.description.substring(0, 158) + " ..."
                } else if (this.editorData.description.length > 0) {
                    this.serp_preview.description = this.editorData.description + ""
                }
                if (save) this.saveSerpSnippet()
            },
            // getWordCount() {
            //     let textOnly = this.editorData.content.match(/(?<=\>).+?(?=\<)/g)
            //     let words = []
            //     textOnly.map((block) => {
            //         let wArray = block.replace(/\.|\_|\?|\!|\,|\\|\:/g, "").split(" ")
            //         words = [...words, ...wArray]
            //     })
            //     words = words.filter((word) => word.length > 1 || word.toLowerCase() == "i")
            //     return { value: words.length }
            // },
            setContent(content) {
                this.editorData.content = content
            },
            async wdfIdfAnalysis(doc, forced = false) {
                try {
                    // console.log("WDFIDF", this.fetched)
                    if (this.fetched % 4 == 0 || this.fetched > 4 || forced) {
                        // // console.log(doc)
                        const url = process.env.VUE_APP_APIURL + "/cs/wdfidf"
                        let post_data = {
                            doc: doc,
                            word_count: this.editorWords.length
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)

                        this.getTargetedWordCount(response_data.data.data, this.project.settings.word_count)

                        //TODO: add targetedWorcount
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async segment() {
                return new Promise(async (resolve, reject) => {
                    try {
                        // // console.log(doc)
                        const url = process.env.VUE_APP_APIURL + "/cs/segment"
                        let post_data = {
                            textNodes: this.textNodes
                        }
                        let response_data = await this.$helpers.axiosPost(url, post_data)
                        resolve(response_data.data)
                    } catch (error) {
                        // console.log(error)
                        reject(error)
                    }
                })
            },
            pasteMetaData(metaData) {
                this.editorData.description = metaData.description
                this.editorData.title = metaData.title
                this.setSERPtitle()
                this.setSERPdescription()
                this.showMetaDataGenerator = false
                this.saveSerpSnippet()
            },
            getTargetedWordCount(wdf_data, word_count) {
                // // console.log("WDF_DATA:", wdf_data)
                word_count = parseInt(word_count)
                // n = 10^(WDF * log(N+1)) - 1
                // n = 10^(0,403 * log(4577+1)) - 1
                //TODO: use avgwdf of this.selectedKeywords
                this.selectedKeywords.map((keyword) => {
                    const nDefaultContentLength = Math.pow(Math.E, keyword.avgWDF * Math.log(word_count + 1)) // Ziel Anzahl bei durchscn. WDF Wert / avg content length comps
                    const nRealContentLength = Math.pow(Math.E, keyword.avgWDF * Math.log(this.editorWords.length + 1)) // Ziel Anzahl bei durchscn. WDF Wert / tatsächliche länge des editor contents
                    // const test = Math.pow(Math.E, keyword.avgWDF * Math.log(1000 + 1))
                    keyword.targetedWDF = [
                        { wordCount: word_count, wordDocumentFreq: Math.ceil(nDefaultContentLength), type: "default_length", avg_wdf: keyword.avgWDF },
                        { wordCount: this.editorWords.length, wordDocumentFreq: Math.ceil(nRealContentLength), type: "editor_length", avg_wdf: keyword.avgWDF }
                    ]
                    let editorwdfentry = wdf_data.find((entry) => entry.keyword == keyword.keyword)
                    keyword.editor_wdf = editorwdfentry ? editorwdfentry.wdf : null
                })
                // wdf_data.map((keyword_wdf_data) => {
                //     const n = Math.pow(Math.E, keyword_wdf_data.wdf.wdf * Math.log(word_count + 1))
                //     keyword_wdf_data.targetedKeywordCount = Math.ceil(n)
                // })

                // console.log("wdfDataSET")

                // console.log(this.selectedKeywords)
                this.topKeywords.push({})
                this.topKeywords.pop()
                // this.processContent(null, true, false)
            },
            processContent(newContent) {
                // console.log(delta, force)
                // let update = [" ", ".", "!", "?", "\n"]
                // if (wdf) {
                //     this.wdfIdfAnalysis(this.editorData.content)
                // }
                // if (!this.editorData.content.length) {
                //     force = true
                // }
                // if (force || !delta.ops[1] || update.includes(delta.ops[1].insert) || delta.ops[1].delete) {
                //     console.log("inside processContent")

                //     this.textNodes = ""
                //     var el = document.createElement("html")
                //     el.innerHTML = this.editorData.content
                //     let body = el.getElementsByTagName("body")
                //     this.pushTextNode(body[0])
                //     let images = this.getImages()
                //     this.editorImageCount = images.length
                //     // this.saveEditor(false)
                // }
                // console.log("DELTA", delta)
                // this.editorData.content = newContent
                this.processContentBounce()
                // if (delta) {
                //     // this.saveEditor(false)
                // }
            },
            processContentBounce: debounce(function () {
                this.textNodes = ""
                var el = document.createElement("html")
                el.innerHTML = this.editorData.content
                let body = el.getElementsByTagName("body")
                this.pushTextNode(body[0])
                let images = this.getImages()
                this.editorImageCount = images.length
                setTimeout(async () => {
                    await this.wdfIdfAnalysis(this.editorData.content)
                    this.saveEditor(false)
                }, 200)
            }, 500),
            getImages() {
                var el = document.createElement("html")
                el.innerHTML = this.editorData.content
                return el.getElementsByTagName("img")
            },

            pushTextNode(node) {
                if (node.nodeName === "#text") {
                    const nodeVal = node.nodeValue.trim()
                    if (nodeVal) {
                        if (node.parentNode.localName === "li" && node.nextSibling === null) {
                            this.textNodes += " " + nodeVal + "."
                        } else {
                            this.textNodes += " " + nodeVal
                        }
                    }
                    return
                }
                node.childNodes.forEach((childNode) => {
                    this.pushTextNode(childNode)
                })
            },
            getReadability(content) {
                // ASL. Die durchschnittliche Satzlänge (Average Sentence Length) ergibt sich, indem die Anzahl der Wörter im Text durch die Anzahl der Sätze des Textes dividiert wird.
                // ASW. Die durchschnittliche Silbenanzahl pro Wort (Average Number of Syllables per Word) ergibt sich, indem die Silbenanzahl des gesamten Textes durch die Anzahl der Wörter im Text dividiert wird.
                const ranges = [
                    { id: 1, min: 0, max: 20, text: "very difficult", color: "red darken-4" },
                    { id: 2, min: 20, max: 40, text: "difficult", color: "red darken-2" },
                    { id: 3, min: 40, max: 50, text: "demanding", color: "orange darken-2" },
                    { id: 4, min: 50, max: 60, text: "normal", color: "amber darken-2" },
                    { id: 5, min: 60, max: 70, text: "simple", color: "yellow darken-2" },
                    { id: 6, min: 70, max: 80, text: "easy", color: "green darken-1" },
                    { id: 7, min: 80, max: 90, text: "very easy", color: "light-green darken-2" },
                    { id: 7, min: 90, max: 100, text: "very easy", color: "light-green darken-2" }
                ]

                //TODO: real ranges wieder einbauen und überschriften als vollständige sätze erkennen
                // const ranges = [
                //     { id: 1, min: 0, max: 30, text: "very difficult", color: "red darken-4" },
                //     { id: 2, min: 30, max: 50, text: "difficult", color: "red darken-2" },
                //     { id: 3, min: 50, max: 60, text: "demanding", color: "orange darken-2" },
                //     { id: 4, min: 60, max: 70, text: "normal", color: "amber darken-2" },
                //     { id: 5, min: 70, max: 80, text: "simple", color: "yellow darken-2" },
                //     { id: 6, min: 80, max: 90, text: "easy", color: "green darken-1" },
                //     { id: 7, min: 90, max: 100, text: "very easy", color: "light-green darken-2" },
                //     { id: 7, min: 100, max: 1000, text: "very easy", color: "light-green darken-2" }
                // ]

                // Readability index = 180 - ASL - ASW x 58,5
                if (this.editorWords.length > 3) {
                    let asl = this.editorWords.length / this.editorSentences.length
                    let syllCount = this.getSyllablesCount(this.editorWords)
                    let asw = syllCount / this.editorWords.length // Die durchschnittliche Silbenanzahl pro Wort
                    let ri = 180 - asl - asw * 58.5
                    let range = ranges.find((entry) => ri >= entry.min && ri < entry.max)
                    // console.log("RANGE -----------------------", range)
                    // console.log("READABILITY -----------------------", ri)
                    // console.log(".settings.readability -----------------------", this.project.settings.readability)

                    let settingsReadability = ranges.find((r) => r.text == this.project.settings.readability.text)
                    let diff = settingsReadability.min - ri
                    if (range) {
                        if (diff <= 0) {
                            range.color = "green darken-1"
                        } else if (diff >= 20) {
                            range.color = "orange darken-2"
                        } else if (diff >= 10) {
                            range.color = "yellow darken-2"
                        } else {
                            range.color = "green darken-1"
                        }
                        let score = this.getScore(settingsReadability.min, ri)
                        let c = this.getColor(settingsReadability.min, ri)
                        // console.log(c)

                        this.editorReadability = { ...range, ri: ri, diff: diff, score: score, c: c }
                        // console.log("editorReadability -----------------------", this.editorReadability)
                    }
                    // target readability
                } else {
                    this.editorReadability = {}
                }
            },
            getSyllablesCount(words) {
                // [bcdfghjklmnpqrstvwxz]*[äöüaeiouy]+[bcdfghjklmnpqrstvwxz]*
                let sylls = this.textNodes.match(/[bcdfghjklmnpqrstvwxz]*[äöüaeiouy]+[bcdfghjklmnpqrstvwxz]*/gi)
                return sylls ? sylls.length : 0
            },
            getCovAttr(keyword) {
                // wenn im editor mehr wörter sind als minimum angesezt wurden, werden die eigentliche länge des contens als basis für die zielanzahl der keywords
                let data = { targetCount: 0, editor_count: 0 }
                data.editor_count = keyword.editor_wdf ? keyword.editor_wdf.wordDocumentFreq : 0
                if (this.editorWords.length > this.project.settings.word_count) {
                    data.targetCount = keyword.targetedWDF && keyword.targetedWDF[1] ? keyword.targetedWDF[1].wordDocumentFreq : 0
                } else {
                    data.targetCount = keyword.targetedWDF && keyword.targetedWDF[0] ? keyword.targetedWDF[0].wordDocumentFreq : 0
                }
                return data
            },
            getKWCov(keyword) {
                //TODO: REwork
                let regex = new RegExp(keyword, "gi")
                try {
                    let data = this.editorData.content.match(regex)
                    return data ? data.length : 0
                } catch (error) {
                    return 0
                }
            },
            getScore(targetValue, value, returnObject = false) {
                if (targetValue == 0) return returnObject ? { score: 1 } : 1

                value = value / targetValue
                targetValue = 1
                if (value >= targetValue) {
                    return returnObject ? { score: 1 } : 1
                } else {
                    return returnObject ? { score: value } : value
                }
            },

            getColor(targetValue, value) {
                if (targetValue == 0) return { color: "green darken-1" }
                value = (value / targetValue) * 100
                targetValue = 100

                if (value >= targetValue) {
                    return { color: "green darken-1" }
                } else if (value - targetValue > -10) {
                    return { color: "yellow darken-1" }
                } else if (value - targetValue > -30) {
                    return { color: "yellow darken-2" }
                } else if (value - targetValue > -40) {
                    return { color: "orange darken-2" }
                } else {
                    return { color: "deep-orange darken-2" }
                }
            },
            blockEditor() {
                this.myInterval = setInterval(async () => {
                    if (this.$route.params.project_id == undefined || this.editorData.content == undefined) {
                        window.clearInterval(this.myInterval)
                        window.removeEventListener("blur", this.userLeaving)
                        window.removeEventListener("focus", this.userTabActiveAgain)
                    } else {
                        if (document.hasFocus()) {
                            this.openEditor()
                        }
                    }
                }, 10000)
            },
            autoSave() {
                // window.addEventListener("popstate", this.userLeaving, false)
                // this.myInterval = setInterval(async () => {
                //     if (this.$route.params.project_id == undefined || this.editorData.content == undefined) {
                //         window.clearInterval(this.myInterval)
                //         window.removeEventListener("blur", this.userLeaving)
                //         window.removeEventListener("focus", this.userTabActiveAgain)
                //     } else {
                //         // console.log("Still RUNNING!!!")
                //         // check if tab has focus
                //         // console.log(document.hasFocus())
                //         if (document.hasFocus()) {
                //             if (this.autosave) {
                //                 console.log("Save")
                //                 if (this.data_fetched) await this.saveEditor(false)
                //             }
                //         }
                //     }
                //     // else {
                //     //     this.autosave = false
                //     // }
                // }, 10000)
            },
            userLeaving() {
                // if (this.data_fetched) this.saveEditor(false)
                window.clearInterval(this.myInterval)
                console.log("LEAVING")
                this.autosave = false
                this.openEditor(false)
                // this.overlay.active = true
                this.overlay.text = "Tab inactive!"
                // this.inactive_dialog = true
                this.editor_disabled = true

                this.user_left = true
                this.data_fetched = false
                // this.editorData.content = undefined

                window.removeEventListener("blur", this.userLeaving)
                // window.removeEventListener("popstate", this.userLeaving, false)
            },
            async userTabActiveAgain() {
                // user tab active again, check if editor is available & fetch new version
                console.log("active again")

                // SHOW OVERLAY
                // this.overlay.active = true
                // this.inactive_dialog = false
                // this.refresh_dialog = true
                this.overlay.text = "Refreshing data. Please stand by!"
                this.overlay.refreshing = true
                this.data_fetched = false

                /**
                 * fetches editor data of project
                 */
                const url = process.env.VUE_APP_APIURL + "/cs/get/editor/entrycheck"

                let post_data = {
                    company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                    project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                let user_id = this.shared_extern ? this.sharedUserId : localStorage.id
                console.log(response_data.data.editorData)
                console.log("this.shared_extern", this.shared_extern)
                console.log("editor user id", response_data.data.editorData.user_id)
                console.log("entering user id", user_id)
                if (response_data.data.editorData.user_id == user_id) {
                    // editor is opened by same user -> next
                    window.addEventListener("blur", this.userLeaving)
                    this.openEditor()

                    await this.fetchProject()
                    await this.fetchKeywords()
                    await this.fetchEditor()

                    this.blockEditor()
                    this.user_left = false
                    this.autosave = true
                    this.info.show = false
                    // this.refresh_dialog = false
                    this.overlay.active = false
                    this.overlay.refreshing = false
                } else {
                    var now = new Date()

                    if (Math.floor(now - new Date(response_data.data.editorData.last_updated)) / 1000 > 15) {
                        // editor was not updated since 15 seconds - editor available
                        window.addEventListener("blur", this.userLeaving)
                        this.openEditor()

                        await this.fetchProject()
                        await this.fetchKeywords()
                        await this.fetchEditor()

                        this.blockEditor()
                        this.info.show = false
                        this.user_left = false
                        this.autosave = true
                        // this.refresh_dialog = false
                        this.overlay.active = false
                        this.overlay.refreshing = false
                    } else {
                        // editor was updated since in the last 15 seconds - editor unavailable
                        this.overlay.active = true
                        // this.overlay.text = "This Content Editor is opened by another user!"
                        this.info_timeout = 60000
                        this.info = this.$helpers.createNotification(
                            "This Content Editor is opened by another user. The Content Editors can be used by one user at a time!",
                            "mdi-alert-circle-outline",
                            "#FCBA03"
                        )
                    }
                }
            },
            checkForEntry() {
                return new Promise(async (resolve, reject) => {
                    /**
                     * fetches editor data of project
                     */
                    let url = process.env.VUE_APP_APIURL + "/cs/get/editor/entrycheck"
                    let user_id = this.shared_extern ? this.sharedUserId : localStorage.id

                    let post_data = {
                        company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                        project_id: this.shared_extern ? this.sharedId : this.$route.params.project_id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    // if (response_data.data.editorData && response_data.data.editorData._id) {
                    // console.log(response_data.data)

                    var now = new Date()

                    if (!response_data.data.editorData.user_id || response_data.data.editorData.user_id == "" || response_data.data.editorData.user_id == user_id) {
                        resolve(true)
                    } else {
                        if (Math.floor(now - new Date(response_data.data.editorData.last_updated)) / 1000 > 15) {
                            resolve(true)
                        } else {
                            this.info_timeout = 60000
                            this.overlay.active = true
                            this.info = this.$helpers.createNotification(
                                "This Content Editor is opened by another user. The Content Editors can be used by one user at a time!",
                                "mdi-alert-circle-outline",
                                "#FCBA03"
                            )
                            resolve(false)
                        }
                    }
                    // } else {
                    //     console.log("no editor data yet -> first opening")
                    //     resolve(true)
                    // }
                })
            }
        },
        watch: {
            textNodes: {
                async handler(val) {
                    let editorSentences = []
                    let editorWords = []

                    editorSentences = await this.segment()

                    editorSentences.map((sentences) => {
                        let wArray = sentences
                            .replace(/\.|\_|\?|\!|\,|\\|\:/g, "")
                            .split(" ")
                            .filter((entry) => entry != "")
                        editorWords = [...editorWords, ...wArray]
                    })
                    this.editorSentences = editorSentences
                    this.editorWords = editorWords
                    this.getReadability()

                    this.fetched++
                    this.data_fetched = true
                },
                deep: true
            },

            fetched(val) {
                if (val && val % 4 == 0) {
                    // console.log("process content --- WATCHER")
                    // console.log(this.project.settings.readability)
                    // console.log(this.editorReadability)
                    if (this.project.settings.readability.text == "normal" && this.project.settings.readability.min == 60) {
                        document.getElementById("myBar").style.left = 50 + "%" // <=====
                        document.getElementById("myBar2").style.left = 60 + "%" // <=====
                    } else {
                        document.getElementById("myBar").style.left = this.project.settings.readability.min + "%" // <=====
                        document.getElementById("myBar2").style.left = this.project.settings.readability.max + "%" // <=====
                    }
                    // console.log("ALLL FETCHED ->>>> PROCESS CONTENT")
                    // this.processContent(null, true)
                }
            }
        },
        computed: {
            keywordFrequency() {
                // console.log(this.selectedKeywords)
                // let frequency = this.selectedKeywords.map((x) => {
                //     let new_obj = { keyword: x.keyword, frequency: this.getCovAttr(x.keyword) }
                //     return new_obj
                // })
                // console.log("FREEEEEQUEEEEENz")
                // console.log(frequency)
                return null
            },
            getContentScore() {
                if (this.project) {
                    let wordSC = this.getScore(this.project.settings.word_count, this.editorWords.length)
                    let readSC = this.editorReadability.score ? this.editorReadability.score : 0
                    // if (this.editorReadability && this.editorReadability.diff) {
                    //     if (this.editorReadability.ri <= this.project.settings.readability.max && this.editorReadability.ri > this.project.settings.readability.min) {
                    //         readSC = 1
                    //     } else {
                    //         readSC = 0.5 - Math.abs(this.editorReadability.diff) / 100
                    //     }
                    // } else if (this.editorReadability && this.editorReadability.diff === 0) {
                    //     readSC = 1
                    // }

                    let kwSC = this.getScore(this.project.settings.term_usage, this.keywordCoverage)
                    let imageSC = this.getScore(this.project.settings.img_count, this.editorImageCount)
                    // let termSC =
                    // console.log("wc:", wordSC)
                    // console.log("rc:", readSC, this.editorReadability)
                    // console.log("kc:", kwSC)
                    // console.log("ic:", imageSC)
                    let score = 0.1 * readSC + 0.4 * wordSC + 0.4 * kwSC + 0.1 * imageSC
                    // this.project.c
                    // console.log("score", score * 100)
                    return parseInt((score * 100).toFixed(2))
                }
            },
            selectedCompetitors() {
                return this.project.competitors.filter((comp) => comp.selected)
            },
            selectedKeywords() {
                //TODO: add wdf
                return this.topKeywords
                    .filter((keyword) => keyword.selected)
                    .map((entry) => {
                        entry.covrate = this.getKWCov(entry.keyword)
                        return entry
                    })
            },
            selectedTopics() {
                return this.project.topics.filter((topic) => topic.selected)
            },
            selectedQuestions() {
                return this.project.questions.filter((question) => question.selected)
            },
            keywordCoverage() {
                // let count = 0
                // console.log("keywordCoverage", this.selectedKeywords)
                const kwArray = [...this.selectedKeywords]
                let count = kwArray.filter((keyword) => {
                    let data = this.getCovAttr(keyword)
                    return data.editor_count >= data.targetCount ? true : false
                }).length
                return Math.ceil((count / kwArray.length) * 100) || 0
            }
            // handleMouseLeave(event) {
            //     const isMouseOutsideViewport = event.clientX < 0 || event.clientY < 0 || event.clientX >= window.innerWidth || event.clientY >= window.innerHeight

            //     if (isMouseOutsideViewport) {
            //         // Hier kannst du die Funktion ausführen oder eine Bestätigungsnachricht anzeigen
            //         // Führe hier deine gewünschte Aktion aus
            //     }
            // }
        },
        beforeRouteEnter(to, from, next) {
            next(async (vm) => {
                console.log("BLUBV")

                if (vm.$route.params.share_id) {
                    let encrypted = ""
                    try {
                        encrypted = await vm.CryptoJS.AES.decrypt(vm.$route.params.share_id.replace(new RegExp("Por21Ld", "g"), "/"), process.env.VUE_APP_ENCRYPTION_PW).toString(vm.CryptoJS.enc.Utf8)
                    } catch (error) {
                        console.log(error)
                        vm.info_timeout = 60000
                        vm.info = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = false
                        return
                    }

                    let encryptedSplits = encrypted.split("|")
                    vm.sharedCompanyId = encryptedSplits[0]
                    vm.sharedId = encryptedSplits[1]
                    if (!vm.sharedId) {
                        vm.info_timeout = 60000
                        vm.info = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = false
                        return
                    }
                    vm.shared_extern = true

                    let informations = await Promise.all([vm.fetchProject(), vm.fetchCompanyStatus()])
                    let shared = informations[0]
                    let companyactive = informations[1]
                    if (shared && companyactive) {
                        if (localStorage.sharedUserId && localStorage.sharedUserId.includes("extern_")) {
                            vm.sharedUserId = localStorage.sharedUserId
                        } else {
                            vm.sharedUserId = "extern_" + Math.random().toString(36).slice(2)
                            localStorage.sharedUserId = vm.sharedUserId
                        }
                        let entrycheck = await vm.checkForEntry()
                        console.log("ENTRYSCHECK___________: " + entrycheck)
                        if (entrycheck === false) {
                            vm.loading = false
                            vm.overlay.active = true
                            vm.overlay.text = "This Content Editor is opened by another user!"
                            vm.info_timeout = 60000
                            vm.info = vm.$helpers.createNotification(
                                "This Content Editor is opened by another user. The Content Editors can be used by one user at a time!",
                                "mdi-alert-circle-outline",
                                "#FCBA03"
                            )
                            return
                        }

                        vm.info.show = false
                        window.addEventListener("blur", vm.userLeaving)
                        vm.openEditor()

                        await vm.fetchProject()
                        await vm.fetchKeywords()
                        await vm.fetchEditor()
                        vm.blockEditor()

                        // vm.checkFocus()
                    } else {
                        vm.shared_extern = false
                        vm.info_timeout = 60000
                        vm.info = vm.$helpers.createNotification("The collection is not shared.", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = false
                    }
                } else {
                    if (!vm.$store.state.content_suite) {
                        vm.$router.push("/")
                    } else {
                        let entrycheck = await vm.checkForEntry()
                        console.log("ENTRYSCHECK___________normalEnter: " + entrycheck)

                        if (entrycheck === false) {
                            vm.loading = false
                            vm.overlay.active = true
                            vm.info_timeout = 60000
                            vm.overlay.text = "This Content Editor is opened by another user!"

                            vm.info = vm.$helpers.createNotification(
                                "This Content Editor is opened by another user. The Content Editors can be used by one user at a time!",
                                "mdi-alert-circle-outline",
                                "#FCBA03"
                            )
                            console.log(vm.overlay)
                            // next() // Navigation abbrechen, wenn entrycheck === false
                            // return
                        } else {
                            window.addEventListener("blur", vm.userLeaving)
                            vm.openEditor()
                            vm.info.show = false
                            await vm.fetchProject()
                            await vm.fetchKeywords()
                            await vm.fetchEditor()
                            vm.blockEditor()
                        }
                        // vm.checkFocus()
                    }
                }
            })
        },
        async beforeRouteLeave(to, from, next) {
            // if (this.data_fetched) await this.saveEditor(true)
            window.clearInterval(this.myInterval)
            if (this.overlay.active === false) {
                this.openEditor(true)
            }
            window.removeEventListener("blur", this.userLeaving)
            window.removeEventListener("focus", this.userTabActiveAgain)
            // document.removeEventListener("mouseleave", this.handleMouseLeave)
            // window.removeEventListener("popstate", this.userLeaving, false)
            this.data_fetched = false
            this.editor_disabled = true
            this.editorData.content = undefined

            next()
        },
        mounted() {
            // this.fetchProject()
            // this.fetchKeywords()
            // this.fetchEditor()
            // this.autoSave()
            // document.addEventListener("mouseleave", async (event) => {
            //     if (event.clientY <= 0 || event.clientX <= 0 || event.clientX >= window.innerWidth || event.clientY >= window.innerHeight) {
            //         console.log("I'm out")
            //         if (this.data_fetched) await this.saveEditor(true)
            //     }
            // })
            window.addEventListener("focus", this.userTabActiveAgain)
        },

        created() {
            // Debounce-Methode erstellen, die nach einer Verzögerung die API-Anfrage auslöst
            // this.debouncedSaveContentToAPI = debounce(this.saveEditor(), 1000) // Hier 1000 Millisekunden (1 Sekunde) als Beispiel
        },
        destroyed() {
            // document.removeEventListener("mousemove", this.handleMouseLeave)
            window.removeEventListener("focus", this.userTabActiveAgain)
        }
    }
</script>
<style scoped></style>
<style lang="scss">
    #myBar,
    #myBar2 {
        border-radius: 2px;
        background-color: #b9b9b9;
        width: 2px;
        height: 16px;
        position: absolute;
        right: 100%;
        bottom: -5px;
    }
    .lineal {
        padding-top: 5px;
    }
    #readability_hard {
        font-size: xx-small;
        position: absolute;
    }
    #readability_easy {
        font-size: xx-small;
        position: absolute;
        left: 87%;
        // margin-right: 10px;
    }
    tr:hover .removekwbtn {
        display: flex !important;
    }
    .removekwbtn {
        display: none;
    }
    .right_area {
        height: 100vh;
        top: 64px;
        transform: translateX(0%);
        width: 350px;
        max-height: calc(100% - 64px);
        position: fixed;
        left: auto;
        right: 0;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        overflow: hidden;
        background: white;
        /* border-left: 2px solid var(--v-primary-base); */
    }
    .theme--dark .right_area {
        background: #1e1e1e;
    }
    .area_content {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        color: #3c3c3cde;
    }
    .main_area {
        padding-right: 350px;
        padding-left: 70px !important;
    }
    @media only screen and (max-width: 1163px) {
        .main_area {
            padding-left: 20px !important;
        }
    }
    @media only screen and (max-width: 768px) {
        .right_area {
            display: none !important;
        }
        .main_area {
            padding-right: 0px;
        }
    }
    .range {
        font-size: 12px;
    }
    .scorelbl {
        color: rgb(114, 114, 114);
        font-size: 14px;
    }
    .titlelbl {
        color: rgb(114, 114, 114);
    }
    .cs_p_title {
        font-size: 19px;
    }
    #serp-title {
        color: rgb(26, 13, 171);
        cursor: pointer;
        font-family: arial, sans-serif;
        font-size: 18px;
        font-weight: normal;
        line-height: 21.6px;
        text-align: left;
        text-decoration: none;
        visibility: visible;
        // white-space: nowrap;
    }

    #serp-url {
        color: rgb(0, 102, 33);
        font-family: arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        text-align: left;
        visibility: visible;
        // white-space: nowrap;
    }

    #serp-description {
        color: rgb(84, 84, 84);
        font-family: arial, sans-serif;
        font-size: 13px;
        font-weight: normal;
        line-height: 18.2px;
        text-align: left;
        visibility: visible;
        word-wrap: break-word;
    }
    .countareay {
        font-size: 12px;
        color: rgb(114, 114, 114);
        display: flex;
    }
    .keyword-count {
        margin-bottom: auto;
        margin-left: auto;
    }
    #editorkwtable table > tbody > tr > td:nth-child(2) {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
        /* background: var(--v-keywordcol-base) !important; */
    }

    .backsheet {
        border-radius: inherit;
        width: 100%;
        background-color: var(--v-primary-base);
        position: absolute;
        top: -4px;
        height: 20px;
        z-index: -1;
    }
    #detailfields .v-expansion-panel-header {
        margin: 7px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        border-radius: 10px;
        max-width: 95%;
    }
    .theme--light #detailfields .v-expansion-panel-header {
        background-color: #f6f6f6;
    }
    .theme--dark #detailfields .v-expansion-panel-header {
        background-color: #000000;
    }
    #detailfields .headericon {
        max-width: 62px;
        display: flex;
    }
    .desciption_area {
        // border: 1px solid grey;
        border-radius: 10px;
        padding: 5px 20px;
        background-color: #f6f6f6;
    }
    #briefing_description > div {
        padding-left: 7px;
        padding-right: 7px;
    }
    .tippheading {
        font-weight: bold;
    }
    .tipp {
        width: 100%;
        border: 1px solid grey;
        border-radius: 10px;
        display: flex;
        padding: 10px 5px;
        font-size: 13px;
        margin-top: 5px;
    }
    #outlineexpansion .v-expansion-panel-content__wrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>
