<template>
    <div>
        <v-row class="mt-2">
            <v-col xl="10" md="12" sm="12" class="ml-5">
                <div class="">
                    <div class="">
                        <h2 style="font-weight: 300" class="pb-2">Briefing Settings</h2>
                        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas laudantium ullam ipsam incidunt tempora officiis illum explicabo praesentium porro nulla voluptatibus</p> -->
                    </div>
                    <div class="pa-5 pt-0">
                        <v-checkbox label="use default settings" v-model="useDefault"></v-checkbox>
                        <v-row class="qgreycard">
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED LENGTH OF CONTENT
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >This field allows you to specify the desired length of the content for your project. The default value is based on the average length of content produced
                                            by your competitors. It is important to consider the attention span of your target audience, the complexity of the topic, and the desired level of detail
                                            when choosing the length of your content.
                                        </span>
                                    </v-tooltip>
                                </div>
                                <!-- <div class="lbl pb-2 pb-2" style="text-transform: uppercase">targeted length of content</div> -->

                                <v-text-field
                                    :disabled="useDefault"
                                    @focus="showTipp('title')"
                                    rounded
                                    filled
                                    type="num"
                                    v-model="project.settings.word_count"
                                    :hint="'avg. competitors content length: ' + Math.ceil(project.avgWordCount)"
                                    persistent-hint></v-text-field>
                            </v-col>
                            <!-- <v-col class="my-auto">
                                <v-card color="primary" class="rounded_card">
                                    <v-card-text class="pa-0">
                                        <v-row>
                                            <v-col cols="2">
                                                <div class="tippicon">
                                                    <v-icon color="white" class="ma-auto">mdi-information-outline</v-icon>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div class="tipp pr-2">
                                                    <p class="ma-auto white--text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus doloribus fugiat</p>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col> -->
                        </v-row>
                        <v-row class="qgreycard">
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED TERM USAGE
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >Allows you to specify the desired frequency and distribution of keywords and phrases in your content. For example: 75% of your selected content phrases
                                            must be used at least as often as your competitors do on average.
                                        </span>
                                    </v-tooltip>
                                </div>
                                <v-select
                                    :disabled="useDefault"
                                    hint="Default: 75%"
                                    persistent-hint
                                    :items="termusages"
                                    v-model="project.settings.term_usage"
                                    rounded
                                    filled
                                    item-text="text"
                                    item-value="value"></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="qgreycard">
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED READABILITY
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >Allows you to specify the desired level of readability for your content based on the Flesh Reading Ease Index. The index measures how easy or difficult it
                                            is to read a particular piece of content. It is important to create content that is easy to read and understand, as this can increase user engagement and
                                            sharing.</span
                                        >
                                    </v-tooltip>
                                </div>
                                <v-select
                                    :disabled="useDefault"
                                    hint="Default: normal"
                                    persistent-hint
                                    :items="readabilities"
                                    v-model="project.settings.readability"
                                    rounded
                                    filled
                                    item-text="text"
                                    return-object></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="qgreycard">
                            <v-col>
                                <div class="lbl pb-2 pb-2">
                                    TARGETED IMAGE USAGE
                                    <v-tooltip max-width="450" color="" top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span
                                            >Allows you to specify the desired frequency of images in your content. The default value is based on the average image usage of your competitors. Including
                                            relevant and high-quality images can enhance the visual appeal of your content and increase user engagement.
                                        </span>
                                    </v-tooltip>
                                </div>
                                <v-text-field
                                    :disabled="useDefault"
                                    :hint="'avg. competitors Image Count: ' + project.avgImgCount"
                                    persistent-hint
                                    v-model="project.settings.img_count"
                                    rounded
                                    type="number"
                                    filled></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: {
            project: Object
        },
        data() {
            return {
                // readabilities: [
                //     { id: 1, min: 0, max: 30, text: "very difficult", color: "red darken-4" },
                //     { id: 2, min: 30, max: 50, text: "difficult", color: "red darken-2" },
                //     { id: 3, min: 50, max: 60, text: "demanding", color: "orange darken-2" },
                //     { id: 4, min: 60, max: 70, text: "normal", color: "amber darken-2" },
                //     { id: 5, min: 70, max: 80, text: "simple", color: "yellow darken-2" },
                //     { id: 6, min: 80, max: 90, text: "easy", color: "green darken-1" },
                //     { id: 7, min: 90, max: 100, text: "very easy", color: "light-green darken-2" }
                // ],
                readabilities: [
                    { id: 1, min: 0, max: 20, text: "very difficult", color: "red darken-4" },
                    { id: 2, min: 20, max: 40, text: "difficult", color: "red darken-2" },
                    { id: 3, min: 40, max: 50, text: "demanding", color: "orange darken-2" },
                    { id: 4, min: 50, max: 60, text: "normal", color: "amber darken-2" },
                    { id: 5, min: 60, max: 70, text: "simple", color: "yellow darken-2" },
                    { id: 6, min: 70, max: 80, text: "easy", color: "green darken-1" },
                    { id: 7, min: 80, max: 90, text: "very easy", color: "light-green darken-2" }
                ],
                termusages: [
                    { text: "10%", value: 10 },
                    { text: "15%", value: 10 },
                    { text: "20%", value: 10 },
                    { text: "25%", value: 25 },
                    { text: "30%", value: 30 },
                    { text: "35%", value: 35 },
                    { text: "40%", value: 40 },
                    { text: "45%", value: 45 },
                    { text: "50%", value: 50 },
                    { text: "55%", value: 55 },
                    { text: "60%", value: 60 },
                    { text: "65%", value: 65 },
                    { text: "70%", value: 70 },
                    { text: "75%", value: 75 },
                    { text: "80%", value: 80 },
                    { text: "85%", value: 85 },
                    { text: "90%", value: 90 },
                    { text: "95%", value: 95 },
                    { text: "100%", value: 100 }
                ],
                useDefault: true
            }
        },
        methods: {
            showTipp(tippID) {
                if (!this.visibleTipp) {
                    this.visibleTipp = this.tipps.find((tipp) => tipp.id == tippID)
                } else {
                    this.visibleTipp = null
                    setTimeout(() => {
                        this.visibleTipp = this.tipps.find((tipp) => tipp.id == tippID)
                    }, 500)
                }
            }
        },
        mounted() {
            if (this.project.settings.word_count == 0) {
                this.project.settings.word_count = this.project.avgWordCount
            }
        }
    }
</script>

<style>
    .infocard {
        height: 100%;
        background-color: rgb(199, 199, 199);
        border-radius: 15px;
    }
    .lbl {
        font-size: 12px !important;
        font-weight: 600;
    }
    .formarea {
        border-radius: 10px;
        padding: 5px 20px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        background: linear-gradient(
            90deg,
            var(--v-background_gradient_1-base) 0%,
            var(--v-background_gradient_2-base) 26%,
            var(--v-background_gradient_3-base) 50%,
            var(--v-background_gradient_4-base) 75%,
            var(--v-background_gradient_5-base) 100%
        );
        /* background: #fafbfa; */
    }
    .tippicon {
        display: flex;
        height: 100%;
    }
    .tipp {
        display: flex;
        height: 100%;
    }
</style>
