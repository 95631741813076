<template>
    <div class="navi-wrapper flex">
        <v-card class="quaroshadow rounded_card">
            <v-btn-toggle mandatory divided dense v-model="navi" color="primary" group>
                <v-btn v-if="!shared_extern || (shared_extern && share_data.briefing)" small @click="redirect('briefing')" value="briefing">
                    <v-icon small start class="mr-2"> mdi-application-outline </v-icon>
                    <span class="hidden-sm-and-down">Briefing</span>
                </v-btn>

                <v-btn v-if="!shared_extern || (shared_extern && share_data.editor)" small @click="redirect('content-editor')" value="content-editor">
                    <v-icon small start class="mr-2"> mdi-application-edit-outline </v-icon>
                    <span class="hidden-sm-and-down">Editor</span>
                </v-btn>

                <v-btn v-if="!shared_extern || (shared_extern && share_data.competitors)" small @click="redirect('content-project/competitors')" value="content-project/competitors">
                    <v-icon small start class="mr-2"> mdi-view-grid-compact </v-icon>
                    <span class="hidden-sm-and-down">Competitors</span>
                </v-btn>

                <v-btn v-if="!shared_extern || (shared_extern && share_data.wdfidf)" small @click="redirect('content-project/wdf-idf')" value="content-project/wdf-idf">
                    <v-icon small start class="mr-2"> mdi-chart-box-outline </v-icon>
                    <span class="hidden-sm-and-down">WDF*IDF</span>
                </v-btn>
            </v-btn-toggle>
        </v-card>
    </div>
</template>

<script>
    export default {
        props: {
            id: String,
            active: Object,
            shared_extern: Boolean,
            share_data: Object,
            shared_company_id: String
        },
        data() {
            return {
                navi: ""
            }
        },
        methods: {
            redirect(value) {
                console.log(value)
                console.log(this.navi)
                if (value == this.navi) return
                console.log(this.shared_extern)
                if (this.shared_extern) {
                    let encrypted = this.CryptoJS.AES.encrypt(this.shared_company_id + "|" + this.id, process.env.VUE_APP_ENCRYPTION_PW).toString()
                    let replaced = encrypted.replace(new RegExp("/", "g"), "Por21Ld")

                    this.$router.push("/" + value + "/" + this.id + "/" + replaced)
                } else {
                    this.$router.push("/" + value + "/" + this.id)
                }
            }
        },
        watch: {
            // navi(val) {
            //     this.$router.push("/" + val + "/" + this.id)
            // },
            active(val) {
                this.navi = val.destination
            },
            shared_extern(val) {
                // this.shared_extern_template = val
            }
        },
        mounted() {
            this.navi = this.active.destination + ""
        }
    }
</script>

<style>
    .navi-wrapper {
        width: auto;
    }
</style>
