<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- downloadalert  -->
        <v-slide-y-transition>
            <v-snackbar width="300px" vertical top v-model="referenceFetchNotificiation" :timeout="2000000" color="primary">
                <div class="pb-0">
                    <div class="download-label">
                        <span class="mx-auto">Fetching of reference URL has been blocked!</span>
                    </div>
                    <div>
                        <span class="mx-auto">Enhancing bypass mechanisms....</span>
                    </div>
                    <v-progress-linear style="width: 310px" label="downloading" color="white" indeterminate rounded height="6"></v-progress-linear>
                </div>
            </v-snackbar>
        </v-slide-y-transition>
        <v-slide-x-transition>
            <div v-if="showsearchbar">
                <v-row>
                    <v-col md="12" sm="12" x-sm="12">
                        <v-card class="rounded_card quaroshadow">
                            <div class="backsheet"></div>
                            <!-- <v-row class="pt-4 pb-1">
                                <span style="color: #717171; font-size: 24px; font-weight: 100 !important" class="mx-auto">WDF*IDF ANALYSIS</span>
                            </v-row> -->
                            <v-card-text>
                                <div style="color: #717171; font-size: 20px; font-weight: 100 !important" class="mb-4">WDF*IDF Analysis</div>
                                <v-row no-gutters>
                                    <v-col>
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl"
                                                >Keyword
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Keyword" field specifies the topic you want to analyze. </span>
                                                </v-tooltip>
                                            </span>
                                            <v-text-field
                                                :disabled="analysis.loading"
                                                hide-details
                                                rounded
                                                filled
                                                dense
                                                v-model="analysis.keyword"
                                                placeholder="enter any keyword..."
                                                @keyup.enter="startAnalysis"></v-text-field>
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl"
                                                >Reference URL
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Reference URL" specifies an URL which will be analyzed as a reference. </span>
                                                </v-tooltip>
                                            </span>
                                            <v-text-field
                                                :disabled="analysis.loading"
                                                hide-details
                                                rounded
                                                filled
                                                dense
                                                v-model="analysis.url"
                                                @keyup.enter="startAnalysis"
                                                placeholder="optional reference url..."></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="mt-2">
                                    <v-col cols="6">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl"
                                                >Results
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Results" field specifies which competitors will be inluded in the WDF*IDF Analysis. </span>
                                                </v-tooltip>
                                            </span>
                                            <v-chip-group
                                                class="py-0"
                                                color="cyan darken-2"
                                                column
                                                show-arrows
                                                v-model="resultFilter"
                                                mandatory
                                                next-icon="mdi-chevron-right"
                                                prev-icon="mdi-chevron-left">
                                                <v-chip
                                                    :disabled="analysis.loading"
                                                    v-for="(resultChip, i) in resultChips"
                                                    :key="i"
                                                    filter
                                                    close-icon="mdi-close-circle-outline"
                                                    filter-icon="mdi-check-circle">
                                                    {{ resultChip.label }}
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl"
                                                >Device
                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span>The "Device" field indicates for which device the google search results are analyzed. </span>
                                                </v-tooltip>
                                            </span>
                                            <v-chip-group
                                                class="py-0"
                                                color="orange darken-2"
                                                column
                                                show-arrows
                                                v-model="devicefilter"
                                                mandatory
                                                next-icon="mdi-chevron-right"
                                                prev-icon="mdi-chevron-left">
                                                <v-chip
                                                    :disabled="analysis.loading"
                                                    v-for="(devicetype, i) in devicetypes"
                                                    :key="i"
                                                    filter
                                                    close-icon="mdi-close-circle-outline"
                                                    filter-icon="mdi-check-circle">
                                                    {{ devicetype.label }}
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl"
                                                >Location

                                                <v-tooltip max-width="250" color="" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="pl-0 mr-auto tooltipicon" x-small color="grey lightn-2">mdi-help-circle-outline</v-icon>
                                                    </template>
                                                    <span
                                                        >The "Location" field specifies the geographical location of the target audience and which search results will be analyzed for optimization
                                                        purposes.
                                                    </span>
                                                </v-tooltip>
                                            </span>

                                            <v-select
                                                :disabled="analysis.loading"
                                                rounded
                                                dense
                                                v-model="analysis.langloc"
                                                :items="langloc_items"
                                                item-text="location_text"
                                                item-value="location_code"
                                                prepend-inner-icon="mdi-map-marker"
                                                return-object
                                                hide-details
                                                filled
                                                attach>
                                            </v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn @click="startAnalysis" :disabled="analysis.loading" color="primary">
                                    <v-icon>mdi-device</v-icon>
                                    start</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <div v-if="analysis.loading" class="loaderwrapper">
                <v-row class="loaderimg mt-7 mb-5">
                    <img v-if="!wdfError" id="loader" src="../../assets/quaro-q.svg" />
                    <v-icon v-else size="100" class="mx-auto" color="warning">mdi-alert</v-icon>
                </v-row>
                <v-row class="loaderheading">
                    <span style="font-weight: 300; font-size: 16px" class="mx-auto">{{ statusNotification.message }}...</span>
                </v-row>
                <v-row class="ma-auto">
                    <v-col>
                        <div id="loaderdiv">
                            <v-progress-linear
                                v-if="!wdfError"
                                rounded
                                :indeterminate="statusNotification.message == 'Fetching live google results' ? true : false"
                                height="6px"
                                class="ma-auto"
                                color="primary"
                                :value="statusNotification.progress"></v-progress-linear>
                        </div>
                    </v-col>
                </v-row>

                <p class="loadersubtext mt-5 text-center" v-if="!wdfError">
                    We analyse the content of your competitors' websites. What do your competitors write about? With the help of intelligent text processing and a WDF*IDF analysis, we show you the
                    phrases you can use if you want to achieve a good position in the Google search results.
                </p>
                <div v-else class="flex">
                    <v-btn class="ml-auto mr-1" outlined color="grey" @click="cancelSearch">Cancel</v-btn>
                    <v-btn class="mr-auto ml-1" outlined color="grey" @click="startAnalysis">Retry</v-btn>
                </div>
            </div>
        </v-slide-x-transition>

        <v-slide-x-transition>
            <div class="mt-10 mb-5" v-if="analysis.finished">
                <div style="font-size: 12px; text-transform: uppercase">WDF*IDF ANALYSE</div>
                <div style="font-size: 24px" class="pb-1">
                    <span>{{ analysis.keyword }}</span>
                </div>
                <div style="font-size: 12px">
                    <div class="">
                        <v-chip class="mr-2" style="text-transform: capitalize">
                            <v-icon small class="mr-1">{{ analysis.device == "mobile" ? "mdi-cellphone" : "mdi-monitor" }}</v-icon>
                            {{ analysis.device }}
                        </v-chip>
                        <v-chip class="mr-2" style="text-transform: uppercase">
                            <v-icon small class="mr-1">mdi-map-marker</v-icon>
                            {{ analysis.langloc.language_code }}
                        </v-chip>
                        <v-chip color="primary" style="text-transform: uppercase" @click="resetAnalysis" rounded> <v-icon class="mr-1">mdi-restart</v-icon> new analysis</v-chip>
                    </div>
                </div>
            </div>
        </v-slide-x-transition>

        <!-- Chart -->
        <v-slide-x-transition>
            <v-row v-if="analysis.finished">
                <v-col>
                    <v-expansion-panels v-model="chart_panel" flat accordion elevation-1 class="px-0">
                        <v-expansion-panel class="quaroshadow rounded_card">
                            <div class="backsheet"></div>
                            <v-expansion-panel-header dense class="py-1">
                                <div class="quarolbl">
                                    <v-icon class="pb-1 mr-1" small color="grey">mdi-chart-timeline-variant</v-icon>
                                    Chart
                                </div>
                                <div class="chartswitch">
                                    <v-btn-toggle mandatory color="primary" rounded v-model="chartswitch" borderless class="mx-auto" dense @click.native.stop>
                                        <v-btn value="wdf" small>
                                            <span class="px-3">WDF</span>
                                        </v-btn>

                                        <v-btn value="wdfidf" small>
                                            <span class="">WDF*IDF</span>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <stacked-chart v-if="chartswitch === 'wdf'" class="pb-2" :chartData="wdf_chartData" :reloadChart="reloadChart"></stacked-chart>
                                <stacked-chart v-else class="pb-2" :chartData="wdfidf_chartData" :reloadChart="reloadChart"></stacked-chart>
                                <!-- <v-range-slider max-width="300px" v-model="range" :max="100" :min="1" hide-details class="align-center"></v-range-slider> -->
                                <div class="flex" no-gutters>
                                    <div class="mx-auto">
                                        <v-btn
                                            small
                                            :disabled="range.min == 1"
                                            color="primary"
                                            @click="
                                                range.min -= 20
                                                range.max -= 20
                                                generateChart()
                                            "
                                            icon
                                            ><v-icon>mdi-chevron-left</v-icon></v-btn
                                        >
                                        <v-chip small class="mx-1" color="primary"> {{ range.min }}-{{ range.max }}</v-chip>
                                        <v-btn
                                            small
                                            color="primary"
                                            @click="
                                                range.min += 20
                                                range.max += 20
                                                generateChart()
                                            "
                                            icon
                                            ><v-icon>mdi-chevron-right</v-icon></v-btn
                                        >
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <v-row v-if="analysis.finished">
                <v-col md="12" sm="12" x-sm="12">
                    <v-expansion-panels v-model="metrics_panel" flat accordion elevation-1 class="px-0">
                        <v-expansion-panel class="quaroshadow rounded_card">
                            <div class="backsheet"></div>
                            <v-expansion-panel-header dense class="py-1">
                                <div class="quarolbl">
                                    <v-icon class="pb-1 mr-1" small color="grey">mdi-cog</v-icon>
                                    Settings
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row no-gutters>
                                    <v-col class="pa-0 mb-2" md="6" sm="12">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl">Reference</span>
                                            <v-row>
                                                <v-chip-group color="orange darken-2" column show-arrows v-model="reffilter" multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                    <v-chip
                                                        class="my-auto ml-3"
                                                        :disabled="!analysis.url"
                                                        v-for="(refType, i) in refTypes"
                                                        :key="i"
                                                        filter
                                                        close-icon="mdi-close-circle-outline"
                                                        filter-icon="mdi-check-circle">
                                                        {{ refType.label }}
                                                    </v-chip>
                                                </v-chip-group>
                                                <v-text-field class="mt-1" disabled hide-details rounded filled dense :value="analysis.url" placeholder="no reference URL"></v-text-field>
                                            </v-row>
                                        </div>
                                    </v-col>
                                    <v-col class="pa-0 mb-2" md="6" sm="12">
                                        <div class="selectionCol">
                                            <span class="qsublbl">Competitors</span> <v-chip class="ml-2 white--text" x-small color="red lighten-2">coming soon</v-chip>
                                            <!-- <v-chip-group color="primary" column show-arrows v-model="compfilter" mandatory next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip disabled v-for="(preSelection, i) in preSelections" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ preSelection.label }}
                                                </v-chip>
                                            </v-chip-group> -->
                                            <v-autocomplete
                                                class="mt-1"
                                                hide-details
                                                rounded
                                                filled
                                                dense
                                                multiple
                                                :items="competitors"
                                                v-model="selectedCompetitors"
                                                clearable
                                                item-value="url"
                                                return-object
                                                placeholder="select manually">
                                                <template v-slot:item="data"
                                                    ><span class="compselectitem">{{ data.item.url }}</span></template
                                                >
                                                <!-- <template v-slot:selection="data"
                                                    ><span>{{ data.item.domain }}</span></template
                                                > -->
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip small v-if="index < 2">
                                                        <span>{{ item.domain }}</span>
                                                    </v-chip>
                                                    <span v-if="index === 2" class="text-grey text-caption align-self-center"> (+{{ selectedCompetitors.length - 2 }} others) </span>
                                                </template>
                                            </v-autocomplete>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col class="pa-0 mb-2" md="12" sm="12">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl">Metrics</span>
                                            <v-chip-group color="cyan darken-2" column show-arrows v-model="headerfilter" mandatory multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip v-for="(header, i) in selectableHeaders" :key="i" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ header.text }}
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row> </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <v-card class="quaroshadow mt-4 rounded_card" v-if="analysis.finished">
                <div class="backsheet"></div>
                <v-row>
                    <v-col class="flex">
                        <v-text-field
                            class="ml-auto mr-2"
                            v-model="search"
                            rounded
                            dense
                            style="max-width: 400px"
                            filled
                            append-icon="mdi-magnify"
                            label="Search for keywords"
                            single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>

                <v-data-table
                    id="wdftable"
                    height="750px"
                    fixed-header
                    :headers="tableHeader"
                    :items="analysisData"
                    :search="search"
                    class="elevation-0"
                    :items-per-page="50"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 50, 100]
                    }"
                    :custom-sort="customSort">
                    <!-- header slot -->
                    <template v-for="(header_entry, index) in tableHeader" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="quarotblheader">
                                            {{ header.text }}
                                            <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>
                    <!-- Table Body -->
                    <template v-slot:body="{ items }">
                        <tbody v-if="items.length">
                            <tr v-for="(item, itemIndex) in items" :key="itemIndex">
                                <td
                                    v-for="(header, headerIndex) in tableHeader"
                                    :key="headerIndex"
                                    class="py-4"
                                    :style="header.value.substring(0, 4) == 'wdf_' || header.value.substring(0, 7) == 'wdfidf_' ? 'background-color: #f6f6f6;' : ''">
                                    <!-- ------- -->
                                    <!-- keyword -->
                                    <!-- ------- -->
                                    <div v-if="header.value === 'keyword'" class="td_keyword">
                                        {{ item[header.value] }}
                                    </div>
                                    <!-- -------------- -->
                                    <!---- relevance ----->
                                    <!-- -------------- -->
                                    <!-- <div v-if="header.value === 'relevance'" v-bind="(relevance = getRelevance(item))">
                                        <v-progress-linear style="border-radius: 12px; font-size: 12px" class="white--text" rounded :value="relevance.value" color="primary" height="24">
                                            {{ relevance.value }}%
                                        </v-progress-linear>
                                    </div> -->
                                    <!-- -------------- -->
                                    <!---- avgsv ----->
                                    <!-- -------------- -->
                                    <!-- <div v-if="header.value === 'avgsv'">
                                        <v-chip small color="primary"> {{ $helpers.formatNumber(item[header.value]) }}</v-chip>
                                    </div> -->
                                    <!-- -------------- -->
                                    <!---- url_coverage ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'url_coverage'">
                                        <v-progress-linear
                                            class="white--text"
                                            style="border-radius: 12px; font-size: 12px"
                                            rounded
                                            :value="getPercent(item[header.value])"
                                            color="cyan darken-1"
                                            height="24">
                                            {{ getPercent(item[header.value]) }}%
                                        </v-progress-linear>
                                    </div>
                                    <!-- -------------- -->
                                    <!---- avgKeywordFrequency ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'avgKeywordFrequency'" class="flex">
                                        <span class="ma-auto">{{ item[header.value] ? item[header.value].toFixed(0) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- avg_wdf----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'avgWDF'" class="flex">
                                        <span class="ma-auto">{{ item[header.value] ? item[header.value].toFixed(3) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- max_wdf----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'maxwdf'" class="flex">
                                        <span class="ma-auto">{{ item[header.value] ? item[header.value].toFixed(3) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- min_wdf----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'minwdf'" class="flex">
                                        <span class="ma-auto"> {{ item[header.value] ? item[header.value].toFixed(3) : 0 }}</span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- wdf_url----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'wdf_url'" class="flex">
                                        <span class="ma-auto" v-if="item[header.value] && item[header.value] != '-'"> {{ item[header.value].toFixed(3) }}</span>
                                        <span class="ma-auto" v-else><v-chip class="white--text" color="deep-orange darken-2"> - </v-chip></span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- wdf_editor----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'wdf_editor'" class="flex">
                                        <span class="ma-auto" v-if="item[header.value] && item[header.value] != '-'"> {{ item[header.value].toFixed(3) }}</span>
                                        <span class="ma-auto" v-else><v-chip class="white--text" color="deep-orange darken-2"> - </v-chip></span>
                                    </div>
                                    <!-- ------- -->
                                    <!---- word_count----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'word_count'" class="flex">
                                        <span class="ma-auto"> {{ item.keyword.split(" ").length }}</span>
                                    </div>
                                    <!-- ------ -->
                                    <!---- idf----->
                                    <!-- ------ -->
                                    <div v-else-if="header.selected && header.value === 'idf'">
                                        {{ item[header.value] ? item[header.value].idf.toFixed(3) : 0 }}
                                    </div>
                                    <!-- ------ -->
                                    <!---- termplacement----->
                                    <!-- ------ -->
                                    <div v-else-if="header.selected && header.value === 'termplacement'">
                                        <v-chip-group>
                                            <v-chip small class="white--text mr-1 my-1" v-for="(placement, index) in item[header.value]" :key="index" :color="colors[placement.tag]">{{
                                                placement.tag
                                            }}</v-chip>
                                        </v-chip-group>
                                    </div>

                                    <div v-else class="flex">
                                        <span class="ma-auto" v-if="item[header.value] && item[header.value] != '-'"> {{ item[header.value].toFixed(3) }}</span>
                                        <span class="ma-auto" v-else><v-chip class="white--text" color="deep-orange darken-2"> - </v-chip></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-card>
        </v-slide-x-transition>
        <AddUrlDialogVue
            :show="addURLDialog.show"
            :id="addURLDialog.id"
            @close="
                addURLDialog.show = false
                fetchProjects()
            "
            :setMainURL="true"
            :addLighthouseData="true"></AddUrlDialogVue>

        <v-snackbar top v-model="notification.show" :color="notification.color" v-if="notification">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </div>
</template>

<script>
    import AddUrlDialogVue from "../../components/content-suite/AddUrlDialog.vue"
    import StackedChart from "../../components/charts/StackedChart.js"
    import { reject } from "q"
    import axios from "axios"
    import { colorCodes } from "../../components/helper/global.js"

    export default {
        name: "AdhocWdfIdf",
        components: {
            AddUrlDialogVue,
            StackedChart
        },
        data() {
            return {
                referenceFetchNotificiation: false,
                wdfError: false,
                showsearchbar: true,
                statusNotification: { message: "process started...", progress: 0 },
                langloc_items: [
                    { location_text: "Germany", language_code: "de", location_code: "2276" },
                    { location_text: "Switzerland (de)", language_code: "de", location_code: "2756" },
                    { location_text: "Austria (de)", language_code: "de", location_code: "2040" },
                    { location_text: "United Kingdom", language_code: "en", location_code: "2826" },
                    { location_text: "United States", language_code: "en", location_code: "2840" }
                ],
                chartswitch: "wdfidf",
                range: { min: 1, max: 20 },
                reloadChart: 0,
                chartData: {},
                wdf_chartData: {
                    labels: ["1", "2", "3", "4"],
                    datasets: [
                        {
                            value: "maxwdf",
                            label: "WDF (max)",
                            data: [],
                            borderColor: "#00acc1",
                            backgroundColor: "#00acc138",
                            stack: "combined",
                            pointStyle: "circle",
                            pointRadius: 8,
                            pointHoverRadius: 12,
                            borderWidth: 2
                        },

                        {
                            value: "avgwdf",
                            label: "WDF (Ø)",
                            data: [],
                            borderColor: "rgb(66, 135, 245)",
                            backgroundColor: "rgba(66, 135, 245, .3)",
                            borderWidth: 2,
                            stack: "combined",
                            borderRadius: 5,
                            borderSkipped: false,
                            type: "bar"
                        }
                    ]
                },
                wdfidf_chartData: {
                    labels: ["1", "2", "3", "4"],
                    datasets: [
                        {
                            value: "maxwdfidf",
                            label: "WDF*IDF (max)",
                            data: [],
                            borderColor: "#00acc1",
                            backgroundColor: "#00acc138",
                            stack: "combined",
                            pointStyle: "circle",
                            pointRadius: 8,
                            pointHoverRadius: 12,
                            borderWidth: 2
                        },

                        {
                            value: "avgwdfidf",
                            label: "WDF*IDF (Ø)",
                            data: [],
                            borderColor: "rgb(66, 135, 245)",
                            backgroundColor: "rgba(66, 135, 245, .3)",
                            borderWidth: 2,
                            stack: "combined",
                            borderRadius: 5,
                            borderSkipped: false,
                            type: "bar"
                        }
                    ]
                },
                compfilter: [2],
                competitors: [],
                selectedCompetitors: [],
                resultFilter: [0],
                resultChips: [
                    { depth: 10, label: "Top 10" },
                    { depth: 20, label: "Top 20" },
                    { depth: 30, label: "Top 30" },
                    { depth: 40, label: "Top 40" }
                ],
                preSelections: [
                    { selected: true, label: "All" },
                    { selected: false, label: "Top 10" },
                    { selected: false, label: "Top 20" }
                    // { selected: false, label: "Top 30" },
                    // { selected: false, label: "Top 40" }
                ],
                refTypes: [
                    { selected: true, label: "URL" }
                    // { selected: false, label: "Key" }
                ],
                reffilter: [0],
                devicetypes: [
                    { selected: true, label: "Mobile" },
                    { selected: false, label: "Desktop" }
                ],
                devicefilter: [0],
                metrics_panel: 0,
                chart_panel: 0,
                analysis: {
                    device: "Mobile",
                    langloc: { location_text: "Germany", language_code: "de", location_code: "2276" },
                    keyword: "",
                    url: "",
                    finished: false,
                    loading: false
                },
                // project: null,
                addURLDialog: {
                    id: null,
                    show: false
                },
                analysisData: [],
                notification: null,
                loading: true,
                headers: [
                    {
                        text: "Term",
                        value: "keyword",
                        sortable: true,
                        selectable: false,
                        selected: true,
                        width: 250
                    },
                    {
                        text: "Document Count",
                        value: "url_coverage",
                        sortable: true,
                        width: "175px",
                        tooltip: "This column shows the number of documents where the keyword appears. It can help you understand how many of your competitors are using the same keyword.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "Term Placement",
                        value: "termplacement",
                        sortable: false,
                        tooltip: "This column shows the location of the keyword in the document. It can help you understand where your competitors are placing the keyword on their website.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF (min)",
                        value: "minwdf",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF min shows the minimum value for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF (max)",
                        value: "maxwdf",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF max shows the maximum value for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF (Ø)",
                        value: "avgWDF",
                        sortable: true,
                        tooltip:
                            "The Within Document Frequency (WDF) is a weighted value that represents the keyword density within a document. WDF Ø shows average WDF value for the keyword across all documents.",
                        selectable: true,
                        selected: true
                    },

                    {
                        text: "WDF*IDF (min)",
                        value: "minwdfidf",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF*IDF (max)",
                        value: "maxwdfidf",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents.",
                        selectable: true,
                        selected: false
                    },
                    {
                        text: "WDF*IDF (Ø)",
                        value: "avgWDFIDF",
                        sortable: true,
                        tooltip:
                            "The WDF multiplied by Inverse Document Frequency (WDF*IDF) is a value that indicates how important the keyword is to the document. WDFIDF min/max/Ø show the minimum, maximum, and average WDF*IDF values for the keyword across all documents.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF (url)",
                        value: "wdf_url",
                        sortable: true,
                        tooltip: "The Within Document Frequency for your reference URL of this project",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "WDF*IDF (url)",
                        value: "wdfidf_url",
                        sortable: true,
                        tooltip: "The WDF multiplied by Inverse Document Frequency (WDF*IDF) for your reference URL",
                        selectable: true,
                        selected: true
                    },

                    {
                        text: "IDF",
                        value: "idf",
                        sortable: true,
                        tooltip:
                            "The Inverse Document Frequency (IDF) is a value that indicates how important the keyword is across all documents. A high IDF value means that the keyword is rare and appears in fewer documents.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "Ø Keyword Frequency",
                        value: "avgKeywordFrequency",
                        sortable: true,
                        tooltip:
                            "This column shows the average number of times the keyword appears in each document. It can help you understand how frequently your competitors are using the keyword.",
                        selectable: true,
                        selected: true
                    },
                    {
                        text: "Word Count",
                        value: "word_count",
                        sortable: true,
                        tooltip: "Shows you how many words the term or phrase consists of.",
                        selectable: true,
                        selected: true
                    }
                ],
                colors: {
                    h1: "cyan darken-3",
                    h2: "purple darken-3",
                    h3: "lime darken-3",
                    h4: "light-green darken-3",
                    h5: "blue darken-3",
                    h6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                search: "",
                relevanceData: {
                    maxWDF: 0,
                    minWDF: 0,
                    minURLCoverage: 0,
                    maxURLCoverage: 0,
                    minPlacementValue: 0,
                    maxPlacementValue: 0
                }
            }
        },
        methods: {
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers)
                console.log(sortedItems)
                return sortedItems
            },
            resetAnalysis() {
                this.analysis.finished = false
                this.analysis.keyword = ""
                this.analysis.url = ""
                setTimeout(() => {
                    this.showsearchbar = true
                }, 500)
            },
            async startAnalysis() {
                try {
                    if (this.analysis.keyword.length < 2) {
                        this.notification = this.$helpers.createNotification("Please make sure to provide a keyword! (At least two characters)", "mdi-alert", "orange darken-2")
                        return
                    }

                    this.statusNotification.progress = 0
                    this.wdfError = false
                    this.analysis.loading = true
                    this.analysis.device = this.devicetypes[this.devicefilter].label
                    this.analysis.depth = this.resultChips[this.resultFilter]

                    const url = process.env.VUE_APP_APIURL + "/cs/full-wdfidf"
                    let post_data = {
                        ...this.analysis,
                        company_id: localStorage.company_id,
                        user_id: localStorage.id,
                        socket_id: this.$socket.id
                    }
                    console.log(post_data)
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    // console.log(response_data)
                    if (response_data.data.error == "no_credits_left") {
                        this.notification = this.$helpers.createNotification("You dont have any credits left. Please contact the support via chat or email!", "mdi-alert-circle-outline", "#FCBA03")
                        this.analysis.loading = false
                        return
                    }
                    this.statusNotification.message = "Analysing website contents"
                    this.statusNotification.progress = 100
                    this.keywords = response_data.data.keywords
                    this.processData(this.keywords)

                    // this.competitors = response_data.data.competitors.map((comp, index) => (comp.label = index + ". " + comp.url))
                    this.competitors = response_data.data.competitors
                    if (this.analysis.url) {
                        this.competitors.splice(0, 1)
                    } else {
                        this.headers[9].selected = false
                        this.headers[10].selected = false
                    }
                } catch (error) {
                    this.wdfError = true
                    this.statusNotification.message = "Something went wrong! Please try again or contact support via chat or email!"
                    console.log(error)
                }
            },
            processData(keywords, selectedCompetitors = []) {
                this.analysisData = keywords.map((entry) => {
                    let wdfs = entry.wdf.map((w) => w.wdf.wdf)
                    entry.maxwdf = Math.max(...wdfs)
                    entry.minwdf = Math.min(...wdfs)
                    let urlentry = entry.wdf.find((wdfentry) => wdfentry.url == this.analysis.url)
                    entry.wdf_url = urlentry ? urlentry.wdf.wdf : "-"
                    entry.maxwdfidf = entry.maxwdf ? entry.maxwdf * entry.idf.idf : "-"
                    entry.minwdfidf = entry.minwdf ? entry.minwdf * entry.idf.idf : "-"
                    entry.avgWDFIDF = entry.avgWDF ? entry.avgWDF * entry.idf.idf : "-"
                    entry.wdfidf_url = entry.wdf_url != "-" ? entry.wdf_url * entry.idf.idf : "-"
                    selectedCompetitors.map((selectedComp) => {
                        let urlCompEntry = entry.wdf.find((wdfentry) => wdfentry.url == selectedComp.url)
                        entry["wdf_c_" + selectedComp.domain] = urlCompEntry ? urlCompEntry.wdf.wdf : "-"
                        entry["wdfidf_c_" + selectedComp.domain] = entry["wdf_c_" + selectedComp.domain] != "-" ? entry["wdf_c_" + selectedComp.domain] * entry.idf.idf : "-"
                    })
                    return entry
                })
                // filter spamming keywords with Doc Count 1 but high wdf
                this.analysisData = this.analysisData.filter((keyword) => keyword.idf.docsWithKeyword > 1 || keyword.maxwdf < 0.3)

                this.reffilter = this.analysis.url ? [0] : []
                this.toggleSelectedCompHeaders(selectedCompetitors)
                this.generateChart(selectedCompetitors)
                console.log(this.analysisData)
                setTimeout(() => {
                    this.analysis.loading = false
                    this.showsearchbar = false
                    setTimeout(() => {
                        this.analysis.finished = true
                    }, 500)
                }, 500)
            },
            toggleSelectedCompHeaders(selectedCompetitors) {
                const inputWdfIndex = this.tableHeader.findIndex((h) => h.value == "termplacement")
                const inputWdfIdfIndex = this.tableHeader.findIndex((h) => h.value == "termplacement")

                // Remove Table Header which are not selected anymore
                this.headers = this.headers.filter((h) => !h.compHeader)
                // this.headers.map((ch, index) => {
                //     // check if header ist competitor header
                //     if (ch.compHeader) {
                //         console.log(ch)
                //         console.log(selectedCompetitors)
                //         console.log(selectedCompetitors.findIndex((e) => e.url == ch.url))
                //         // check if header still selected
                //         if (selectedCompetitors.findIndex((e) => e.url == ch.url) == -1) {
                //             // remove header if not
                //             this.headers.splice(index, 1)
                //             console.log("removeHeader")
                //         }
                //     }
                // })
                console.log(this.headers)

                // Add Table Header for new selected Competitors
                selectedCompetitors.map((selectedComp) => {
                    let wdfIndex = this.headers.findIndex((h) => h.value == "wdf_c_" + selectedComp.domain)
                    let wdfIdfIndex = this.headers.findIndex((h) => h.value == "wdfidf_c_" + selectedComp.domain)

                    // comp is new selected
                    if (wdfIndex == -1) {
                        let selectedCompHeader = {
                            sortable: true,
                            tooltip: "The Within Document Frequency for this selected competitor URL",
                            selectable: true,
                            selected: true,
                            compHeader: true,
                            url: ""
                        }
                        selectedCompHeader.text = "WDF - " + selectedComp.domain
                        selectedCompHeader.value = "wdf_c_" + selectedComp.domain
                        selectedCompHeader.url = selectedComp.url
                        this.headers.splice(inputWdfIndex ? inputWdfIndex + 1 : 4, 0, selectedCompHeader)
                    }
                    if (wdfIdfIndex == -1) {
                        let selectedCompHeader = {
                            sortable: true,
                            tooltip: "The WDF multiplied by Inverse Document Frequency (WDF*IDF) for your reference URL",
                            selectable: true,
                            selected: true,
                            compHeader: true,
                            url: ""
                        }
                        selectedCompHeader.text = "WDF*IDF - " + selectedComp.domain
                        selectedCompHeader.value = "wdfidf_c_" + selectedComp.domain
                        selectedCompHeader.url = selectedComp.url
                        this.headers.splice(inputWdfIdfIndex ? inputWdfIdfIndex + 1 : 4, 0, selectedCompHeader)
                    }
                    console.log(this.headers)
                })
            },
            generateChart() {
                //Setting labels
                let labels = this.sortedAnalysisData.map((kw, index) => "(" + (index + 1) + ") " + kw.keyword)
                this.wdf_chartData.labels = labels.slice(this.range.min - 1, this.range.max - 1)
                this.wdfidf_chartData.labels = labels.slice(this.range.min - 1, this.range.max - 1)

                //WDF Data
                let avgwdf_dataset = this.wdf_chartData.datasets.find((dataset) => dataset.value == "avgwdf")
                avgwdf_dataset.data = this.sortedAnalysisData.map((kw) => kw.avgWDF * 10).slice(this.range.min - 1, this.range.max - 1)
                let maxwdf_dataset = this.wdf_chartData.datasets.find((dataset) => dataset.value == "maxwdf")
                maxwdf_dataset.data = this.sortedAnalysisData.map((kw) => kw.maxwdf * 10).slice(this.range.min - 1, this.range.max - 1)

                //WDF*IDF Data
                let avgwdfidf_dataset = this.wdfidf_chartData.datasets.find((dataset) => dataset.value == "avgwdfidf")
                avgwdfidf_dataset.data = this.sortedAnalysisData.map((kw) => kw.avgWDFIDF * 10).slice(this.range.min - 1, this.range.max - 1)
                let maxwdfidf_dataset = this.wdfidf_chartData.datasets.find((dataset) => dataset.value == "maxwdfidf")
                maxwdfidf_dataset.data = this.sortedAnalysisData.map((kw) => kw.maxwdfidf * 10).slice(this.range.min - 1, this.range.max - 1)

                this.getReferenceData()
            },
            getReferenceData() {
                let ref_wdf_dataset = {
                    value: "ref",
                    label: "Reference (URL)",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    borderColor: "#fbc02d",
                    borderWidth: 2,
                    stack: "combined",
                    pointStyle: "circle",
                    pointRadius: 8,
                    pointHoverRadius: 12
                }
                let ref_wdfidf_dataset = JSON.parse(JSON.stringify(ref_wdf_dataset))

                // genereate dataset for comparison url
                if (this.reffilter.length) {
                    ref_wdf_dataset.data = this.sortedAnalysisData.map((kw) => (kw.wdf_url != "-" ? kw.wdf_url * 10 : 0)).slice(this.range.min - 1, this.range.max - 1)
                    ref_wdfidf_dataset.data = this.sortedAnalysisData.map((kw) => (kw.wdfidf_url != "-" ? kw.wdfidf_url * 10 : 0)).slice(this.range.min - 1, this.range.max - 1)
                    let setIndex = this.wdf_chartData.datasets.findIndex((entry) => entry.value == "ref")
                    if (setIndex < 0) {
                        //add dataset
                        this.wdfidf_chartData.datasets.splice(1, 0, ref_wdfidf_dataset)
                        //add dataset
                        this.wdf_chartData.datasets.splice(1, 0, ref_wdf_dataset)
                    } else {
                        //reset data
                        this.wdfidf_chartData.datasets[setIndex].data = ref_wdfidf_dataset.data
                        //reset data
                        this.wdf_chartData.datasets[setIndex].data = ref_wdf_dataset.data
                    }
                } else {
                    // URL is not selected as reference
                    this.removeDataset("ref", 0)
                }
                this.addComps2Chart()
                this.reloadChart++
            },
            addComps2Chart() {
                //remove data
                this.wdf_chartData.datasets.map((dataset, index) => {
                    if (dataset.value.substring(0, 4) == "comp") {
                        this.wdf_chartData.datasets.splice(index, 1)
                        this.wdfidf_chartData.datasets.splice(index, 1)
                    }
                })
                // add data
                this.selectedCompetitors.map((comp, index) => {
                    let comp_wdf_dataset = {
                        value: "comp_" + comp.url,
                        label: comp.url,
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        borderColor: "rgb(" + colorCodes[index] + ")",
                        backgroundColor: "rgba(0,0,0,0)",
                        borderWidth: 2,
                        stack: "combined",
                        pointStyle: "circle",
                        pointRadius: 8,
                        pointHoverRadius: 12
                    }
                    let comp_wdfidf_dataset = JSON.parse(JSON.stringify(comp_wdf_dataset))
                    comp_wdf_dataset.data = this.sortedAnalysisData.map((kw) => (kw["wdf_c_" + comp.domain] != "-" ? kw["wdf_c_" + comp.domain] * 10 : 0)).slice(this.range.min - 1, this.range.max - 1)
                    comp_wdfidf_dataset.data = this.sortedAnalysisData
                        .map((kw) => (kw["wdfidf_c_" + comp.domain] != "-" ? kw["wdfidf_c_" + comp.domain] * 10 : 0))
                        .slice(this.range.min - 1, this.range.max - 1)
                    let setIndex = this.wdf_chartData.datasets.findIndex((entry) => entry.label == comp.url)
                    if (setIndex < 0) {
                        //add dataset
                        this.wdfidf_chartData.datasets.splice(1, 0, comp_wdfidf_dataset)
                        //add dataset
                        this.wdf_chartData.datasets.splice(1, 0, comp_wdf_dataset)
                    } else {
                        //reset data
                        this.wdfidf_chartData.datasets[setIndex].data = comp_wdfidf_dataset.data
                        //reset data
                        this.wdf_chartData.datasets[setIndex].data = comp_wdf_dataset.data
                    }
                })
            },
            removeDataset(value, filterIndex) {
                if (this.reffilter[0] !== filterIndex && this.reffilter[1] !== filterIndex) {
                    let removeWDFIndex = this.wdf_chartData.datasets.findIndex((dataset) => dataset.value == value)
                    let removeWDFIDFIndex = this.wdfidf_chartData.datasets.findIndex((dataset) => dataset.value == value)
                    // remove
                    if (removeWDFIndex > -1) {
                        this.wdf_chartData.datasets.splice(removeWDFIndex, 1)
                    }
                    if (removeWDFIDFIndex > -1) {
                        this.wdfidf_chartData.datasets.splice(removeWDFIDFIndex, 1)
                    }
                }
            },
            cancelSearch() {
                console.log("cancel")
                this.analysis.loading = false
                this.wdfError = false
            },
            getPercent(value) {
                return parseInt(value * 100)
            }
        },
        watch: {
            selectedCompetitors(val) {
                this.processData(this.keywords, val)
                console.log(val)
            },
            chart_panel(val) {
                console.log(val)
                setTimeout(() => {
                    this.reloadChart++
                }, 1)
            },
            chartswitch(val) {
                this.generateChart()
            },
            reffilter(val) {
                console.log(val)
                this.generateChart()
            },
            devicefilter(val) {
                console.log(val)
                this.analysis.device = this.devicetypes[val].label
            }
        },
        computed: {
            sortedAnalysisData() {
                let switchhelper = this.chartswitch
                return this.analysisData.sort(function (a, b) {
                    if (switchhelper == "wdf") {
                        return b.avgWDF - a.avgWDF
                    } else {
                        return b.avgWDFIDF - a.avgWDFIDF
                    }
                })
            },
            headerfilter: {
                set(val) {
                    this.selectableHeaders.map((header, index) => {
                        if (val.includes(index)) {
                            header.selected = true
                        } else {
                            header.selected = false
                        }
                    })
                },
                get() {
                    let filter = []
                    this.selectableHeaders.map((header, index) => (header.selected ? filter.push(index) : ""))
                    return filter
                }
            },
            keywordfilter: {
                set(val) {
                    console.log(val)
                },
                get() {
                    let filter = []
                    this.selectedTopics.map((topic, index) => (topic.selected ? filter.push(index) : ""))
                    return filter
                }
            },
            selectedTopics() {
                return this.project ? this.project.topics.filter((topic) => topic.selected) : []
            },
            tableHeader() {
                return this.headers.filter((header) => header.selected)
            },
            selectableHeaders() {
                return this.headers.filter((header) => header.selectable)
            }
        },
        mounted() {
            this.$socket.on("getCSNotification", (data) => {
                console.log(data)
                if (data.type == "cs_creation") {
                    this.statusNotification.message = data.progress > 90 ? "Fetching reference URL" : data.message
                    this.statusNotification.progress = data.progress
                } else {
                    this.statusNotification = data
                }

                if (data.type == "refURLFetching") {
                    console.log(data)
                    this.referenceFetchNotificiation = true

                    if (data.data) {
                        this.referenceFetchNotificiation = false
                        this.processData(data)
                    }
                }
            })
        }
    }
</script>

<style scoped lang="scss">
    #wdftable table > thead > tr > th {
        background: var(--v-keywordcol-base) !important;
    }

    #wdftable table > tbody > tr > td:nth-child(1) {
        background: var(--v-keywordcol-base) !important;
    }
    .circularprogress {
        margin-top: 20vh;
    }
    .tabletext {
        font-size: 18px;
    }
    .table_url {
        color: var(--v-primary-base);
    }
    .up_status {
        margin-top: 2px;
        font-size: 12px;
        color: grey;
    }
    .urllink {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    #wdftable .td_keyword {
        font-size: 16px;
    }
    .loaderimg {
        margin: auto;
    }
    #loader {
        margin: auto;
    }
    #loaderdiv {
        max-width: 300px;
        margin: auto;
    }
    .loadersubtext {
        text-align: center;
        max-width: 700px;
        margin: auto;
        color: grey;
        font-size: 12px;
    }
    .compselectitem {
        font-size: 12px !important;
        font-weight: 100 !important;
    }
</style>
