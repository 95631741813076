import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import Component from "./TiptapAiWriter.vue"

export default Node.create({
    name: "vueComponent",

    group: "block",

    atom: true,

    addAttributes() {
        return {
            count: {
                default: 0
            },
            keywords: { default: [] }
        }
    },

    parseHTML() {
        return [
            {
                tag: "vue-component"
            }
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ["vue-component", mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    }
})
