<template>
    <div id="contentcontainter" class="standard-mode" style="padding-bottom: 85px">
        <!-- LOADER -->
        <div v-if="!projects">
            <div style="display: flex">
                <span class="circularprogress mx-auto">
                    <img id="loader" src="../../assets/quaro-q.svg" />
                </span>
            </div>
        </div>

        <!-- SNACKBAR -->
        <v-snackbar top v-model="notification.show" :color="notification.color" v-if="notification">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>

        <!-- HEAD -->
        <!-- <div class="mt-10" style="font-size: 24px" v-if="projects">MONITORING PROJECTS</div>
        <div style="font-size: 12px" v-if="projects">
            <v-chip color="primary">{{ projects ? projects.length : "0" }} {{ projects.length == 1 ? "Project" : "Projects" }}</v-chip>
        </div> -->
        <!-- <v-row style="display: flex" v-if="projects && projects.length">
            <div class="mt-auto ml-auto mr-3">
                <v-btn class="pl-2 ml-auto" @click="$router.push('/monitoring/setup')" color="primary" filled small>
                    <v-icon class="mr-1" small>mdi-plus-circle-outline</v-icon>
                    new project
                </v-btn>
            </div>
        </v-row> -->
        <!-- TABLE -->
        <v-slide-x-transition>
            <div v-if="true" class="flex mt-10">
                <v-card class="mx-auto" style="width: 100%">
                    <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                        <v-toolbar-items>
                            <v-btn text color="grey darken-2" @click="fetchProjects"> Monitoring Projects </v-btn>
                        </v-toolbar-items>
                        <div class="flex-grow-1"></div>

                        <template v-if="$vuetify.breakpoint.smAndUp">
                            <v-text-field
                                class="pr-5"
                                v-model="search"
                                rounded
                                dense
                                style="max-width: 400px"
                                filled
                                append-icon="mdi-magnify"
                                label="search..."
                                single-line
                                hide-details
                                id="kwsearch"></v-text-field>
                            <v-btn disabled rounded class="ml-auto" @click="$router.push('/monitoring/setup')" color="primary" filled>
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                                new
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn disabled rounded class="ml-auto" @click="$router.push('/monitoring/setup')" color="primary" filled>
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-toolbar>
                    <v-row no-gutters class="d-flex" style="flex-wrap: nowrap">
                        <!-- <v-divider v-if="showfolder" vertical style="border-color: rgb(237 239 241); border-width: 2px"></v-divider> -->
                        <v-col style="overflow-x: hidden">
                            <!-- projects -->
                            <v-card flat tile class="flexible-column" style="height: 100%">
                                <v-card-text v-if="noProjects">
                                    <!-- NO PROJECTS -->
                                    <v-slide-x-transition>
                                        <v-row class="mt-5">
                                            <v-col>
                                                <v-card color="primary" max-width="600px" class="mx-auto rounded_card quaroshadow">
                                                    <v-card-text>
                                                        <v-row>
                                                            <v-col md="4" sm="4" style="display: flex">
                                                                <img width="120" class="mx-auto" src="../../assets/undraw_folder.svg" />
                                                            </v-col>
                                                            <v-col md="8" sm="8" class="white--text">
                                                                <h2 class="my-2">
                                                                    {{ bannertext.title }}
                                                                    <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip>
                                                                </h2>

                                                                <p class="mb-3">{{ bannertext.firstp }}</p>

                                                                <v-btn outlined color="white" class="pl-2" small @click="$router.push('/monitoring/setup')">
                                                                    <v-icon small class="mr-1">mdi-plus-circle-outline</v-icon>
                                                                    create new project</v-btn
                                                                >
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-slide-x-transition>
                                </v-card-text>
                                <v-card-text v-else class="grow pa-0">
                                    <v-data-table
                                        id="ms_project_table"
                                        fixed-header
                                        :headers="headers"
                                        :items="projects"
                                        :search.sync="search"
                                        class="elevation-0"
                                        @click:row="onRowClick"
                                        :items-per-page="20"
                                        :sort-by="'created'"
                                        :sort-desc="true"
                                        :loading="loading"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 50]
                                        }">
                                        <!-- header slot -->
                                        <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                                            <span :key="index">
                                                <span v-bind:style="{ width: header.width }">
                                                    <!-- Adding tooltips to header -->
                                                    <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="quarotblheader">
                                                                {{ header.text }}
                                                                <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>
                                                            <v-row no-gutters>
                                                                <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                            </v-row>
                                                            {{ header.tooltip }}
                                                        </span>
                                                    </v-tooltip>
                                                </span>
                                            </span>
                                        </template>
                                        <!-- Table Body -->
                                        <template v-slot:body="{ items }">
                                            <tbody v-if="items.length" style="color: rgb(60, 60, 60); font-weight: 400 !important">
                                                <tr v-for="(item, itemIndex) in items" :key="itemIndex" @click="onRowClick(item)" class="rowhover">
                                                    <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="py-4">
                                                        <!-- ------- -->
                                                        <!-- title -->
                                                        <!-- ------- -->
                                                        <div v-if="header.value === 'title'" class="pl-5">
                                                            <span class="tabletext"> {{ item[header.value] }}</span>
                                                            <div class="table_url mt-1">
                                                                <v-chip small @click="" class="pl-2" outlined>
                                                                    <img
                                                                        class="mr-1"
                                                                        style="width: 12; border-radius: 5px"
                                                                        :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item.domain + '&sz=12'"
                                                                        alt="" />
                                                                    {{ item.domain }}
                                                                </v-chip>
                                                            </div>
                                                        </div>

                                                        <!-- -------------- -->
                                                        <!---- visibility ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'visibility'">
                                                            <span color="primary" class="flex" v-bind="(data = getDomainVisib(item))">
                                                                <v-tooltip v-if="item.setup.error" max-width="250" color="primary" top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip small v-on="on" class="white--text px-2" rounded outlined color="error">
                                                                            <v-icon small>mdi-alert</v-icon>
                                                                            error
                                                                        </v-chip>
                                                                    </template>
                                                                    <span class="mt-5">
                                                                        <p><b> Something went wrong while importing the keywords! Please try again or contact the support! </b></p>
                                                                    </span>
                                                                </v-tooltip>
                                                                <v-tooltip v-else-if="item.processed_dates.length == 0" max-width="250" color="primary" top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-progress-linear
                                                                            class=""
                                                                            v-on="on"
                                                                            style="border-radius: 12px; font-size: 12px"
                                                                            rounded
                                                                            outlined
                                                                            indeterminate
                                                                            color="success"
                                                                            height="24">
                                                                            Importing...
                                                                        </v-progress-linear>
                                                                    </template>
                                                                    <span class="mt-5">
                                                                        <p><b>The project is currently beeing created This may take a while! </b></p>
                                                                    </span>
                                                                </v-tooltip>
                                                                <div v-else-if="data" class="d-flex">
                                                                    <span class="tabletext my-auto"> {{ $helpers.formatNumber(data.value) }}</span>
                                                                    <v-chip
                                                                        outlined
                                                                        class="px-2 ml-1 my-auto"
                                                                        style="margin-top: 1px"
                                                                        small
                                                                        :color="data.change == 0 ? '' : data.change > 0 ? 'success ' : 'error'">
                                                                        <v-icon color="grey" small v-if="data.change == 0">mdi-arrow-right-thin</v-icon>
                                                                        <v-icon color="success" small v-else-if="data.change > 0">mdi-arrow-up-thin</v-icon>
                                                                        <v-icon color="error" small v-else>mdi-arrow-down-thin</v-icon>

                                                                        <span class="my-auto"> {{ (data.change * 100).toFixed(2) }}%</span>
                                                                    </v-chip>
                                                                </div>
                                                                <v-tooltip v-else max-width="250" color="primary" top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip v-on="on" outlined class="px-2 ml-1" style="margin-top: 1px" small
                                                                            ><v-icon size="16px" color="warning" class="mr-1">mdi-alert-circle-outline</v-icon>no data</v-chip
                                                                        >
                                                                    </template>
                                                                    <span class="mt-5">
                                                                        <!-- <h3>Check your domain</h3> -->
                                                                        <p class="mt-4">
                                                                            The domain <v-chip small>{{ item.domain }}</v-chip> does not appear to be present in the search results.
                                                                        </p>
                                                                        <p><b> Please make sure that you have spelled your domain name correctly! </b></p>
                                                                    </span>
                                                                </v-tooltip>
                                                            </span>
                                                        </div>

                                                        <!-- -------------- -->
                                                        <!---- competitors ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'top_competitors'" style="white-space: nowrap">
                                                            <v-chip outlined :color="c.domain == item.domain ? 'primary' : ''" class="mr-1" v-for="(c, i) in item[header.value]" :key="c.domain">
                                                                <img
                                                                    class="mr-2"
                                                                    style="width: 12; border-radius: 5px"
                                                                    :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + c.domain + '&sz=12'"
                                                                    alt="" />
                                                                <span> {{ c.position || i + 1 }}. {{ c.domain }}</span>
                                                                <span class="px-1"> - </span>
                                                                {{ $helpers.formatNumber(c.visibility[0].value) }}
                                                            </v-chip>
                                                            <!-- <v-chip color="white" @click=""><v-icon color="grey darken-2">mdi-plus</v-icon></v-chip> -->
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- keywords ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'keyword_count'">
                                                            <v-chip outlined color="primary">
                                                                {{ $helpers.formatNumber(item[header.value]) }}
                                                            </v-chip>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- device ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'device'" style="text-transform: uppercase; white-space: nowrap">
                                                            <v-icon small>{{ item[header.value] == "desktop" ? "mdi-monitor" : "mdi-cellphone" }}</v-icon> {{ item[header.value] }}
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- Language ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'langloc.language_code'" style="text-transform: uppercase; white-space: nowrap">
                                                            <v-icon small>mdi-map-marker</v-icon> {{ item.langloc.location_text }}
                                                        </div>

                                                        <!-- -------------- -->
                                                        <!---- created ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === '_id'">
                                                            <span style="white-space: nowrap">{{ getCreatedDate(item._id) }}</span>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- created by ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'processed_dates'">
                                                            <span style="white-space: nowrap">
                                                                {{
                                                                    item.processed_dates[item.processed_dates.length - 1] ? item.processed_dates[item.processed_dates.length - 1].substring(0, 10) : "-"
                                                                }}</span
                                                            >
                                                        </div>
                                                        <div v-if="header.value === 'actions'">
                                                            <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" icon class="ml-auto" :disabled="$store.state.monitoring_suite_group.id == 2">
                                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list rounded dense>
                                                                    <v-list-item
                                                                        @click="
                                                                            editDialog.show = true
                                                                            editDialog.item = JSON.parse(JSON.stringify(item))
                                                                            editDialog.error = null
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-pencil</v-icon><v-list-item-title>Edit</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        @click="
                                                                            deleteDialog = true
                                                                            selectedDeleteItemTitle = item.title
                                                                            selectedDeleteItem = item
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-slide-x-transition>
        <EditProjectDialog
            v-if="editDialog.show"
            :editDialog="editDialog"
            @close="editDialog.show = false"
            @saved="
                editDialog.show = false
                fetchProjects()
            " />
        <DeleteDialog
            :deleteDialog="deleteDialog"
            :selectedDeleteItem="selectedDeleteItem.title"
            deleteLabel="Project"
            :loading="false"
            @delete="deleteProject"
            @close="deleteDialog = false"></DeleteDialog>
    </div>
</template>

<script>
    import DeleteDialog from "../../components/dialogs/DeleteDialog.vue"
    import EditProjectDialog from "../../components/monitoring/EditProjectDialog.vue"

    export default {
        components: {
            DeleteDialog,
            EditProjectDialog
        },
        data() {
            return {
                editDialog: { show: false, item: {}, error: null },
                deleteDialog: false,
                selectedDeleteItem: { title: "" },
                search: "",
                noProjects: false,
                loading: false,
                projects: [],
                notification: { show: false },
                bannertext: {
                    title: "Create a Project",
                    firstp: "Use Monitoring Projects to track and optimize your rankings!"
                },
                headers: [
                    {
                        text: "Project",
                        value: "title", //domain and path
                        sortable: true,
                        width: 400
                    },
                    // {
                    //     text: "Domain",
                    //     value: "domain",
                    //     sortable: true
                    // },
                    {
                        text: "Visibility",
                        value: "visibility",
                        sortable: true
                    },
                    {
                        text: "Best Competitors",
                        value: "top_competitors",
                        sortable: true
                    },
                    {
                        text: "Keywords",
                        value: "keyword_count",
                        sortable: true
                    },
                    {
                        text: "Device",
                        value: "device",
                        sortable: true
                    },
                    {
                        text: "Location",
                        value: "langloc.language_code",
                        sortable: true
                    },
                    {
                        text: "Created",
                        value: "_id",
                        sortable: true,
                        sort: (a, b) => this.mongoIdSort(a, b)
                    },
                    {
                        text: "Updated",
                        value: "processed_dates",
                        sortable: true
                    },

                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        align: "end",
                        width: 50
                    }
                ]
            }
        },
        computed: {
            lutz() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) == 32
            }
        },
        methods: {
            onRowClick(row) {
                if (row.processed_dates.length) {
                    this.$router.push("/monitoring/project/" + row._id)
                } else {
                    this.notification = this.$helpers.createNotification("This project is currently beeing imported! Try again later. ", "mdi-alert", "warning")
                }

                console.log(row)
            },
            async fetchProjects() {
                try {
                    this.loading = true
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/"
                    let post_data = {
                        company_id: localStorage.company_id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data.projects)
                    this.projects = response_data.data.projects
                    this.noProjects = this.projects.length ? false : true
                    this.loading = false
                } catch (error) {
                    this.loading = false
                }
            },
            async deleteProject() {
                console.log(this.selectedDeleteItem)
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/delete/one"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: this.selectedDeleteItem._id
                }
                this.deleteDialog = false
                let deleteIndex = this.projects.findIndex((p) => p._id == this.selectedDeleteItem._id)
                if (deleteIndex > -1) {
                    this.projects.splice(deleteIndex, 1)
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.fetchProjects()
                this.deleteDialog = false
            },
            getDomainVisib(project) {
                let domainEntry = project.top_competitors.find((c) => c.domain == project.domain)
                if (domainEntry) {
                    let change = 0
                    if (domainEntry.visibility.length > 1) {
                        if (domainEntry.visibility[0].value - domainEntry.visibility[1].value == 0) {
                            change = 0
                        } else {
                            change = (domainEntry.visibility[0].value - domainEntry.visibility[1].value) / domainEntry.visibility[0].value
                        }
                    }

                    return { value: domainEntry.visibility[0].value, change: change }
                }
                return { value: 0, change: 0 }
            },
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers)
                console.log(sortedItems)
                return sortedItems
            },
            getCreatedDate(objectId) {
                // Convert MongoDB ObjectID to a timestamp and format the date
                // Convert MongoDB ObjectID to a timestamp and format the date
                const timestamp = new Date(parseInt(objectId.substring(0, 8), 16) * 1000)
                const formattedDate = timestamp.toISOString().slice(0, 10)
                return formattedDate
            },
            mongoIdSort(a, b) {
                const extractDateTimestamp = (id) => {
                    return parseInt(id.toString().slice(0, 8), 16)
                }

                const timestampA = extractDateTimestamp(a)
                const timestampB = extractDateTimestamp(b)

                // Vergleichslogik für die Sortierreihenfolge
                if (timestampA < timestampB) {
                    return -1
                }
                if (timestampA > timestampB) {
                    return 1
                }
                return 0
            }
        },

        mounted() {
            this.fetchProjects()
            this.$socket.on("projectImportStatus", (data) => {
                console.log(data)
                if (data.progress == 100) this.fetchProjects()
            })
        }
    }
</script>

<style>
    #ms_project_table table > tbody > tr > td:last-child {
        background: var(--v-keywordcol-base) !important;
        border-left: 2px solid var(--v-primary-base);
    }
    #ms_project_table table > tbody > tr > td:last-child,
    #ms_project_table table > thead > tr > th:last-child {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
    }
    #ms_project_table .tabletext {
        font-size: 16px;
    }
    .theme--dark #ms_project_table span,
    .theme--dark #ms_project_table div {
        color: white;
    }
    #ms_project_table .rowhover:hover {
        cursor: pointer;
    }
    #ms_project_table .v-chip {
        background-color: #edeff1 !important;
    }
    .theme--dark #ms_project_table .v-chip {
        background-color: #222222 !important;
    }
    #ms_project_table > .v-progress-linear__background.success {
        opacity: 0.6 !important;
    }
</style>
